app-orders-list {
    .title_bar{
        background-color: $color_5 !important;
        font-family: $main-font;
        color: $color_1;
        ion-label{
            color: $color_1 !important;
        }
    }
    .link{
        color:$color_white !important;
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }

    ion-content{
        --background: $color_1 !important;
        background: $color_1 !important;
    }

    ion-label{
        color: $color_2;
    }

    ion-item {
        color: $color_white !important;
        ion-label {
            font-family: $second-font;
        }
    }

    .title_bar{
        font-family: $main-font;
    }

    .button-accordion{
        &:hover, &:focus{
            background-color: transparent !important;
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
        }
        ion-icon{
            color: $color_1;
            font-size: 1.7rem;
        }
    }

    app-price{
        ion-icon{
            padding-left: 10px !important;
        }
    }

    ion-item.see-more-item{
        --border-color: $color_white !important;
        /*--border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0))));*/


    }
}