app-cards-list{

    ion-label{
        color: $color_2;
    }

    .scroll-content{
      /*   background:$color_1 url('#{$assetPath}/Common/background_grid.png') no-repeat center bottom !important;
        background-size: cover !important; */
        margin-bottom: 0  !important;
        padding-left:15px;
    }
    .content,
    .content-md {
        background: transparent !important;
    }
    ion-item{
        --background: $color_1 !important;
        background: $color_1 !important;
        div{
            color: $color_white !important;
            font-family: Arial !important;
        }

        ion-label {
            margin: 0 !important;
            font-family: $second-font;
            padding-left:15px;
        }
    }
    .horizontal-margin{
        ion-label{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
    .button_close{
        width: 30px;
        display: flex;
        align-self: flex-end;
        margin-bottom: -32px;
        button{
            background-color: transparent;
        }
    }
    .divider{
        background-color: $color_white !important;
    }
    ion-footer{
        background: transparent !important;
    }

    ion-content{
        --background: $color_1 !important;
        background: $color_1 !important;
    }

    ion-list, ion-item, ion-item-divider{
        --background: $color_1 !important;
        background: $color_1 !important;

        color: $color_white !important;
        --ion-item-border-color: $color_white !important;
        /*border-bottom: 1px solid var(--ion-item-border-color, rgba(255, 255, 255)) !important;*/
 
    }

    ion-header ion-toolbar{
        border-bottom: 1px solid $color_white !important;
    }

    .title_bar{
        font-family: $main-font;
    }
}