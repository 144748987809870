app-order-detail{
    ion-content{
        --background: $color_1 !important;
        background: $color_1 !important;
    }

    .scroll-content{
        margin-bottom: 120px;
        button{
            box-shadow: 0px 0px 0px 0px rgba(16,128,130,0.26) !important;
        }
    }
    .qrcode-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 50px;
        color: white;
        font-size: 1rem;
        line-height: 1rem;
        div{
            width:90%;
            @include margin-auto;
        }

        app-price{
            ion-icon{
                margin-left: -5px !important;
                font-size: 12px;
            }
        }


        qrcode{
            margin-bottom: 5px !important;
        }
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }
}
