app-pick-up-place {
    text-align: center;
    .scroll-content{
        background: $color_1;
    }
    ion-footer{
        background: $color_1 !important;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    button.button_color_1 {
        @extend .mainfont-button;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}