app-pick-up-hour {
    .scroll-content, ion-footer {
        background: $color_1;
    }
    hr.separator{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0.3), rgba(255, 255, 255, 1), rgba(255, 255, 0, 0.3));
    }
    hr.separator_1{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.40), rgba(255, 255, 0, 0));
    }
    hr.separator_2{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.80), rgba(255, 255, 0, 0));
    }
    hr.separator_day{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 1), rgba(255, 255, 0, 0));
    }
    hr.separator_outadated{
        background-color: $color_5;
    }
    .text-hour{
        color: white;
        background: $color_1 !important;
    }
    .slot-text {
        background-color: rgba(0, 255, 240, 1);
        padding:80px;
        font-family: MontserratBold;
        font-size: 1.4rem;
        color:white;
        text-align: center;
        opacity: 1;
        text-transform: uppercase;
    }
    .selected {
        background-color: $color_5;
        color: $color_4;
        opacity: 1;
    }
    .unavailable {
        background-color: rgba(222, 33, 33, 1);
        opacity: 0.7;
    }
    .disabled {
        background-color: #6f7574;
        opacity: 0.7;
    }
    .outdated {
        opacity: 0.7;
    }
    ion-slides {
        ion-slide{
            color:$color_white;
            font-family: "Montserrat";
            background:$color_1;
        }
    }
    .swiper-container{
        height:40px !important;
        background:$color_1;
    }
    .swiper-wrapper{
       
    }
    .swiper-slide{
        font-size: 2.2rem;
        cursor: pointer;
    }
    .swiper-button-prev, .swiper-button-next {
        ion-icon {
            font-size: 3.2rem !important;
            opacity: 0.8;
            &:before{
                color:$color_white;
               
            }
        }
    }
    ion-footer{
        background: $color_1 !important;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}