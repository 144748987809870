app-mention {
    .header-wp{
        .scoll-content{
            margin-top:70px !important;
        }
    }
    .toolbar-title {
        color: white !important;
    }
    p{
        text-align: justify;
        font-size: 2.5rem  !important;
    }
}
