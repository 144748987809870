//Catégories
.app-root > ion-tab {
    background-color: $color_1;
    img.placeholder {
        background-image: url('../../assets/images/default_priceless_white.png');
        &.price {
            background-image: url('../../assets/images/default_white.png');
        }
    }
    .toolbarscroll {
        padding: 0 !important;
        width:103%;
        .toolbar-background {
            background-color: $color_1 !important;
            overflow-y: hidden;
            overflow-x: hidden;
        }
    }
    app-options,
    app-bundle,
    app-directory {
        .scroll-content {
           // background: $color_1 url('../assets/images/Restaurant/FOND_RESTAURANT.png') no-repeat center !important;
            padding: 5px !important;
        }
    }
    app-directory{
        .scroll-content {
            margin-top: 150px !important;
        }
        .toolbarscroll{
            background: $color_2;
            margin-top:0px !important;
        }
    }
    app-options .scroll-content {
        margin-top: 170px !important
    }
}
