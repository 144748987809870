app-cgv, app-gcs, app-legals {
    .toolbar-background {
        border: none !important;
    }
    .header-wp{
        .scroll-content{
            margin-top:70px !important;
        }
    }
    .toolbar-title {
        color: white !important;
    }
    h1{
        font-size: 2rem;
        margin-top:10px;
        margin-bottom: 8px;
    }
    .cgv_content{
        padding: 20px;
        text-align: justify;
    }


    /* List Definitions */
    ol
    {margin-bottom:0cm;}
    ul
    {
        margin:0;
        padding:0;
        margin-left: 15px;
        & > li { margin-top: 15px;}
        & > ul > li { margin: 0 }
    }

    ion-content{
        --background: $color_1 !important;
        background: $color_1 !important;
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }

}