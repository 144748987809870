
@import "../variables";

//HOME
app-home {
    background:$color_1 url('#{$assetPath}/Accueil/background_home.png') no-repeat center -10px;
    background-size: cover;

    #lamaison_slide{
        display: none;
    }
    #capao_slide{
        display: none;
    }
    #leaderprice_slide{
        display: none;
    }
    #beesket_slide{
        display: none;
    }
    #waowei_logo{
        display: none;
    }
    #enjoytacos_logo{
        display: none;
    }
    #logo{
      //  display: none;
    }
   ion-toolbar {
        padding: 0 !important;
    }
    .scroll-content{
        margin-top: 10px !important;
        overflow-y: hidden !important;
    }
    #logo{
        width: 267px;
        height: 140px;
        content: " ";
        background:transparent url('#{$assetPath}/Accueil/logo_home.png') no-repeat center center !important;
        background-size: contain !important;
        margin-top: -20px;
        @include margin-auto;
    } 
    ion-header {
        background-color: transparent !important;
        //header title and button
       ion-toolbar {
            min-height: 70px;
            background-color: transparent !important;
            border-bottom: unset !important;
        }
        ion-toolbar.ios{
            min-height: 80px !important;
            padding-top: 35px !important;
        }
    }
    .content-md{
        background-color: transparent !important;
    }
    .toolbar-background-md {
        border-color: transparent !important;
        background-color: transparent !important;
    }
    .toolbar-content{
        position: absolute;
        top: 0;
    }
    .container {
        overflow-y: hidden;
        ion-row{
            height: 100px !important;
            margin-bottom: 15px;
            ion-col{
                background:transparent url('#{$assetPath}/Accueil/logo_home.png') no-repeat center top;
                background-size: cover;
                height: 89px;
                width: 238px;
            }
        }
        div.menu{
            width: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: calc(100vh - 185px);
            max-height: calc(100vh - 185px);
            justify-content: center;
            button{
                padding-top: 11px;
                font-weight: 600;
                width: 70%;
                height: 40px !important;
                min-height: 40px;
                margin-bottom: 0px;
                border-width: 1px !important;
                line-height: 9px !important;
                background: white !important;
                color: #2cb6b9 !important;
                transform: scaleY(1.05) !important;
                box-shadow: 3px 4px 10px 2px rgb(6 59 60 / 30%) !important;
                font-size: 18px;

                // width: 70%;
                // height: 40px !important;
                // min-height: 40px;
                // margin-bottom: 40px;
                // border-width: 1px !important;
                // line-height: 9px !important;
                // background: $color_white !important;
                // color: $color_1 !important;
                // transform: scaleY(1.05);
                // box-shadow: 3px 4px 10px 2px rgba(6, 59, 60, 0.3) !important;
                &:first-of-type{
                 //   margin-top:33px;
                }
                &:last-of-type{
                    margin-bottom:33px;
                }
                .button-inner{
                    font-size: 2.2rem;
                }
            }
        }

        @at-root {
            .ios app-home .container {
                margin-top: 1vh;
            }
        }
    }

    // a laisser en fin de page pour responsive grand ecran
    @media screen and (min-width: 1024px) {
        .menu{
            button{
                width: 30%  !important;
            }
        }
        .container{
            width: 100%;
        }
        .scroll-content {
            margin-top: 0px !important;
        }
    }  

    .header-ios .header-background {
        height: 80px !important;
    } 
}

