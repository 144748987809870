
@import "../variables";

//TABS
app-tabs {
    ion-tabs{

        ion-tab-bar{
            background: $color_1;
            ion-tab-button{
                background: $color_1;
                color: $color_2; 
                font-family: "Montserrat";
                -webkit-transform: scaleY(1.05);
                transform: scaleY(1.05);
                min-height: 5.6rem;
                font-weight: normal;
                ion-icon{
                    width: 40px !important;
                    color: $color_2;
                    height: 32px !important;
                    margin-top: 1px;
                    min-width: 2.4rem;
                    font-size: 2.4rem;
                    transform-origin: 50% 150%;
                }
                ion-label{
                    color: $color_2;
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.1px;
                    -webkit-transform: scaleY(1.05);
                    transform: scaleY(1.05);
                }
                img.placeholder {
                    background-image: url('../../../assets/images/default_priceless_white.png');
                    &.price {
                        background-image: url('../../../assets/images/default_white.png');
                    }
                }
                ion-badge{
                    font-size: 1.3rem !important;
                    top: 10px !important;
                    left: 40px !important;

                }
            }
            ion-tab-button.selected{
                ion-label{
                    color: $color_5; 
                }
                ion-icon{
                    color: $color_5; 
                }
            }

        }

       


         // a laisser en fin de page pour responsive grand ecran
         @media screen and (min-width: 1024px) {
            .menu{
                button{
                    width: 30%  !important;
                }
            }
            .container{
                width: 100%;
            }
            .scroll-content {
                margin-top: 0px !important;
            }
        }  
    } 
}

