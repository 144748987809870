app-payzen-register {
    .scroll-content {
        background:$color_1;
    }
    p{
        font-size: 1.3rem !important;
        color: white;
        /*padding: 15px;*/
    }
    ion-footer{
        padding-left:5px !important;
        padding-right: 5px  !important;
        background:$color_1;
        ion-button.halfwidth:first-of-type {
            margin-right: 8px !important;
            border:0;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
        --padding-start: 20px;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}

app-payzen-embeded{

    .toolbar-title{
        &:after{
            font-family:$fontawesome;
            color:$color_white;
        }
    }

    #myPaymentForm {
        .kr-do-register {
            .kr-checkbox-label {
                color: $color_white;
            }
        }
        .kr-checkbox-label {
            font-family: $main-font !important;
        }
        .kr-embedded.kr-help-button-inner-field .kr-payment-button,
        .kr-embedded.kr-help-button-inner-field .kr-form-error {
            font-family: $second-font !important;
        }
        .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default
        .kr-checkbox-input-wrapper
        .kr-checkbox-control-indicator {
            background-color: $color_1;
        }
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-field-control
            input:checked
            ~ .kr-checkbox-control-indicator,
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-field-control
            input:checked:focus
            ~ .kr-checkbox-control-indicator {
            background-color: $color_1;
        }
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-checkbox-control-indicator {
            border: 1px solid $color_white;
        }
        button {
            @extend .button_1;
        }
    }

    #myPaymentForm {
        padding: 1rem;
        text-align: center;
        box-sizing: border-box;
        min-width: 100%;
        width: 100vw !important;
        @media screen and (min-width: 1024px) and (orientation: landscape) {
            min-width: 300px !important;
            width: 300px !important;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (min-width: 768px) and (orientation: portrait) {
            min-width: 300px !important;
            width: 300px !important;
            margin-left: auto;
            margin-right: auto;
        }
        .kr-embedded{
            margin-left: auto;
            margin-right: auto;
            width: 90% !important;
        }
        .kr-do-register {
            .kr-checkbox-label {
                /*color: $color_white;*/
                font-size: 1.8rem;
                line-height: 36px;
                padding-left: 10px;
            }
        }
        .kr-checkbox-label {
            /*font-family: $main-font !important;*/
        }
        .kr-do-register .kr-checkbox-layout {
            display: inline-flex;
        }
        .kr-embedded.kr-help-button-inner-field .kr-payment-button,
        .kr-embedded.kr-help-button-inner-field .kr-form-error {
            width: 100% !important;
            /*font-family: $second-font !important;*/
        }
        .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default
        .kr-checkbox-input-wrapper
        .kr-checkbox-control-indicator {
            /*background-color: $color_1;*/
        }
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-field-control
            input:checked
            ~ .kr-checkbox-control-indicator,
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-field-control
            input:checked:focus
            ~ .kr-checkbox-control-indicator {
            /*background-color: $color_1;*/
        }
        .kr-embedded
            .kr-field.kr-checkbox.kr-checkbox-type-default
            .kr-checkbox-input-wrapper
            .kr-checkbox-control-indicator {
            /*border: 1px solid $color_white;*/
            border-radius: 10px;
        }
        button {
            /*@extend .button_1;*/
            line-height: 26px;
            height: 48px !important;
            width: 100% !important;
            span{
                font-size: 1.1rem !important;
            }
        }
    }

    .kr-embedded{
        margin-left: auto !important;
        margin-right: auto !important;
        width: 90% !important;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }

}