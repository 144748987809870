/* Reset */
input {
    margin: 0 !important;
}

$xsmall-screen : 320px;
$xmedium-screen : 768px;
$medium-screen : 1024px;
$large-screen : 1366px;

$font-arial :  Arial, Helvetica, sans-serif;

/* Fin: Reset */
a{
    cursor: pointer;
    color:black;
    text-decoration: underline;
}
.link { text-decoration: underline }
.bold { font-weight: bold }
/* .fixed-content,
.scroll-content {
    padding: 0 !important;
} */
ion-modal {
   // z-index: 1 !important;
    .modal-wrapper {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        display: block !important;
        width: 100% !important;
        height: 100% !important;
    }
}
ion-footer.buttons-bar {
    padding: 10vw;
    padding-top: 2vh;
    padding-bottom: 3vh;
    display: flex;
    justify-content: space-around;
    button {
        @extend .mainfont-button;
    }
}
// Placeholder utilisé lorsqu'une image ne peut pas être chargée
img.placeholder {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 0; // masque le alt
}

//Buutons
.white-button, .black-button {
    border-color: white;
    transition: all .2s ease;
}

@mixin white-button {
    background-color: white !important;
    color: black !important;
    &:active {
        background-color: black !important;
        color: white !important;
    }
}

@mixin black-button {
    background-color: black !important;
    color: white !important;
    &:active {
        background-color: white !important;
        color: black !important;
    }
}
.white-button { @include white-button }
.black-button { @include black-button }

.content {
    background-color: transparent;
    &.scroll-content-padding-default .scroll-content {
        padding: 40px !important;
        @media screen and (max-width: 400px) {
            padding: 30px !important;
        }
    }
}

.input-inner, .input-wrapper {
    align-items: center !important;
}

app-login,
app-user-form,
app-password-change,
app-password-lost,
app-cgv,
app-mention,
app-order-detail,
app-orders-list,
app-profil,
app-store-detail,
app-stores-list {
    .white-button, .black-button {
        transition: all .2s ease;
    }
}

.scroll-content {
    margin-bottom: 44px;
}
.content-centered {
    text-align: center !important;
}
.content-right {
    text-align: right !important;
}
.content-left {
    text-align: left !important;
}
.zero-padding {
    padding: 0 !important;
}
.zero-margin {
    margin: 0 !important;
}
.fullwidth {
    width: 100% !important;
}
.halfwidth {
    width: 50% !important;
}
.colorwhite {
    color: white !important;
}
.backgroundblack {
    background-color: black !important;
}
.back-button{
    font-size:20px;
}
.capitalize {
    text-transform: capitalize;
}
.platform-ios .alert-ios .alert-button-group {
    flex-direction: column;
    .btnvalider {
        margin-right: 10px;
        min-width:20%;
    }
    .btncancel {
        width: 20% !important;
        min-width:20%;
    }
}
.wp{
    h2{
        font-size: 26px  !important;
    }
    .col-qty {
        margin-top:0 !important;
    }
    .alert-wrapper {
        border:1px solid white;
    }
}
.welcome-text, .version-text {
    text-align: center;
}
.pointer_none{
    pointer-events: none;
}
ion-tabs .tabbar {
    box-shadow: 0 0 0  transparent !important;
}

ion-app {
    background: transparent !important;
    background-color: transparent !important;
    &.md{
        background: transparent !important;
        background-color: transparent !important;
    }
    &.platform-ios, &.wp {
        [menutoggle] { // Masque le bouton de menu pour ios
            display: none;
        }
    }
    &.platform-android:not(.device-type-terminal) ion-tabs {
        .tabbar { // masque la tabbar pour android (material-design)
            height: 0;
            display: none;
        }
        ion-tab ion-content > div {
            margin-bottom: 0;
        }
    }
}

ion-badge{
    background-size: contain;
    padding: 8px !important;
    transition: all 2s ease;
    z-index: 1000;
    @keyframes highlight {
        0% { transform: rotate(0deg) scale(1)}
        25% { transform: rotate(360deg) scale(1.2) }
        50% { transform: rotate(720deg) scale(1) }
        75% { transform: rotate(1080deg) scale(1.2) }
        100% { transform: rotate(1440deg) scale(1) }
    }
    &:before {
        content: "";
        position: absolute;
        top:10px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &.highlight:before {
        animation: highlight 2s linear;
    }
    &.badge-md{
        position: absolute;
        left: 15px;
        top: 2px;

    }
    // menu iphone et wp
    &.badge-ios-danger, &.badge-wp-danger{
        width:26px;
        height: 27px;
        margin-top:4px;
        padding: 0 15px !important;
        right: calc(47% - 30px) !important;
        &.active{
            &:before {
                transform: rotate(360deg);
                filter: brightness(1.5);
            }
        }
    }
    &.badge-ios-danger::before , &.badge-wp-danger::before {
        position: absolute;
        top: -6px;
        content: " ";
        width: 92%;
        height: 100%;
        float: left;
        left: 5px;
        z-index: 0;
    }
    // icone panier en haut de page sur android
    &.top-badge {
        margin-top:-7px;
        width: 30px;
        &:before{
            border-radius: 20px;
        }
        &.badge-md{
            background: transparent !important;
            position: relative !important;
            left: -5px;
            top: 2px;
        }
    }

    .has-icon .tab-badge {
        right: calc(40% - 30px);
    }
    &.tab-badge{
        padding: 1px 4px;
        position: absolute;
        height: 22px;
        font-size: 1.8rem;
        line-height: 7px;
        text-align: center;
        width: 38px;
        left: 50%;
        top: -1px;
        background: transparent !important;
       // border: 1px solid white !important;
        border-radius: 30px;
    }
}


.toolbar-ios ion-title,.toolbar-ios ion-title {
    padding: 0  !important;
}
.menu{
     .image-menu-home{
        &.error{
            display: none;
        }
    }
}
#leftMenu{
    .item-block{
        min-height: 3em;
    }
}
ion-alert{
    height: 100%;
    text-align: center;
    width: 100% !important;
    min-height: 40%;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 50%;
        text-align: center;
        width: 80% !important;
        margin-left: auto;
        margin-right: auto;
        top: 5% !important;
        border:2px solid white;
    }
  
    .alert-wrapper {
        border-radius: 0;
        width: 95vw !important;
        max-width: 95vw !important;
        min-height: 90vw;
        margin-left: auto;
        margin-right: auto;
     
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            height: 100%;
            width: 100%;
            max-height: 100% !important;
            padding: 30px;
        }
        @media screen and (min-width: 1024px) {
            min-height: 50vw;
            width: 40vw;
            max-width: 40vw;
            transform: scale(0.8);
        }
        .alert-title {
            text-transform: uppercase;
            font-size:2.5rem;
            text-align: center;
            @media screen and (max-height: 767px) {
                font-size:2.1rem;
            }
            @media screen and (min-width: 768px) {
               margin-top: 50px;
               line-height: 3rem;
               font-size: 2rem;
            }
            @media screen and (min-width: 1024px) {
                margin-top: 40px;
                font-size:2.5rem;
            }
        }
        .alert-sub-title{
            text-transform: uppercase;
            text-align: center;
            margin-top: 20px  !important;
        }
        .alert-message{
            padding-left: 5vw !important;
            padding-right: 5vw !important;
            overflow-y: hidden;
            font-size: 2rem;
            margin: auto;
            @media screen and (max-height: 600px) {
                font-size:1.7rem;
            }
            @media screen and (min-width: 768px) {
                font-size: 1.8rem;
            }
            @media screen and (min-width: 1024px) {
                font-size:2rem;
            }
        }
        .alert-button-group {
            width:100%;
            margin-bottom: 20px;
            justify-content: center  !important;
            flex-direction: row !important;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                margin-bottom: 50px;
            }
            @media screen and (min-width: 1024px) {
                width:60% !important;
                margin-left: auto !important;
                margin-right:auto !important;
            }
            margin-top: 20px;
            .alert-button {
                height: 45px;
                width:40vw;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                border-radius: 0;
                @media screen and (min-width: 768px) {
                    width: 45%;
                }
                .button-inner {
                    justify-content: center;
                    text-align: center;
                    margin-top:0px;
                }
                &:first-child {
                    @extend .mainfont-button;
                    border-radius: 0;
                    margin: 0 8px 0 0 !important;
                }
                &:last-child {
                    @extend .mainfont-button;
                    margin: 0 !important;
                }
            }
            [dir="ltr"] .alert-md .alert-button {
                margin: 0 !important;
            }
        }
    }
    @each $i in O, 7, 11 {
        &.alert-margin#{$i} .alert-wrapper .alert-title {
            margin-top:$i !important;
        }
    }
    &.alert-paiement{
        .alert-title{
            margin-bottom: 50px;
            margin-top: 70px;
            color: #71dc71;
            &:before{
               font-family: "FontAwesome";
               position: absolute;
               top:30px;
               left: 45%;
               content: '\f058';
               color: #71dc71;
               font-size: 4rem;
           }
        }
        .alert-message{
            margin-bottom: 20px;
        }
    }
    &.alert_majeur {
        .alert-message{
            order: 3;
            padding: 10px !important;
            @media screen and (min-width: 1024px) {
                font-size:2rem !important;
            }
        }
        .alert-wrapper .alert-button-group {
            order: 2;
            width:100%;
            margin: auto 0;
            justify-content: center  !important;
            flex-direction: row !important;
            font-size: 1.3rem;
            .alert-button {
                line-height: 45px;
                height: 45px;
                width:80vw;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                border-radius: 0;
                margin: 10px auto !important;
                .button-inner {
                    justify-content: center;
                    font-size: 30px;
                    text-align: center;
                    margin-top:0px;
                    @media screen and (max-width: 374px) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
.alert-ios .alert-button {
    min-width:40vw !important;
}
.alert-ios{
    .alert-button {
        flex: none !important;
    }
}
[dir="ltr"] .alert-md .alert-button-group {
    padding: 0 !important;
}
.item-md.item-input.input-has-focus .item-inner {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.list-md .item-input.input-has-focus:last-child {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.list-md .item-input.input-has-focus:last-child .item-inner {
    box-shadow: none;
}
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child .item-inner {
    box-shadow: none;
}
.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.list-md .item-input.ng-invalid.ng-touched:not(.input-has-focus):last-child {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
}
.item-md.item-block .item-inner{
    border-bottom:0px solid transparent !important;
}
.version-text.app-mode {
    z-index: 9999;
    transition: color 0.5s;
    &::after {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        text-align: center;
        color: white;
        z-index: 9999;
        opacity: 0;
        transition: opacity 0.5s
    }
    &.localhost:hover,
    &.dev:hover,
    &.test:hover,
    &.staging:hover {
        color: transparent !important;
        transition: color 0.5s ease 5s;
        &::after {
            opacity: 0.3;
            transition: opacity 0.5s ease 5s
        }
    }
    &.localhost::after { content: "localhost"; background-color: white; color: black }
    &.dev::after { content: "dev"; background-color: blue; }
    &.test::after { content: "test"; background-color: green; }
    &.staging::after { content: "staging"; background-color: orange; }
}

price{
    .symbolCSSClass {
        padding: 0 2px;
    }
    &::after{
        content:"€";
    }
    .symbolDeviseCss{
        display: none;
    }
}

::-webkit-scrollbar {
    width: 6px !important;
    @media screen and (max-width: 768px) {
        width: 12px !important;
    }
} 
::-webkit-scrollbar-track {
    background-color: transparent;
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); 
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
    background-color: transparent;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
    background-color: black;
} 

.picker-toolbar-button{
    width:100%;
}
.msg_popup_qte{
    padding : 15px 0;
    text-align: left;
    opacity: 0.8;
    line-height: 2rem;
    max-height: 200px;
    overflow-y: auto;
}
.italic_popup_qte{
    opacity: 0.8;
    padding: 5px 0 15px 0;
    display: inline-flex;
}
