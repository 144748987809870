@import "fonts";
html,
body {
    font-size: 12px;
    font-family: $main-font;
    @media screen and (min-width: 768px) {
        font-size: 12px;
    }
}

.mainfont-button:not(.qte_min, .qte_max) {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-style: solid;
    display: flex;
    align-self: center;
    justify-content: center;
    font-family: $second-font;
    height: 40px !important;
    width: 77%;
    margin-top: 15px;
    border-width: 0px;
    /*Comment by sajid*/
    /*background: $color_white url("#{$assetPath}/Common/filet_tricolore.png") no-repeat center bottom;*/
    @include box-shadow(2px, 2px, 3px, 1px, 25, 89, 153, 0.26);
    color: $color_3;
    border-radius: 2px;
    white-space: nowrap;
    /*padding-top: 15px;*/
    font-size: 17px;
    font-weight: 600;
    span {
        font-size: 2.1rem;
        text-transform: uppercase;
    }
}

.mainfont-button-simple {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    display: flex;
    align-self: center;
    justify-content: center;
    height: 35px;
    border-style: solid;
    border-width: 0px;
    font-family: $second-font;
    font-size: 2.2rem;
    width: 50px;
    border-radius: 2px;
    color: $color_5;
    white-space: nowrap;
    background-color: transparent;
    @media screen and (max-width: 320px) {
        font-size: 2rem;
    }
    @media screen and (max-height: 500px) {
        font-size: 2.5rem;
    }
    span,
    .button-inner {
        text-transform: uppercase;
    }
}

ion-app {
    background: $color_1 !important;
    ion-radio,
    ion-checkbox {
        ion-label {
            color: $color_1;
        }
    }
    .checkbox-icon {
        border-width: 0 !important;
    }
    .checkbox-ios .checkbox-checked {
        border-color: transparent !important;
        background-color: transparent !important;
    }
    .checkbox-md .checkbox-checked {
        border-color: transparent !important;
        background-color: transparent !important;
    }
    .checkbox-inner,
    .radio-inner {
        display: none;
    }
    ion-item {
        background-color: transparent !important;
        @include input-form($second-font, 1.6rem, $color-text-input, $color-background-input, $color-border-input);
    }
    ion-datetime {
        button {
            border-bottom: 1px solid $color-border-input !important;
        }
        .datetime-text {
            margin-top: 13px;
        }
    }
    .radio-ios .radio-icon {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        left: 7px;
        top: 8px;
    }
    ion-checkbox,
    ion-radio {
        .checkbox-icon,
        .radio-icon {
            /*&:before {
                font-family: Ionicons;
                content: "\f1f6";
                color: $color_white;
                font-size: 25px;
            }
            &.checkbox-checked,
            &.radio-checked {
                &:before {
                    font-family: Ionicons;
                    content: "\f149";
                    color: $color_white;
                    font-size: 25px;
                }
            }*/
            .alert-checkbox-icon {
                border-radius: 0 !important;
            }
        }
        &.white {
            filter: none;
        }
    }
    //Checkbox
    div.checkbox {
        &:before {
            font-family: Ionicons;
            content: "\f1f6";
            color: $color_white;
            font-size: 25px;
        }
    }
    div.checkbox.checked {
        &:before {
            font-family: Ionicons;
            content: "\f149";
            color: $color_white;
            font-size: 25px;
        }
    }
    &.platform-ios,
    &.platform-wp {
        .ios-shortcuts {
            & button {
                background-color: $color_1;
                color: $color_2;
                &.selected {
                    background-color: $color_2;
                    color: $color_1;
                }
            }
        }
    }
    .grid-cell-container {
        flex-wrap: wrap;
        margin-bottom: 50px;
        display: grid;
        &.nb_grid-2 {
            @include gridcell(2);
        }
        &.nb_grid-3 {
            @include gridcell(3);
        }
        &.nb_grid-4 {
            @include gridcell(4);
        }
        &.nb_grid-6 {
            @include gridcell(6);
        }
    }
    // pour les options
    .nb_grid-2 {
        .grid-cell-container {
            flex-wrap: wrap;
            margin-bottom: 50px;
            display: grid;
            @include gridcell(2);
        }
    }
    .nb_grid-3 {
        .grid-cell-container {
            flex-wrap: wrap;
            margin-bottom: 50px;
            display: grid;
            @include gridcell(3);
        }
    }
    .nb_grid-4 {
        .grid-cell-container {
            flex-wrap: wrap;
            margin-bottom: 50px;
            display: grid;
            @include gridcell(4);
        }
    }
    .nb_grid-6 {
        .grid-cell-container {
            flex-wrap: wrap;
            margin-bottom: 50px;
            display: grid;
            @include gridcell(6);
        }
    }
    .picker-wrapper {
        background: $color_1 !important;
        button.picker-opt {
            color: $color_white;
        }
        button.picker-opt-selected {
            color: $color_white !important;
        }
        .picker-toolbar {
            background: $color_1 !important;
            width: 100%;
            .picker-button {
                color: $color_white;
            }
            .picker-toolbar-button:not(.picker-toolbar-cancel) {
                text-align: right;
            }
        }
    }
}

ion-header {
    background-color: $color_1 !important;
    //header title and button
    ion-toolbar {
        height: 65px;
        background: $color_1 !important;
        --background: $color_1 !important;
        border-bottom: 1px solid $color_white;
        ion-buttons {
            color: $color_white !important;
            ion-icon {
                color: $color_white !important;
            }
        }
        .back-button,
        .back-button-md {
            min-width: 30px;
            box-shadow: none;
            color: $color_white !important;
            //   margin-top: 5px;
        }
        ion-title {
            background: $color_1 !important;
            color: $color_white !important;
            .toolbar-title {
                color: $color_5 !important;
                font-family: $main-font;
                font-weight: 700;
                width: 100%;
                .subtitle {
                    width: 100%;
                    opacity: 0.8;
                    font-size: 1.5rem;
                    margin-top: -1px;
                    font-family: $second-font;
                }
            }
            div.subtitle {
                color: $color_white !important;
                text-transform: none;
            }
        }
    }
}

.toolbar-title-md {
    font-size: 1.6rem !important;
    text-transform: uppercase;
}

app-directory,
app-quote,
app-language-list,
app-options {
    ion-header {

       ion-toolbar {
            ion-title {
                .toolbar-title {
                    width: 100% !important;
                }
            }
        }
    }
}

app-language-list{
    background: $color_1 !important;
    --background: $color_1 !important;
    background-color: $color_1 !important;
    ion-header {
        ion-toolbar{
            background: $color_1 !important;
            --background: $color_1 !important;
            background-color: $color_1 !important;
            ion-title {
                text-align: center;
                width: 100% !important;

            }
            ion-icon{
                color: $color_white !important;
            }
        }
    }
    ion-content{
        background: $color_1 !important;
        --background: $color_1 !important;
        background-color: $color_1 !important;
    }
    ion-footer{
        ion-button{
            color: $color_white !important;
        }
    }
}

app-quote, app-quote-review{
    ion-content{
        background: $color_1 !important;
        --background: $color_1 !important;
        background-color: $color_1 !important;
    }
}

.menu-toolbar {
    .toolbar-background {
        background-color: $color_1 !important;
    }
    .btn-login {
        color: $color_2 !important;
        background-color: transparent !important;
        border: 2px solid $color_2;
        font-family: $main-font;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-login{
    color: $color_white !important;
    width: 180px;
}

.tabs-md .tabbar {
    background: $color_1 !important;
}

.card-background-page {
    ion-card {
        position: relative;
        text-align: center;
    }
    .card-title {
        position: absolute;
        top: 36%;
        font-size: 2em;
        width: 100%;
        font-weight: bold;
        color: #fff;
    }
    .card-subtitle {
        font-size: 1em;
        position: absolute;
        top: 52%;
        width: 100%;
        color: #fff;
    }
}

ion-badge {
    left: 50px;
    // icone panier en haut de page sur android
    &.top-badge {
        &:before {
            border: 1px solid white;
            top: -1px;
            @media screen and (min-width: 1024px) and (orientation: landscape) {
                top: 2px;
            }
        }
        &.badge-md {
            color: $color_white !important;
        }
    }
    &.tab-badge {
        background-color: transparent !important;
        &:before {
            border: 1px solid $color_white;
            top: -2px;
            border-radius: 20px;
        }
    }
}

.platform-android {
    app-directory {
        .grid-cell-container {
            margin-top: 0px;
        }
    }
    app-options,
    app-bundle {
        .grid-cell-container {
            margin-top: 0px;
        }
    }
}

.ios .alert-ios .alert-button-group {
    flex-direction: column;
    .btnvalider {
        margin-right: 10px;
        min-width: 20%;
    }
    .btncancel {
        width: 20% !important;
        min-width: 20%;
    }
}

.toolbar-background-md {
    background-color: $color_1 !important;
}

tabs-md .tabbar {
    background: $color_1 !important;
}

ion-alert {
    background-color: transparent !important;
    color: $color_2 !important;
    .alert-wrapper {
        background-color: $color_1 !important;
        border: 2px solid $color_white;
        border-radius: 20px !important;
        .alert-title {
            font-family: $main-font;
            letter-spacing: -1px;
            font-size: 1.6rem !important;
            color: $color_5;
        }
        .alert-sub-title {
            font-family: $main-font;
        }
        .alert-message {
            color: $color_2;
            font-family: $main-font;
            font-size: 1.4rem !important;
        }
        .alert-button-group {
            .alert-button {
                width: 48% !important;
                border: 1px solid $color_white;
                &:first-child {
                    @extend .mainfont-button;
                    @extend .button_1;
                    @extend .button-md;
                    letter-spacing: -0.5px !important;
                    border-radius: 0;
                    margin: 0 8px 0 0 !important;
                }
                &:last-child {
                    @extend .mainfont-button;
                    @extend .button_2;
                    @extend .button-md;
                    letter-spacing: -0.5px !important;
                    margin: 0 !important;
                }
                .button-inner {
                    font-size: 1.4rem;
                }
                .alert-button-inner{
                    font-size: 1.4rem !important;
                    text-align: center !important;
                }
            }
            [dir="ltr"] .alert-md .alert-button {
                margin: 0 !important;
            }
        }
    }
    @each $i in O, 7, 11 {
        &.alert-margin#{$i} .alert-wrapper .alert-title {
            margin-top: $i !important;
        }
    }
    &.alert_majeur {
        .alert-wrapper .alert-button-group {
            flex-direction: row !important;
            .alert-button {
                .button-inner {
                    font-size: 18px !important;
                }
                &:first-child {
                    @extend .button_1;
                }
                &:last-child {
                    @extend .button_2;
                }
            }
        }
    }
    &.alert-deconnect {
        .alert-wrapper .alert-button-group .alert-button .button-inner {
            font-size: 1.6rem;
        }
    }
}

.platform-ios {
    .tabbar {
        background: $color_1 !important;
    }
}

.back-button {
    color: $color_white;
    margin-top: 8px;
}

ion-footer.buttons-bar {
    padding-top: 16px;
    padding-bottom: 16px;
}

.list-ios .item-block .item-inner {
    border-bottom: 0.5px solid $color_white !important;
}

.button-md {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

#blocksSection {
    background: red !important;
}

app-type-barcode .digicode div {
    display: inline;
}

.alert_deconnexion {
    .button-inner {
        font-size: 12pt;
    }
}

.header-md::after,
.tabs-md[tabsPlacement="top"] > .tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement="bottom"] > .tabbar::before {
    background-image: none !important;
}

.header-md::after,
.tabs-md[tabsPlacement="top"] > .tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement="bottom"] > .tabbar::before {
    background-image: none !important;
}

.sc-ion-loading-md-h, .sc-ion-loading-md-s .spinner-crescent circle {
    stroke: #ffffff !important;
    color: #ffffff !important;
}

.sc-ion-loading-md-h, .sc-ion-loading-md-s .loading-wrapper {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    background: transparent !important;
    transform: scale(1.2) !important;
}

ion-spinner {
    position: unset !important;
    display: inline-block;
    height: 28px;
}

.loading-ios .loading-wrapper {
    color: #000;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    background: transparent !important;
}

.loading-ios {
    .loading-content {
        display: none;
    }
}

::-webkit-scrollbar {
    background-color: $color_1;
}

.picker-wrapper {
    background: $color_linear_background_2 !important;
    button.picker-opt {
        color: $color_1;
    }
    button.picker-opt-selected {
        color: $color_1 !important;
    }
    .picker-toolbar {
        background: $color_linear_background_2 !important;
        width: 100%;
        .picker-button {
            color: $color_1;
        }
        .picker-toolbar-button:not(.picker-toolbar-cancel) {
            text-align: right;
        }
    }
}

.bt_cmd {
    // display:none !important;
}

img {
    &.error {
        display: none;
    }
}

app-price {
    &:after {
        margin-left: -3px !important;
        font-size: 1.5rem !important;
    }
}

app-gcs,
app-legals {
    .back-button {
        display: inline;
    }
}

//masque la barre du prix barré pour ce thème ( barre utilisée sur Gifi ) et masque le prix Club
grid-article-cell,
app-grid-cell {
    .unit-price {
        .club_price {
            display: none;
        }
    }
}

app-info {
    .scroll-content,
    ion-footer {
        .price_article {
            .line_through {
                display: none;
            }
            .club_price {
                display: none;
            }
        }
    }
}

app-order-lines {
    app-price {
        &.discard {
            .line_through {
                display: none;
            }
        }
    }
    .total {
        price {
            &.discard {
                .line_through {
                    display: none;
                }
            }
        }
    }
}

app-price .stroke .hidden {
    opacity: 0;
}

.device-type-mobile {
    app-price {
        &.stroke {
            &.hidden {
                opacity: 0;
                display: none;
            }
        }
        .deviceVirgule {
            display: none;
        }
        .devicePoint {
            display: inline;
        }
    }
}

.device-type-terminal {
    app-price {
        &.stroke {
            &.hidden {
                opacity: 0;
                display: none;
            }
        }
        .deviceVirgule {
            display: none;
        }
        .devicePoint {
            display: inline;
        }
    }
}

.club_price {
    display: none;
}
