app-cross-sellings {
    .grid-cell-container {
        margin-top: 20px;
        background: transparent !important;
    }
    ion-title {
        color: white !important;
        font-family: $main-font !important;
        .toolbar-title {
            margin-top: 10px !important;
            text-align: center;
            .subtitle {
                opacity: 0.7;
                font-size: 1.5rem;
                text-align: center;
            }
        }
    }
    .content {
        background-color: $color_1 !important;
        &.article-page-layout-line {
            .scroll-content {
                background: $color_1 !important;

                @at-root {
                    // fixup d'un problème d'affichage du 'content' par dessus le box-shadow de la ion-header
                    .ios page-options .content .scroll-content {
                        margin-top: 65px !important;
                    }
                }
                .checkbox {
                    height: 25% !important;
                    top: 24% !important;
                    left: 10px !important;
                    z-index: 1;
                    float: left;
                    &:before {
                        color: $color_1;
                        font-size: 30px;
                    }
                }
                p {
                    color: $color_1;
                    width: 75%;
                    line-height: 1.6rem;
                    padding-left: 40px;
                }
            }
        }

        &.article-page-layout-grid {
            .scroll-content {
                background: $color_1 !important;

                @at-root {
                    // fixup d'un problème d'affichage du 'content' par dessus le box-shadow de la ion-header
                    .ios page-options .content .scroll-content {
                        margin-top: 65px !important;
                    }
                }
                .checkbox {
                    height: 25% !important;
                    top: 38% !important;
                    left: 7px !important;
                    z-index: 1;
                    float: left;
                    color: $color_1;
                    &:before {
                        color: $color_1;
                        font-size: 30px;
                    }
                }
                p {
                    color: $color_white;
                    width: 100%;
                    line-height: 1.6rem;
                }
            }
        }
    }
    ion-footer {
        background: $color_1;
        height: 70px;
        line-height: 70px;
        button {
            font-size: 2.3rem;
        }
        button {
            font-size: 2rem !important;
        }
        .button_color_2 {
            margin-left: 10px !important;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
