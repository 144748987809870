app-code-scan {
   // background-color: $color_black;
    p, li, hr, div, input {
        margin: auto;
        font-family: $main-font;
        color: $color_2;
        font-size: 2.2rem;
        font-weight: 400;
    }
    .scan-overlay {
        background: $color_1 !important;
        width: 100%;
        height: 100%;
        text-align: center;
        p{
            font-size:1.4rem !important;
        }
    }
    .digicode ion-input input {
        background: $color_1 !important;
        border: 1px solid white;
        border-radius: 5px;
        height:40px;
        line-height: 40px;
        font-size: 1.8rem;
        color : $color_2 !important;
        width:200px !important;
        margin-left:auto !important;
        margin-right:auto !important;
        text-align: center;
        &::placeholder{
            color:white;
            text-align: center;
            font-size: 1.5rem;
        }
    }
    .scroll-content{
        background-color: transparent !important;
    }
    ion-content{
        background: transparent url('#{$assetPath}/Common/background_scan.png') no-repeat center 200px !important;
        color: $color_2;
        &.content-md, &.card-md{
            background-color: transparent !important;
        }
    }
    ion-footer{
        background: $color_1 !important;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    
    ion-content{
        background-color: transparent !important;
        --background: transparent !important;
    }


    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}


app-scan{
    .ios-shortcuts{
        div{
            button{
                background: $color_1;
                color: $color_white;
                &.selected{
                    background: $color_white;
                    color: $color_black;
                }
            }
        }
    }

   
    ion-content{
        background-color: transparent !important;
        --background: transparent !important;
    }


    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
