@import "../variables";
app-user-form {
    .edit-button {
        & + ion-icon {
            color: #aaa;
        }
        &:checked + ion-icon {
            color: #fff;
        }
    }
    .content.editable {
        .error.active {
            color: $color_5;
        }
        .error.active + .mandatory:before {
            color: $color_5;
        }
    }
    .form-register {
        @include margin-auto;
        font-family: $second-font !important;
        ion-radio{
            --color: $color_white !important;
            --color-checked: $color_white !important;
            color: $color_white !important;
            color-checked: $color_white !important;
        }
        /*.radio-icon {
            &:before {
                color: $color_white !important;
            }
        }*/
        ion-label {
            color: $color_white !important;
        }
        .item-input ion-input,
        .item-input ion-textarea {
            color: $color_white;
            input {
                font-family: $second-font !important;
                color: $color_white !important;
                &::placeholder {
                    color: $color_white !important;
                }
            }
        }
        ion-item {
            --background: $color_1 !important;
            .bt_pass {
                span {
                    color: $color_white !important;
                    ion-icon{
                        color: $color_white !important;
                        font-size: 1.2rem;
                    }
                    /*i {
                        &.eye_on {
                            &:after {
                                color: $color_white !important;
                            }
                        }
                        &.eye_off {
                            &:after {
                                color: $color_white !important;
                            }
                        }
                    }*/
                }
            }
        }
    }
    .check_offre {
        .checkbox-icon {
            &:before {
                color: $color_1;
            }
        }
        .checkbox-icon {
            &:before {
                color: $color_2;
            }
        }
    }
    .accept {
        font-size: 1rem;
        a {
            color: $color_white;
        }
    }
    ion-item {
        --background: $color_1 !important;
        font-family: $second-font;
        .datetime {
            .datetime-text {
                color: $color_2;
            }
        }
        .itemcontent {
            color: $color_2;
            input::placeholder,
            .datetime-placeholder {
                color: $color_white;
            }
            ion-textarea,
            ion-input,
            .datetime {
                font-family: $second-font;
            }
        }
        ion-label {
            color: $color_white;
        }
        .radio-md.radio-disabled,
        .item-md.item-radio-disabled ion-label {
            opacity: 1 !important;
        }
    }
    .button_color_1 {
        &.modif {
            margin-top: 50px;
            width: auto !important;
            background: $color_1 !important;
            color: $color_white !important;
            span {
                font-size: 1.4rem;
                color: $color_white !important;
                opacity: 0.8;
            }
        }
    }

    .password-icon{
        color: $color_white !important;
    }

    .ion-item-dob{
        --border-color: $color_white !important;
    }

    ion-footer {
        background: $color_1;
    }

    ion-content{
        --background: $color_1 !important;
        background: $color_1 !important;
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }

    ion-radio{
        border: 1px solid $color_white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding-bottom: 4px;
        color: $color_white;
    }

    ion-checkbox{
        --border-color-checked: $color_white !important;
    }

    ion-datetime{
        color: $color_white !important;
        border-bottom: 1px solid $color_white !important;
    }

    
}
