app-reservation {
    .scroll-content, ion-footer {
        background: $color_1;
        font-size:2rem;
        line-height: 2.5rem;
        color:$color_white;
    }
    hr.separator{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0.3), rgba(255, 255, 255, 1), rgba(255, 255, 0, 0.3));
    }
    hr.separator_1{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.40), rgba(255, 255, 0, 0));
    }
    hr.separator_2{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.80), rgba(255, 255, 0, 0));
    }
    hr.separator_day{
        background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 1), rgba(255, 255, 0, 0));
    }
    hr.separator_outadated{
        background-color: $color_5;
    }
    .text-hour{
        color: white;
        background-color: $color_4;
        padding:5px;
        font-size: 1.6rem !important;
    }
    .slot-text {
        background-color: rgba(0, 255, 240, 1);
        font-family: MontserratBold;
        padding: 75px;
        font-size: 1.4rem;
        color: white;
        text-align: center;
        text-transform: uppercase;
    }
    h2{
       padding-top:8px !important;
    }
    h3{
        margin-top: 12px !important;
    }
    .selected {
        background-color: $color_5;
        color: $color_4;
    }
    .unavailable {
        background-color: rgba(222, 33, 33, 1);
    }
    .disabled {
        background-color: #6f7574;
    }
    .outdated {
        opacity: 0.5;
    }
    ion-slides {
        ion-slide{
            color:$color_white;
            font-family: "Montserrat";
            background:$color_1;
        }
    }
    .swiper-container{
       
        background:$color_1;
    }
    .swiper-slide{
        font-size: 1.6rem !important;
        cursor: pointer;
    }
    .calendar {
        font-size: 1.6rem !important;
        .monthview-datetable {
            background-color: white!important;
            th, td:not(.monthview-disabled){
                color:$color_1 !important;
            }
            th{
                small{
                    font-size: 65% !important;
                }
            }
        }
    }
    .monthview-selected{
        color:grey !important;
        background:#d3dcf0 !important;
    }
    .back_left, .back_right{
        color: $color_white !important;
        span{
            font-size: 2.2rem;
        }
        ion-icon{
            font-size: 1.6rem;
            color: $color_white !important;
            &:before{
                color: $color_white;
            }
        }
    }

    .title {
        font-family: $main-font;
    }
    ion-footer{
        background: $color_1 !important;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    ion-content{
        h2{
            color: $color_white !important;
        }
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
        ion-button{
            margin-top: unset !important;
        }
        ion-icon{
            color: $color_white !important;
            height: 20px !important;
            width: 20px !important;
        }
    }
}