app-order-lines {

    app-price{
        ion-icon{
            color: $color_5 !important;
            padding-left: 20px !important;
        }
    }
    
    .store-name {
        color: $color_white !important;
        font-family: $main-font;
        margin-left: 32px !important;
    }
    .list-header {
        color:$color_white;
    }
    button, .button, ion-icon {
        color: $color_2;
    }
    .order-line {
       .level0, .level1, .level2, .level0_price{
           font-family: $main-font;
           .article_name{
                color: $color_5;
           }
           .btn_cart_personalize{
                color: $color_7;
                span{
                    padding-right:10px;
                    font-size: 12px;
                    font-family : $font-arial;
                    @media screen and (max-width: $medium-screen)  {
                        display: none;
                    }
                }
            }
            .article_price{
                color: $color_5;
            }
        }
        .level0{
            .article_name{
                padding-top: 12px;
            }
           .article_qte{
                .qte{
                    color: $color_5 !important;
                    font-size: 1.8rem;
                    padding-top: 3px;
                }
                button{
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                }
            }
        }
        app-price{
            &:after{
                margin-top: 3px;
                margin-left: 2px;
            }
        }
       
        .level1{
            padding-left: 20px !important;
            .article_name{
                color: $color_5;
            } 
            app-price{
                &:after{
                    margin-top: 0px;
                }
            }
        }
        .level2{
            padding-left: 40px !important;
            .article_name{
                color: $color_1;
            } 
            app-price{
                &:after{
                    margin-top: 0px;
                }
            }
        }
        .icon-create{
            &::before{
                color: $color_7;
            }
        }
      
    }
    .btn_cart_update{
        color: $color_7;
        span{
            padding-right:10px;
            font-size: 13px;
            font-family : $font-arial;
        }
    }
    .list-header{
        background: $color_6;
        font-weight: 700;
        color:$color_6;
    }
    .total {
        color: $color_5;
        font-family: $main-font;
        .price{
            color: $color_5;
        }
        app-price{
            &:after{
                margin-top: 10px;
                font-size: 2rem !important;
                margin-left: 2px;
            }
            &.discard{
                &:after{
                    margin-top: 5px;
                }
            }
        }
    }
    .tva_detail,.tva_titre {
        color: $color_white;
        font-size: 1.8rem;
    }
    .divider{
        background:$color_white;
    }
    .divider_compose{
        background:transparent !important;
        div{
            background:$color_white;
        }
    }

    ion-footer{
        button{
            @include margin-auto;
        }
    }

    ion-icon{
        font-size: 1.2rem;
    }
}