app-nickname {

    p, li, hr, div, input {

        margin: auto;
        font-family: $main-font;
        color: $color_2;
        font-size: 1.4rem;
        font-weight: 400;
        padding-left: 10px;
        padding-right: 10px;
    }
    p{
        padding-top: 20px;
    }
    .digicode{
        text-align: center;
    }
    .pseudo ion-input input {
        background: $color_1 !important;
        border: 1px solid white;
        border-radius: 5px;
        height:40px;
        line-height: 40px;
        font-size: 2.5rem;
        color : $color_2 !important;
        margin-left:auto !important;
        margin-right:auto !important;
        text-align: center;
        &::placeholder{
            color:white;
            text-align: center;
            font-size: 2.5rem;
        }
    }
    ion-content{
       // background: transparent url('#{$assetPath}/Common/background_scan.png') no-repeat center 250px !important;
        color: $color_2;
        background: $color_1 !important;
    }
    ion-footer{
        background: $color_1;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
