@import "colors";
@import "fonts";
$assetPath: $assets-path + "images/Theme_Heyou";
$main-font: "Montserrat";
$second-font: "Lato";
$third-font: "Lato";
$fontawesome: "FontAwesome";
$price-font: "Lato";
$price-currency-font: "Lato";
$xsmall-screen: 320px;
$medium-screen: 1024px;
$large-screen: 1366px;
$xmedium-screen: 768px;
@function screen-size($keys...) {
    $value: (
        portrait: (
            small: (
                width: "320px",
                height: "480px",
            ),
            medium-small: (
                width: "400px",
                height: "600px",
            ),
            medium: (
                width: "414px",
                height: "736px",
            ),
            large: (
                width: "768px",
                height: "1024px",
            ),
        ),
    );
    @each $key in $keys {
        $value: map-get($value, $key);
    }
    @return $value;
}

$small-width: screen-size(portrait, small, width);
$small-height: screen-size(portrait, small, height);
$medium-small-width: screen-size(portrait, medium-small, width);
$medium-small-height: screen-size(portrait, medium-small, height);
$medium-width: screen-size(portrait, medium, width);
$medium-height: screen-size(portrait, medium, height);
$large-width: screen-size(portrait, large, width);
$large-height: screen-size(portrait, large, height);
@mixin margin-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

@mixin box-shadow($value_1, $value_2, $value_3, $value_4, $color_1, $color_2, $color_3, $opacity) {
    -webkit-box-shadow: $value_1 $value_2 $value_3 $value_4 rgba($color_1, $color_2, $color_3, $opacity) !important;
    -moz-box-shadow: $value_1 $value_2 $value_3 $value_4 rgba($color_1, $color_2, $color_3, $opacity) !important;
    box-shadow: $value_1 $value_2 $value_3 $value_4 rgba($color_1, $color_2, $color_3, $opacity) !important;
}

@mixin input-form($fontfamily, $fontsize, $fontcolor, $backgroundcolor, $borderbottomcolor) {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="tel"] {
        font-family: $fontfamily !important;
        font-size: $fontsize !important;
        color: $fontcolor !important;
        background-color: $backgroundcolor !important;
        border: 0 !important;
        border-bottom: 1px solid $borderbottomcolor !important;
        border-radius: 0 !important;
        padding-left: 3px;
        padding-right: 3px;
        height: 30px;
    }

    input[type="tel"] {
        padding-left: 50px !important;
    }
    // floating label
    ion-label.floating {
        color: $color_white !important;
        opacity: 0.6 !important;
        margin: 2px 0 2px 0;
    }
    .text-input-md {
        width: 100% !important;
    }
    &.item-md,
    &.item-ios,
    &.item-wp {
        padding-left: 0;
    }
    .sc-ion-input-ios-h {
        /*--padding-top: 0px;*/
        /*--padding-bottom: 5px;*/
        height: 50px !important;
    }
    .sc-ion-input-ios{
        padding-bottom: 10px;
        height: 40px !important;
        z-index: 999;
        font-size: 60% !important;
        /*padding-top: 5px;*/
    }
}


@mixin gridcell($nbcol) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    @media screen and (min-width: 768px) and (max-width: 1999px) {
        grid-template-columns: repeat($nbcol, 1fr);
        grid-gap: 15px;
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat($nbcol, 1fr);
        grid-gap: 20px;
    }
}

@mixin content_padding() {
    padding: 0 5px !important;
    @media screen and (min-width: 768px) and (max-width: 1999px) {
        padding: 0 10px !important;
    }
    @media screen and (min-width: 1200px) {
        padding: 0 10px !important;
    }
}
