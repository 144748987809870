@import "fonts";

.loading-ios .loading-wrapper {
    border-radius: 17px !important;
    max-width: 270px !important;
    max-height: 90% !important;
    color: $color_2 !important;
    background: $color_1 !important;
    padding: 18px 23px !important;
}
.loading-ios .loading-spinner + .loading-content {
    margin-left: 15px !important;
    font-family: $main-font;
    font-weight: 100;
    font-size: 1.6rem;
}
.container {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
}
[class^="form"] {
    //  @extend .container;
}
.input-inner,
.input-wrapper {
    align-items: center !important;
}
.horizontal-margin {
    margin-left: 16px;
    margin-right: 16px;
}
.divider {
    background-color: #9af5f6;
    height: 1px;
    width: 100%;
    &.large {
        height: 2px;
        background-color: $color_2;
    }
}

.colorwhite {
    color: $color_2 !important;
}
.backgroundblack {
    background-color: $color_1 !important;
}
.button-md:not(.qte_min, .qte_max) {
    // box-shadow: 3px 4px 10px 2px rgba(6, 59, 60, 0.3) !important;
}
.button_1,
.button_2,
.button_3,
.button_4 {
    border-color: $color_white;
    transition: all 0.2s ease;
    letter-spacing: 1px;
    border-radius: 0 !important;
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: auto !important;
    @extend .mainfont-button;
}
.button_simple {
    transition: all 0.2s ease;
    letter-spacing: 1px;
    border-radius: 0 !important;
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: auto !important;
    @extend .mainfont-button-simple;
}
.button_1 {
    background-color: $color_5 !important;
    &:active,
    &:hover {
        span {
            color: $color_3 !important;
        }
    }
    span {
        color: $color_1 !important;
    }
}
.button_2 {
    background-color: $color_white !important;
    color: $color_1 !important;
    &:active,
    &:hover {
        span {
            color: $color_3 !important;
        }
    }
    span {
        color: $color_1 !important;
    }
}
.button_3 {
    background-color: $color_white !important;
    color: $color_1 !important;
    &:active,
    &:hover {
        span {
            color: $color_3 !important;
        }
    }
    span {
        color: $color_1 !important;
    }
}
.button_4 {
    background-color: transparent !important;
    color: $color_white !important;
    &:active,
    &:hover {
        span {
            color: $color_white !important;
        }
    }
    span {
        color: $color_white !important;
    }
}
.button_color_1 {
    @extend .button_1;
}
.button_color_2 {
    @extend .button_2;
}
.button_color_3 {
    @extend .button_3;
}
.button_simple_color_1 {
    @extend .button_simple;
}
.wp {
    h2 {
        font-size: 26px !important;
    }
    .col-qty {
        margin-top: 0 !important;
    }
    .alert-wrapper {
        border: 1px solid $color_2;
    }
}
.text-input-wp {
    border: 0px solid $color_2;
}
.ios-shortcuts {
    background-color: $color_1 !important;
}

.toolbar-title-ios {
    font-size: 2rem !important;
}
.back-button {
    color: $color_white;
    font-size: 20px;
}
.select-alert {
    .alert-message {
        display: none;
    }
    .alert-radio-group {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
        margin-bottom: 30px;
        .alert-radio-icon {
            border-color: white;
        }
        .alert-md [aria-checked="true"] .alert-radio-label {
            border-color: white !important;
        }
        .alert-radio-inner {
            background-color: white !important;
        }
        .alert-md [aria-checked="true"] .alert-radio-icon {
            border-color: white !important;
        }
        .alert-radio-label {
            color: white !important;
            font-size: 1.5rem;
            white-space: normal !important;
        }
    }
    .alert-radio-icon {
        border-color: white !important;
    }
}
.content-md,
.card-md {
    background-color: $color_1 !important;
}
ion-menu {
    .content-md,
    .card-md {
        background-color: $color_2 !important;
    }
    ion-header{
        left: 0;
        top: 0;
        position: relative;
        z-index: 10;
        display: block;
        width: 100%;
        ion-toolbar{
            text-align: center;
            --background: $color_1 !important;
            background-color: $color_1 !important;
            height: unset !important;
        }
    }
    ion-content{
        background: $color_white !important;
        --background: $color_white !important;
        ion-list{
            ion-menu-toggle{
                ion-item{
                    ion-icon{
                        color: $color_1 !important;
                    }
                }
            }
        }
    }
}
app-cards-list {
    background-color: $color_1 !important;
}
.bt_cmd {
    @include box-shadow(0px, 0px, 0px, 0px, 0, 0, 0, 0);
}

ion-menu-button{
    color: $color_white !important;
}

ion-header ion-toolbar ion-title {
    color: $color_5 !important;
    font-family: $main-font;
    font-weight: 700;
    
}
.alert-margintop11{
    margin-top: 11px;
}

.header-ios{
    margin-bottom: 20px;
    height: 60px;
    ion-menu-button{
        /*margin-top: 20px;*/
    }

    .header-background{
        /*height: 80px;*/
    }
}

ion-header {
   ion-toolbar {
        min-height: 70px;
        border-bottom: unset;
    }
    ion-toolbar.ios{
        min-height: 80px !important;
        padding-top: 35px !important;
    }
}
