app-modal-command {
    .margintop{
        margin-top: 20px;
    }
    .text{
        color: $color_white;
        font-size: 2.5rem;
        font-family: $main-font;
        div{
            width: 100%;
        }
    }
    p {
        color : $color_white !important;
        font-family: $main-font;
    }
    .title{
        font-size: 1.8rem !important;
        font-family: $main-font;
    }
    .back-button{
        color:$color_white;
        margin-top:-20px !important;
    }
    .content-md, .card-md {
        /*comment by sajid*/
        /*background:$color_1 url('#{$assetPath}/Common/background_grid.png') repeat center bottom !important;*/
        background:$color_1 url('#{$assetPath}/Common/background.png') repeat center bottom !important;
        background-size: contain !important;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}