app-validation-order {
    background-color: #2cb6b9 !important;
    ion-content {
        background-color: transparent !important;
    }
    a {
        color:$color_white;
        font-family: $second-font;
    }
    p {
        color : $color_white !important;
        font-family: $second-font;
        font-size: 1.5rem !important;
        padding-left: 20px !important;
    }
    ion-label{
        color:$color_white;
        font-family: $second-font;
        font-size: 2.2rem;
    }
    ion-list {
        background: $color_1 !important;
        ion-item, .item-block{
            background-color: transparent;
            color: $color_white;
            font-family: $second-font;
            ion-label {
                font-size: 2.2rem;
            }
            ion-checkbox, ion-radio {
                .checkbox-icon, .radio-icon {
                    top: -3px;
                    &:before{
                        content:"";
                    }
                    /*&.checkbox-checked, &.radio-checked {
                        background-image:none !important;
                        &:before{
                            font-family: Ionicons;
                            content:"\f2bc";
                            color : $color_white;
                            font-size: 25px;
                        }
                    }*/
                }
                &.white {
                    filter: none;
                }
            }
            .radio-md .radio-icon{
                border:0px solid transparent;
            }
        }
        .item-cover{
            border-bottom: 0.5px solid $color_white;
        }
    }
    .amount{
        p{
            font-size: 2.2rem;
        }
        .text{
            font-size: 1.1rem;
            line-height: 20px;
        }
    }
    .item-radio-checked.item ion-label {
        color: $color_white !important;
    }
    .cgu-item{
        .item-inner{
            border-bottom: 0 solid transparent !important;
            ion-label{
                font-size: 1.4rem !important;
                color: $color_white !important;
            }
        }

        ion-label{
            font-size: 0.9rem !important;
            color: $color_white !important;
            padding-left: 5px !important;
        }
        .checkbox-icon, .radio-icon {
            border:0 solid transparent;
            width: 30px;
            height: 30px;
            border-radius: 0% !important;
            background-image:none !important;
            background-color: transparent;
            &:before{
                font-family: Ionicons;
                content:"\f1f6";
                color : $color_white;
                font-size: 25px;
            }
            &.checkbox-checked, &.radio-checked {
                background-image:none !important;
                &:before{
                    font-family: Ionicons;
                    content:"\f149";
                    color : $color_white;
                    font-size: 25px;
                }
            }
            .alert-checkbox-icon{
                border-radius: 0 !important;
            }
        }
    }
    .item.item-md .checkbox-md {
        margin: 9px 8px 9px 4px;
    }
    ion-footer{
        background: $color_1 !important;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
        ion-item{
            background-color: $color_1 !important;
            --background: $color_1 !important;
        }
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }

    ion-radio{
        .radio-inner{
            width: 25px !important;
            height: 40px !important;
        }
        /*--color: transparent !important;
        --color-checked: transparent;*/
    }

    .radio-inner {
        width: 55% !important;
        height: 100% !important;
    }


}
