app-quote, app-quote-review {
    .toolbar-title {
        margin-top: 0 !important;
    }
    .scroll-content{
        background: $color_1;
        margin-bottom: 120px;
        button{
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
        }
    }
    .paniervide-text {
        color: $color_white;
        font-size: 1rem;
    }
    .quote-header {
        .title {
            font-size: 4vw;
            color: $color_white;
        }
    }
    ion-footer{
        background: $color_1;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    ion-content{
        --background: $color_1 !important;
    }

    .mobile_button {
        @include margin-auto;
        @media screen and (min-width: $medium-screen) {
            display: none;
        }
    }

    .widget_button {
        padding-bottom: 50px;
        width: 300px;
        @include margin-auto;
        text-align: center;
        @media screen and (max-width: 1023px) {
            display: none;
        }
        button {
            @include margin-auto;
            &.novisible {
                display: none !important;
            }
            &.visible {
                display: flex !important;
            }
        }
    }

    app-order-lines {
        app-price{
            ion-icon{
                margin-left: 0px !important;
                font-size: 1rem !important;
                color: $color_5 !important;
            }
        }
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }
}