app-pick-up {
	.scroll-content{
		background: $color_1;
	}
	.grid {
		.button_icon_0 {
			ion-icon {
				background: transparent url("#{$assetPath}/Common/pick_0.png") center center no-repeat;
				background-size: contain;
			}
		}
		.button_icon_1 {
			ion-icon {
				background: transparent url("#{$assetPath}/Common/pick_1.png") center center no-repeat;
				background-size: contain;
			}
		}
		.button_icon_2 {
			ion-icon {
				background: transparent url("#{$assetPath}/Common/pick_2.png") center center no-repeat;
				background-size: contain;
			}
		}
		.button_icon_3 {
			ion-icon {
				background: transparent url("#{$assetPath}/Common/pick_3.png") center center no-repeat;
				background-size: contain;
			}
		}
		.button_icon_4 {
			ion-icon {
				background: transparent url("#{$assetPath}/Common/pick_4.png") center center no-repeat;
				background-size: contain;
			}
		}
		.button_icon_5 {
			ion-icon {
				/*Comment by sajid*/
				/*background: transparent url("#{$assetPath}/Common/pick_5.png") center center no-repeat;*/
				background-size: contain;
			}
		}
		@include gridcell(2);
		@media screen and (min-width: 1024px){
			@include gridcell(4);
		}
		.button_icon_0,  .button_icon_1,  .button_icon_2,  .button_icon_3,  .button_icon_4 ,  .button_icon_5 {
			color: $color_white;
			font-family: $second-font;
		}
	}

	ion-content{
		background-color: $color_1 !important;
		--background: $color_1 !important;
	}

	ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
   	}
}