@mixin fontdef($FontPath, $FontName, $FontVersion, $FontType:"Regular") {
    src:  url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.eot?v=#{$FontVersion}');
    src:  url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.eot?#iefix&v=#{$FontVersion}') format('embedded-opentype'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff2?v=#{$FontVersion}') format('woff2'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff?v=#{$FontVersion}') format('woff'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.ttf?v=#{$FontVersion}') format('truetype'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.svg?v=#{$FontVersion}##{$FontName}#{$FontType}') format('svg');
}

@mixin fontdef-woff($FontPath, $FontName, $FontVersion:"1.0.0", $FontType:"Regular") {
    src:	url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff2?v=#{$FontVersion}') format('woff2'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff?v=#{$FontVersion}') format('woff'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.ttf?v=#{$FontVersion}') format('truetype');
}

@mixin fontdef-onlywoff($FontPath, $FontName, $FontVersion:"1.0.0", $FontType:"Regular") {
    src: url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.woff?v=#{$FontVersion}') format('woff'),
    url('#{$FontPath}/#{$FontType}/#{$FontName}-#{$FontType}.svg?v=#{$FontVersion}##{$FontName}#{$FontType}') format('svg');
}

$FontPathLato: "../../assets/fonts/Lato";
$FontPathQuicksand: "../../assets/fonts/Quicksand";
$FontPathChampagne :"../../assets/fonts/Champagne";
$FontPathMontserrat :"../../assets/fonts/Montserrat";
$FontPathAwesome:"../../assets/fonts/FontAwesome";

$FontName: "" !default;
$FontVersion: "1.0.0" !default;


//FontAwesome
@font-face {
    font-family: "FontAwesome";
    src: url('#{$FontPathAwesome}/Regular/FontAwesome.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
//Lato
@font-face {
    font-family: "Lato";
    @include fontdef($FontPathLato, Lato, $FontVersion, "Regular");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "LatoBold";
    @include fontdef($FontPathLato, Lato, $FontVersion, "Bold");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "LatoLight";
    @include fontdef($FontPathLato, Lato, $FontVersion, "Light");
    font-weight: 300;
    font-style: normal;
}

//Quicksand
@font-face {
    font-family: "Quicksand";
    @include fontdef-woff($FontPathQuicksand, quicksand, $FontVersion, "Regular");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "QuicksandBold";
    @include fontdef-woff($FontPathQuicksand, quicksand, $FontVersion, "Bold");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "QuicksandExraBold";
    @include fontdef-woff($FontPathQuicksand, quicksand, $FontVersion, "ExtraBold");
    font-weight: 700;
    font-style: normal;
}

//Champagne
@font-face {
    font-family: "Champagne";
    @include fontdef($FontPathChampagne, Champagne, $FontVersion, "Regular");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "ChampagneBold";
    /*Comment by sajid*/
    /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "ChampagneBoldItalic";
    /*Comment by sajid*/
    /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "ChampagneItalic";
    /*Comment by sajid*/
    /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
    font-weight: 300;
    font-style: normal;
}
//Montserrat
@font-face {
    font-family: "Montserrat";
    @include fontdef($FontPathMontserrat, Montserrat, $FontVersion, "Regular");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "MontserratBold";
    @include fontdef($FontPathMontserrat, Montserrat, $FontVersion, "Bold");
    font-weight: 800;
    font-style: normal;
}