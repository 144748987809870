app-take-away {
    .title {
        margin-bottom: 0px !important;
        text-align: left !important;
        .toolbar-title {
            text-transform: none;
        }
    }
    .grid {
        @include gridcell(2);
        .button_icon_1 {
            ion-icon {
                background: transparent url("#{$assetPath}/Common/store.png") center center no-repeat;
                background-size: contain;
            }
        }
        .button_icon_2 {
            ion-icon {
                background: transparent url("#{$assetPath}/Common/take-away.png") center center no-repeat;
                background-size: contain;
            }
        }

        .button_icon_1,  .button_icon_2 {
            /*font-family: $second-font;*/
            /*color: $color_white;*/
        }
    }
    .scroll-content {
        background: $color_1 !important !important;
        overflow-y: hidden !important;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
