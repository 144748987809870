planning {
    .planning-bloc {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .separator-bloc {
        pointer-events: none;
        position: absolute;
        z-index: 1;
        transform: translateY(-50%);
        width: 100%;
    }
    .slot {
        margin: -2px 5px 1px 5px;
        border-radius: 10px;
        width: 96%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 1;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        @media screen and (min-width: 768px) {
            width: 99%;
        }
        .slot-text {
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 99%;
            display: flex;
            text-transform: uppercase;
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
        }
    }
}
