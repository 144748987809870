app-password-lost, app-password-change{
    ion-content, ion-list, ion-item, ion-label {
        --background: $color_1 !important;
        background: $color_1 !important;
    }
    ion-footer{
        /*background: transparent !important;*/
         --background: $color_1 !important;
        background: $color_1 !important;
    }

    ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }

    .form-password {
        @include margin-auto;
    }

    ion-icon, ion-label{
        color: $color_white !important;
    }


}