@import "../variables";
app-profile {

	.borderbottom{
		//border-bottom: 1px solid black !important;
	}
	.welcome{
		font-size: 1.4rem !important;
	    line-height: 1.9rem;
	}

	.deco{
		display: none;
		width:121px;
		height: 38px;
		margin-left:auto;
		margin-right: auto;
		background: white url('#{$assetPath}/Common/deco.png') no-repeat center !important;
		margin-bottom:15px;
	}
	.visuel_profil{
		width:100%;
		height: 200px;
		margin-left:auto;
		margin-right: auto;
		/*Comment by sajid*/
		/*background: white url('#{$assetPath}/Common/visuel_profil.png') no-repeat !important;*/
		margin-bottom:15px;
		background-size: contain;
	}
	[dir="ltr"] .card-ios, [dir="rtl"] .card-ios {
		border: 1px solid $color_1;
	}
	.store-detail {
		display: flex;
		ion-card, ion-list {
			
			margin: 10px auto !important;
			
		}
		.store-name{
		    font-size: 1.4rem !important;
		    line-height: 1.9rem;
		}
		p{
			font-size: 1.2rem !important;
		    line-height: 1.5rem !important;
		}
		ion-list{
			width: 86vw !important;
			ion-button{
				width:100% !important;
			}
		}
		ion-card {
			width: 86vw !important;
			
			ion-card-title{
				color: $color_2 !important;
				font-size:2rem !important;
				font-family: $main-font;
				/*color:$color_1;*/
			}
			p {
				color: $color_2;
                text-align: center;
			}
			.ico{
				display: table-cell;
				width: 70px !important;
				height: 70px !important;
				background: white url('#{$assetPath}/Common/deco.png') no-repeat center !important;
				background-size: contain;
				background-position: center;
			}
		}
		
	}

	h2{
		font-family: $main-font;
		color:$color_white;
	}

	ion-content{
	    --background: $color_1 !important;
	    background: $color_1 !important;
	    ion-card, ion-list{
	    	background: $color_1 !important;
	    }

	    ion-button{

	    }

	}

	ion-header ion-toolbar {
        border-bottom: 1px solid $color_white !important;
    }
}