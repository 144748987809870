////////////////////////// MOBILE /////////////////////////////////////// MOBILE ////////////////////////////////////////// MOBILE //////////////
.device-type-mobile {
    //PORTRAIT //////////////////////////////PORTRAIT/////////////////////////////////PORTRAIT//////////////////////////////PORTRAIT/////////////////////////////////////////////
    @media screen and (min-width: 768px) and (orientation: portrait) {
        ion-app {
            background-color: $color_1 !important;
            &.md {
                background-color: $color_1 !important;
            }
        }
        ion-page {
            background-color: $color_1 !important;
        }
        ion-modal {
            width: 100vw !important;
            height: 100vh !important;
            position: absolute !important;
        }
        ion-alert {
            ion-backdrop {
                background-color: transparent !important;
                border: 0px solid transparent !important;
            }
            .alert-wrapper {
                width: 75vw !important;
                max-width: 75vw !important;
            }
        }
        #leftMenu {
            .scroll-content {
                margin-top: 0;
                background: white !important;
            }
        }
        ion-title {
            .toolbar-title {
                .subtitle {
                    width: 100%;
                }
            }
        }
        .tabbar {
            margin-left: auto !important;
            margin-right: auto !important;
            background: transparent !important;
            position: relative;
            margin-top: calc(100vh - 56px) !important;
            a.tab-button {
                height: 50px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                @include box-shadow(2px, 2px, 3px, 1px, 25, 89, 153, 0.5);
                &:hover,
                &:focus {
                    opacity: 1 !important;
                }
            }
            .tab-button-icon {
                width: 28px !important;
                height: 30px !important;
                margin-top: 0;
                &:before {
                    font-size: 32px !important;
                }
                &.ion-md-restau:before {
                    font-size: 32px !important;
                }
                &.ion-md-commande:before {
                    font-size: 32px !important;
                }
            }
            .tab-button-text {
                font-size: 1.8rem !important;
                margin-top: -2px;
            }
            ion-badge.tab-badge {
                height: auto !important;
                font-size: 15px !important;
                line-height: 7px;
                width: 38px;
                &:before {
                    top: -2px;
                }
            }
            .tab-button[aria-selected="true"] {
                background: $color_white;
                background-size: cover;
                .tab-button-icon {
                    transform: scale(1.05);
                }
                ion-icon {
                    color: $color_1 !important;
                    &:before {
                        color: $color_1 !important;
                        font-size: 33px !important;
                        margin-top: 1px;
                    }
                }
                .tab-button-text {
                    font-size: 1.6rem !important;
                    color: $color_1 !important;
                }
                ion-badge.tab-badge {
                    height: auto !important;
                    font-size: 15px !important;
                    line-height: 7px;
                    width: 38px;
                    border: 1px solid $color_1 !important;
                    color: $color_1 !important;
                    &:before {
                        top: -2px;
                        color: $color_1 !important;
                    }
                }
            }
        }
        app-home {
            background-size: cover !important;
            ion-header {
                background: $color_1 !important;
            }
            .flag {
                width: 40px !important;
            }
            .menu {
                width: 40%;
                justify-content: flex-start !important;
                button {
                    width: 40% !important;
                    margin-bottom: 30px !important;
                }
            }
            .scroll-content {
                background: $color_1 !important;
                #logo {
                    width: 454px;
                    height: 178px;
                }
            }
            .container {
                width: 100%;
                margin-top: 50px;
                ion-row {
                    height: 220px !important;
                }
            }
        }
        app-info {
            ion-footer {
                button:not(.qte_min, .qte_max) {
                    width: 320px !important;
                }
                button.qte_max,
                button.qte_min {
                    width: 50px !important;
                }
            }
        }
        app-login {
            background-color: $color_1 !important;
            .scroll-content {
                margin-top: 50px !important;
                .form-login {
                    width: 50%;
                    @include margin-auto;
                    .text-input-md {
                        width: 100% !important;
                    }
                }
            }
        }
        app-profile {
            .scroll-content {
                button {
                    width: 320px !important;
                }
            }
        }
        app-password-lost {
            .form-login {
                width: 50%;
                @include margin-auto;
                .text-input-md {
                    width: 100% !important;
                }
            }
        }
        app-stores-list {
            ion-item {
                @include margin-auto;
            }
            ion-footer {
                button {
                    width: 320px !important;
                }
            }
        }
        app-store-detail {
            ion-footer {
                button {
                    width: 320px !important;
                }
            }
        }
        app-payzen-register {
            button {
                width: 15% !important;
            }
        }
        app-reservation {
            ion-content {
                .scroll-content {
                    margin-bottom: 200px !important !important;
                }
            }
        }
        app-order-lines {
            .order-line {
                ion-row.level0 {
                    grid-template-columns: 80% 20%;
                    .article_qte {
                        font-size: 2rem;
                    }
                    .article_price {
                        font-size: 2.2rem;
                    }
                }
                ion-row.level1 {
                    grid-template-columns: 80% 20%;
                    .article_price {
                        app-price {
                            font-size: 1.6rem;
                        }
                    }
                }
                ion-row.level2 {
                    grid-template-columns: 80% 20%;
                    .article_price {
                        app-price {
                            font-size: 1.6rem;
                        }
                    }
                }
                ion-row.level0_price {
                    grid-template-columns: 85% 15%;
                }
                .divider_compose {
                    div {
                        width: 15%;
                    }
                }
            }
            .total {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
            .tva_titre,
            .tva_detail {
                width: 42% !important;
                margin-left: 58%;
            }
        }
        app-quote,
        app-quote-review {
            ion-footer {
                button {
                    width: 30% !important;
                    background: $color_5 !important;
                }
            }
        }
        /*   app-directory {
            .ios-shortcuts {
                div {
                    justify-content: space-between !important;
                }
            }
            .grid-cell-container {
                padding-top: 20px;
                align-content: flex-start !important;
            }
        }
        app-directory,
        app-options,
        app-bundle,
        app-cross-sellings {
            .grid-cell-container {
                width: 100%;
                display: grid;
                grid-template-columns: 33% 33% 33%;
                grid-gap: 2px;
                margin-top: 15px;
                margin-bottom: 70px;
                margin-left: 10px;
                &.single-element {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 100%;
                    grid-article-cell,
                    grid-cell {
                        flex: 0 0 50% !important;
                        width: 50% !important;
                        max-width: 50% !important;
                    }
                }
                grid-article-cell,
                grid-cell {
                    flex: 0 0 90% !important;
                    width: 95% !important;
                    max-width: 95% !important;
                    padding: 10px 5px 10px 5px !important;
                    .container_max {
                        height: 150px !important;
                    }
                }
                .design_grid .container_max img {
                    max-height: 100%;
                    margin-left: -17px;
                    //      max-width: 100%;
                }
                .container_image {
                    padding-bottom: 16px;
                    img {
                        cursor: pointer;
                        max-height: 114% !important;
                    }
                    button.button_4.bt_cmd {
                        width: 96%;
                        bottom: 103px;
                        left: 2%;
                    }
                }
                p {
                    font-size: 1.5rem !important;
                    line-height: 2rem !important;
                    margin-top: 10px;
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
                .layer-price {
                    bottom: 41px;
                    left: 8px;
                    .euro {
                        // clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                        .unit {
                            //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                            price:after {
                                //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                            }
                        }
                        price:after {
                            //    clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                        }
                    }
                }
                .unit-price {
                    left: 8px !important;
                    background-size: contain !important;
                }
                .info,
                .cart {
                    display: flex;
                    align-items: center;
                    background: none;
                    background-size: cover !important;
                    cursor: pointer;
                    position: absolute;
                    opacity: 1 !important;
                    background: #dadada;
                    width: 30px;
                    height: 30px;
                    &:before {
                        font-family: Ionicons;
                        color: $color_white;
                        font-size: 30px;
                        position: relative;
                        border-radius: 50%;
                        line-height: 30px;
                        opacity: 1 !important;
                    }
                }
                .info {
                    width: 25px !important;
                    height: 25px !important;
                    left: 88% !important;
                    top: 126px !important;
                    opacity: 1 !important;
                    background: $color_7;
                    justify-content: center;
                    @media screen and (min-width: $large-screen) {
                        // IPAD PROD
                        left: 92% !important;
                    }
                    &:before {
                        font-family: Ionicons;
                        content: "\f312";
                        color: $color_white;
                        font-size: 32px;
                        position: absolute;
                    }
                }
                .cart {
                    width: 25px !important;
                    height: 25px !important;
                    left: 88% !important;
                    top: 139px !important;
                    opacity: 1 !important;
                    background: $color_7;
                    justify-content: center;
                    @media screen and (min-width: $large-screen) {
                        // IPAD PROD
                        left: 92% !important;
                    }
                    &:before {
                        font-size: 21px;
                        position: absolute;
                    }
                }
                .checkbox {
                    left: 56% !important;
                    top: 84% !important;
                    &:before {
                        font-size: 24px;
                    }
                }
                .breaking_div {
                    top: 7px;
                    right: -37px;
                    @media screen and (min-width: 1024px) and (max-width: 1025px) {
                        right: -80px;
                    }
                }
                .addCart {
                    top: 84% !important;
                }
            }
        } */
        app-directory,
        app-options,
        app-bundle,
        app-cross-sellings,
        app-upsellings {
            .img_bar {
                justify-content: space-between !important;
            }
            .scroll-content {
                margin-bottom: 145px !important;
                .grid-cell-container {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 33% 33% 33%;
                    grid-gap: 2px;
                    margin-top: 15px;
                    margin-bottom: 70px;
                    margin-left: 10px;
                    &.single-element {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 100%;
                        grid-article-cell,
                        app-grid-cell {
                            flex: 0 0 50% !important;
                            width: 50% !important;
                            max-width: 50% !important;
                        }
                    }
                    grid-article-cell,
                    app-grid-cell {
                        flex: 0 0 90% !important;
                        width: 95% !important;
                        max-width: 95% !important;
                        padding: 10px 5px 10px 5px !important;
                        .container_max {
                            height: 150px !important;
                        }
                    }
                    .design_grid .container_max img {
                        max-height: 100%;
                        margin-left: -17px;
                        //      max-width: 100%;
                    }
                    .container_image {
                        padding-bottom: 16px;
                        img {
                            cursor: pointer;
                            max-height: 114% !important;
                        }
                        button.button_4.bt_cmd {
                            width: 96%;
                            bottom: 103px;
                            left: 2%;
                        }
                    }
                    p {
                        font-size: 1.5rem !important;
                        line-height: 2rem !important;
                        margin-top: 10px;
                        width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .layer-price {
                        bottom: 41px;
                        left: 8px;
                        .euro {
                            // clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                            .unit {
                                //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                                app-price:after {
                                    //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                                }
                            }
                            app-price:after {
                                //    clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                            }
                        }
                    }
                    .unit-price {
                        left: 8px !important;
                        background-size: contain !important;
                    }
                    .info,
                    .cart {
                        display: flex;
                        align-items: center;
                        background: none;
                        background-size: cover !important;
                        cursor: pointer;
                        position: absolute;
                        opacity: 1 !important;
                        background: #dadada;
                        width: 30px;
                        height: 30px;
                        &:before {
                            font-family: Ionicons;
                            color: $color_white;
                            font-size: 30px;
                            position: relative;
                            border-radius: 50%;
                            line-height: 30px;
                            opacity: 1 !important;
                        }
                    }
                    .info {
                        width: 25px !important;
                        height: 25px !important;
                        left: 88% !important;
                        top: 126px !important;
                        opacity: 1 !important;
                        background: $color_7;
                        justify-content: center;
                        @media screen and (min-width: $large-screen) {
                            // IPAD PROD
                            left: 92% !important;
                        }
                        &:before {
                            font-family: Ionicons;
                            content: "\f312";
                            color: $color_white;
                            font-size: 32px;
                            position: absolute;
                        }
                    }
                    .cart {
                        width: 25px !important;
                        height: 25px !important;
                        left: 88% !important;
                        top: 139px !important;
                        opacity: 1 !important;
                        background: $color_7;
                        justify-content: center;
                        @media screen and (min-width: $large-screen) {
                            // IPAD PROD
                            left: 92% !important;
                        }
                        &:before {
                            font-size: 21px;
                            position: absolute;
                        }
                    }
                    .checkbox {
                        left: 56% !important;
                        top: 84% !important;
                        &:before {
                            font-size: 24px;
                        }
                    }
                    .breaking_div {
                        top: 7px;
                        right: -37px;
                        @media screen and (min-width: 1024px) and (max-width: 1025px) {
                            right: -80px;
                        }
                    }
                    .addCart {
                        top: 84% !important;
                    }
                }
            }
        }
    }
}