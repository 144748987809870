app-recapmenu {
    background-color: $color_1  !important;
    .back-button{
        display: none !important;
    }
    .content{
        background-color: $color_1  !important;
        .fixed-content {
            background-color: $color_1 !important;
        }
        .scroll-content {
            background-color: $color_1;
            margin-bottom: 90px;
        }
    }
    .item-container{
        background-color: $color_1 !important;
        .item-article{
            background-color: $color_1 !important;
        }
    }
    .title{
        line-height: 70px;
        font-family: $main-font;
    }
    .item-md ion-avatar ion-img, .item-md ion-avatar img {
        width: auto;
        height: 40px;
    }
    ion-list {
        margin-top:70px !important;
        display:block !important;
        ion-label{
            font-family: $second-font;
            font-size: 1.8rem;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
