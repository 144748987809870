app-code-type {
    .scroll-content{
        margin-bottom: 100px;
    }
    p, li, hr, div, input {
        /*margin: auto;*/
        font-family: $main-font;
        color: $color_2;
        font-size: 1.7rem;
        font-weight: 500;
        color : $color_white !important;
    }
    .digicode{
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;
    }
    .digicode ion-input input {
        background: $color_1 !important;
        border: 1px solid white;
        border-radius: 5px;
        height:45px;
        line-height: 40px;
        font-size: 2rem;
        color : $color_2 !important;
        width:200px !important;
        margin-left:auto !important;
        margin-right:auto !important;
        text-align: center;
        &::placeholder{
            color:white;
            text-align: center;
            font-size: 3rem;
        }
    }
    ion-footer{
        background: $color_1;
        button,.button_color_2 {
            width: 50%;
            @include margin-auto;
            background: $color_5 !important;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}