app-options {
    .grid-cell-container {
        background: transparent !important;
    }
    ion-title {
        color: white !important;
        font-family: $main-font !important;
        .toolbar-title {
            margin-top: 10px !important;
            margin-left: -22px;
            .subtitle {
                opacity: 0.7;
                font-size: 1.5rem;
            }
        }
    }
    ion-content{
        --background: $color_1 !important;
        background-color: $color_1 !important;
        background: $color_1 !important;
    }
    .content {
        &.article-page-layout-line {
            background-color: $color_1 !important;
            .scroll-content {
                background: $color_1 !important;
                @at-root {
                    // fixup d'un problème d'affichage du 'content' par dessus le box-shadow de la ion-header
                    .ios app-options .content .scroll-content {
                        margin-top: 65px !important;
                    }
                }
                .checkbox {
                    height: 25% !important;
                    top: 24% !important;
                    left: 10px !important;
                    z-index: 1;
                    float: left;
                    color: $color_1;
                    &:before {
                        color: $color_1;
                        font-size: 30px;
                    }
                }
                p {
                    color: $color_1;
                    width: 72%;
                    line-height: 1.6rem;
                    padding-left: 40px;
                }
                .container-cart {
                    display: none;
                }
                .grid-cell-container {
                    &.single-element {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 100%;
                        grid-article-cell,
                        app-grid-cell {
                            width: 100%;
                        }
                    }
                }
                .deposit_text {
                    font-family: $main-font !important;
                    color: $color_1;
                }
            }
        }

        &.article-page-layout-grid {
            background-color: $color_1 !important;
            .scroll-content {
                background: $color_1 !important;
                @at-root {
                    // fixup d'un problème d'affichage du 'content' par dessus le box-shadow de la ion-header
                    .ios app-options .content .scroll-content {
                        margin-top: 65px !important;
                    }
                }
                .checkbox {
                    height: 25% !important;
                    top: 38% !important;
                    left: 7px !important;
                    z-index: 1;
                    float: left;
                    color: $color_1;
                    &:before {
                        color: $color_1;
                        font-size: 30px;
                    }
                }
                p {
                    color: $color_1;
                    width: 100%;
                    line-height: 1.6rem;
                }
                .container-cart {
                    display: none;
                }
            }
        }
    }
    ion-footer {
        background: $color_1;
        height: 70px;
        line-height: 70px;
        button {
            font-size: 2.3rem;
        }
        .button_color_1 {
            min-width: 47%;
            margin-left: auto;
        }
        .button_color_2 {
            min-width: 47%;
        }
    }
}
