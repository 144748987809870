@charset "UTF-8";
/** Ionic CSS Variables **/
@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
html,
body {
  font-size: 12px;
  font-family: "Montserrat";
}
@media screen and (min-width: 768px) {
  html,
body {
    font-size: 12px;
  }
}

.mainfont-button:not(.qte_min, .qte_max), app-take-back .scroll-content button.button_color_2:not(.qte_min, .qte_max), app-take-back .scroll-content button.button_color_1:not(.qte_min, .qte_max), ion-footer.buttons-bar button:not(.qte_min, .qte_max), .button_1:not(.qte_min, .qte_max), app-payzen-embeded #myPaymentForm button:not(.qte_min, .qte_max), .button_color_1:not(.qte_min, .qte_max),
.button_2:not(.qte_min, .qte_max),
.button_color_2:not(.qte_min, .qte_max),
.button_3:not(.qte_min, .qte_max),
.button_color_3:not(.qte_min, .qte_max),
.button_4:not(.qte_min, .qte_max), ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):last-child, ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):first-child {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  border-style: solid;
  display: flex;
  align-self: center;
  justify-content: center;
  font-family: "Lato";
  height: 40px !important;
  width: 77%;
  margin-top: 15px;
  border-width: 0px;
  /*Comment by sajid*/
  /*background: $color_white url("../../../assets/images/Theme_Heyou/Common/filet_tricolore.png") no-repeat center bottom;*/
  -webkit-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.26) !important;
  -moz-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.26) !important;
  box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.26) !important;
  color: #47b4cf;
  border-radius: 2px;
  white-space: nowrap;
  /*padding-top: 15px;*/
  font-size: 17px;
  font-weight: 600;
}
.mainfont-button:not(.qte_min, .qte_max) span, app-take-back .scroll-content button.button_color_2:not(.qte_min, .qte_max) span, app-take-back .scroll-content button.button_color_1:not(.qte_min, .qte_max) span, ion-footer.buttons-bar button:not(.qte_min, .qte_max) span, .button_1:not(.qte_min, .qte_max) span, app-payzen-embeded #myPaymentForm button:not(.qte_min, .qte_max) span, .button_color_1:not(.qte_min, .qte_max) span,
.button_2:not(.qte_min, .qte_max) span,
.button_color_2:not(.qte_min, .qte_max) span,
.button_3:not(.qte_min, .qte_max) span,
.button_color_3:not(.qte_min, .qte_max) span,
.button_4:not(.qte_min, .qte_max) span, ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):last-child span, ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):first-child span {
  font-size: 2.1rem;
  text-transform: uppercase;
}

.mainfont-button-simple, .button_simple, .button_simple_color_1 {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: flex;
  align-self: center;
  justify-content: center;
  height: 35px;
  border-style: solid;
  border-width: 0px;
  font-family: "Lato";
  font-size: 2.2rem;
  width: 50px;
  border-radius: 2px;
  color: #fcea10;
  white-space: nowrap;
  background-color: transparent;
}
@media screen and (max-width: 320px) {
  .mainfont-button-simple, .button_simple, .button_simple_color_1 {
    font-size: 2rem;
  }
}
@media screen and (max-height: 500px) {
  .mainfont-button-simple, .button_simple, .button_simple_color_1 {
    font-size: 2.5rem;
  }
}
.mainfont-button-simple span, .button_simple span, .button_simple_color_1 span,
.mainfont-button-simple .button-inner,
.button_simple .button-inner,
.button_simple_color_1 .button-inner {
  text-transform: uppercase;
}

ion-app {
  background: #2cb6b9 !important;
}
ion-app ion-radio ion-label,
ion-app ion-checkbox ion-label {
  color: #2cb6b9;
}
ion-app .checkbox-icon {
  border-width: 0 !important;
}
ion-app .checkbox-ios .checkbox-checked {
  border-color: transparent !important;
  background-color: transparent !important;
}
ion-app .checkbox-md .checkbox-checked {
  border-color: transparent !important;
  background-color: transparent !important;
}
ion-app .checkbox-inner,
ion-app .radio-inner {
  display: none;
}
ion-app ion-item {
  background-color: transparent !important;
}
ion-app ion-item input[type=text],
ion-app ion-item input[type=number],
ion-app ion-item input[type=email],
ion-app ion-item input[type=password],
ion-app ion-item input[type=tel] {
  font-family: "Lato" !important;
  font-size: 1.6rem !important;
  color: white !important;
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid white !important;
  border-radius: 0 !important;
  padding-left: 3px;
  padding-right: 3px;
  height: 30px;
}
ion-app ion-item input[type=tel] {
  padding-left: 50px !important;
}
ion-app ion-item ion-label.floating {
  color: white !important;
  opacity: 0.6 !important;
  margin: 2px 0 2px 0;
}
ion-app ion-item .text-input-md {
  width: 100% !important;
}
ion-app ion-item.item-md, ion-app ion-item.item-ios, ion-app ion-item.item-wp {
  padding-left: 0;
}
ion-app ion-item .sc-ion-input-ios-h {
  /*--padding-top: 0px;*/
  /*--padding-bottom: 5px;*/
  height: 50px !important;
}
ion-app ion-item .sc-ion-input-ios {
  padding-bottom: 10px;
  height: 40px !important;
  z-index: 999;
  font-size: 60% !important;
  /*padding-top: 5px;*/
}
ion-app ion-datetime button {
  border-bottom: 1px solid white !important;
}
ion-app ion-datetime .datetime-text {
  margin-top: 13px;
}
ion-app .radio-ios .radio-icon {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 8px;
}
ion-app ion-checkbox .checkbox-icon,
ion-app ion-checkbox .radio-icon,
ion-app ion-radio .checkbox-icon,
ion-app ion-radio .radio-icon {
  /*&:before {
      font-family: Ionicons;
      content: "\f1f6";
      color: $color_white;
      font-size: 25px;
  }
  &.checkbox-checked,
  &.radio-checked {
      &:before {
          font-family: Ionicons;
          content: "\f149";
          color: $color_white;
          font-size: 25px;
      }
  }*/
}
ion-app ion-checkbox .checkbox-icon .alert-checkbox-icon,
ion-app ion-checkbox .radio-icon .alert-checkbox-icon,
ion-app ion-radio .checkbox-icon .alert-checkbox-icon,
ion-app ion-radio .radio-icon .alert-checkbox-icon {
  border-radius: 0 !important;
}
ion-app ion-checkbox.white,
ion-app ion-radio.white {
  filter: none;
}
ion-app div.checkbox:before {
  font-family: Ionicons;
  content: "";
  color: white;
  font-size: 25px;
}
ion-app div.checkbox.checked:before {
  font-family: Ionicons;
  content: "";
  color: white;
  font-size: 25px;
}
ion-app.platform-ios .ios-shortcuts button, ion-app.platform-wp .ios-shortcuts button {
  background-color: #2cb6b9;
  color: white;
}
ion-app.platform-ios .ios-shortcuts button.selected, ion-app.platform-wp .ios-shortcuts button.selected {
  background-color: white;
  color: #2cb6b9;
}
ion-app .grid-cell-container {
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: grid;
}
ion-app .grid-cell-container.nb_grid-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .grid-cell-container.nb_grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .grid-cell-container.nb_grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
ion-app .grid-cell-container.nb_grid-3 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .grid-cell-container.nb_grid-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .grid-cell-container.nb_grid-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
ion-app .grid-cell-container.nb_grid-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .grid-cell-container.nb_grid-4 {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .grid-cell-container.nb_grid-4 {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}
ion-app .grid-cell-container.nb_grid-6 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .grid-cell-container.nb_grid-6 {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .grid-cell-container.nb_grid-6 {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
  }
}
ion-app .nb_grid-2 .grid-cell-container {
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .nb_grid-2 .grid-cell-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .nb_grid-2 .grid-cell-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
ion-app .nb_grid-3 .grid-cell-container {
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .nb_grid-3 .grid-cell-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .nb_grid-3 .grid-cell-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
ion-app .nb_grid-4 .grid-cell-container {
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .nb_grid-4 .grid-cell-container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .nb_grid-4 .grid-cell-container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}
ion-app .nb_grid-6 .grid-cell-container {
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  ion-app .nb_grid-6 .grid-cell-container {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  ion-app .nb_grid-6 .grid-cell-container {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
  }
}
ion-app .picker-wrapper {
  background: #2cb6b9 !important;
}
ion-app .picker-wrapper button.picker-opt {
  color: white;
}
ion-app .picker-wrapper button.picker-opt-selected {
  color: white !important;
}
ion-app .picker-wrapper .picker-toolbar {
  background: #2cb6b9 !important;
  width: 100%;
}
ion-app .picker-wrapper .picker-toolbar .picker-button {
  color: white;
}
ion-app .picker-wrapper .picker-toolbar .picker-toolbar-button:not(.picker-toolbar-cancel) {
  text-align: right;
}

ion-header {
  background-color: #2cb6b9 !important;
}
ion-header ion-toolbar {
  height: 65px;
  background: #2cb6b9 !important;
  --background: $color_1 !important;
  border-bottom: 1px solid white;
}
ion-header ion-toolbar ion-buttons {
  color: white !important;
}
ion-header ion-toolbar ion-buttons ion-icon {
  color: white !important;
}
ion-header ion-toolbar .back-button,
ion-header ion-toolbar .back-button-md {
  min-width: 30px;
  box-shadow: none;
  color: white !important;
}
ion-header ion-toolbar ion-title {
  background: #2cb6b9 !important;
  color: white !important;
}
ion-header ion-toolbar ion-title .toolbar-title {
  color: #fcea10 !important;
  font-family: "Montserrat";
  font-weight: 700;
  width: 100%;
}
ion-header ion-toolbar ion-title .toolbar-title .subtitle {
  width: 100%;
  opacity: 0.8;
  font-size: 1.5rem;
  margin-top: -1px;
  font-family: "Lato";
}
ion-header ion-toolbar ion-title div.subtitle {
  color: white !important;
  text-transform: none;
}

.toolbar-title-md {
  font-size: 1.6rem !important;
  text-transform: uppercase;
}

app-directory ion-header ion-toolbar ion-title .toolbar-title,
app-quote ion-header ion-toolbar ion-title .toolbar-title,
app-language-list ion-header ion-toolbar ion-title .toolbar-title,
app-options ion-header ion-toolbar ion-title .toolbar-title {
  width: 100% !important;
}

app-language-list {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
}
app-language-list ion-header ion-toolbar {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
}
app-language-list ion-header ion-toolbar ion-title {
  text-align: center;
  width: 100% !important;
}
app-language-list ion-header ion-toolbar ion-icon {
  color: white !important;
}
app-language-list ion-content {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
}
app-language-list ion-footer ion-button {
  color: white !important;
}

app-quote ion-content, app-quote-review ion-content {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
}

.menu-toolbar .toolbar-background {
  background-color: #2cb6b9 !important;
}
.menu-toolbar .btn-login {
  color: white !important;
  background-color: transparent !important;
  border: 2px solid white;
  font-family: "Montserrat";
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.btn-login {
  color: white !important;
  width: 180px;
}

.tabs-md .tabbar {
  background: #2cb6b9 !important;
}

.card-background-page ion-card {
  position: relative;
  text-align: center;
}
.card-background-page .card-title {
  position: absolute;
  top: 36%;
  font-size: 2em;
  width: 100%;
  font-weight: bold;
  color: #fff;
}
.card-background-page .card-subtitle {
  font-size: 1em;
  position: absolute;
  top: 52%;
  width: 100%;
  color: #fff;
}

ion-badge {
  left: 50px;
}
ion-badge.top-badge:before {
  border: 1px solid white;
  top: -1px;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  ion-badge.top-badge:before {
    top: 2px;
  }
}
ion-badge.top-badge.badge-md {
  color: white !important;
}
ion-badge.tab-badge {
  background-color: transparent !important;
}
ion-badge.tab-badge:before {
  border: 1px solid white;
  top: -2px;
  border-radius: 20px;
}

.platform-android app-directory .grid-cell-container {
  margin-top: 0px;
}
.platform-android app-options .grid-cell-container,
.platform-android app-bundle .grid-cell-container {
  margin-top: 0px;
}

.ios .alert-ios .alert-button-group {
  flex-direction: column;
}
.ios .alert-ios .alert-button-group .btnvalider {
  margin-right: 10px;
  min-width: 20%;
}
.ios .alert-ios .alert-button-group .btncancel {
  width: 20% !important;
  min-width: 20%;
}

.toolbar-background-md {
  background-color: #2cb6b9 !important;
}

tabs-md .tabbar {
  background: #2cb6b9 !important;
}

ion-alert {
  background-color: transparent !important;
  color: white !important;
}
ion-alert .alert-wrapper {
  background-color: #2cb6b9 !important;
  border: 2px solid white;
  border-radius: 20px !important;
}
ion-alert .alert-wrapper .alert-title {
  font-family: "Montserrat";
  letter-spacing: -1px;
  font-size: 1.6rem !important;
  color: #fcea10;
}
ion-alert .alert-wrapper .alert-sub-title {
  font-family: "Montserrat";
}
ion-alert .alert-wrapper .alert-message {
  color: white;
  font-family: "Montserrat";
  font-size: 1.4rem !important;
}
ion-alert .alert-wrapper .alert-button-group .alert-button {
  width: 48% !important;
  border: 1px solid white;
}
ion-alert .alert-wrapper .alert-button-group .alert-button:first-child {
  letter-spacing: -0.5px !important;
  border-radius: 0;
  margin: 0 8px 0 0 !important;
}
ion-alert .alert-wrapper .alert-button-group .alert-button:last-child {
  letter-spacing: -0.5px !important;
  margin: 0 !important;
}
ion-alert .alert-wrapper .alert-button-group .alert-button .button-inner {
  font-size: 1.4rem;
}
ion-alert .alert-wrapper .alert-button-group .alert-button .alert-button-inner {
  font-size: 1.4rem !important;
  text-align: center !important;
}
ion-alert .alert-wrapper .alert-button-group [dir=ltr] .alert-md .alert-button {
  margin: 0 !important;
}
ion-alert.alert-marginO .alert-wrapper .alert-title {
  margin-top: O !important;
}
ion-alert.alert-margin7 .alert-wrapper .alert-title {
  margin-top: 7 !important;
}
ion-alert.alert-margin11 .alert-wrapper .alert-title {
  margin-top: 11 !important;
}
ion-alert.alert_majeur .alert-wrapper .alert-button-group {
  flex-direction: row !important;
}
ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button .button-inner {
  font-size: 18px !important;
}
ion-alert.alert-deconnect .alert-wrapper .alert-button-group .alert-button .button-inner {
  font-size: 1.6rem;
}

.platform-ios .tabbar {
  background: #2cb6b9 !important;
}

.back-button {
  color: white;
  margin-top: 8px;
}

ion-footer.buttons-bar {
  padding-top: 16px;
  padding-bottom: 16px;
}

.list-ios .item-block .item-inner {
  border-bottom: 0.5px solid white !important;
}

.button-md, ion-alert .alert-wrapper .alert-button-group .alert-button:first-child, ion-alert .alert-wrapper .alert-button-group .alert-button:last-child {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

#blocksSection {
  background: red !important;
}

app-type-barcode .digicode div {
  display: inline;
}

.alert_deconnexion .button-inner {
  font-size: 12pt;
}

.header-md::after,
.tabs-md[tabsPlacement=top] > .tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement=bottom] > .tabbar::before {
  background-image: none !important;
}

.header-md::after,
.tabs-md[tabsPlacement=top] > .tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement=bottom] > .tabbar::before {
  background-image: none !important;
}

.sc-ion-loading-md-h, .sc-ion-loading-md-s .spinner-crescent circle {
  stroke: #ffffff !important;
  color: #ffffff !important;
}

.sc-ion-loading-md-h, .sc-ion-loading-md-s .loading-wrapper {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  background: transparent !important;
  transform: scale(1.2) !important;
}

ion-spinner {
  position: unset !important;
  display: inline-block;
  height: 28px;
}

.loading-ios .loading-wrapper {
  color: #000;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  background: transparent !important;
}

.loading-ios .loading-content {
  display: none;
}

::-webkit-scrollbar {
  background-color: #2cb6b9;
}

.picker-wrapper {
  background: #e6e6e6 !important;
}
.picker-wrapper button.picker-opt {
  color: #2cb6b9;
}
.picker-wrapper button.picker-opt-selected {
  color: #2cb6b9 !important;
}
.picker-wrapper .picker-toolbar {
  background: #e6e6e6 !important;
  width: 100%;
}
.picker-wrapper .picker-toolbar .picker-button {
  color: #2cb6b9;
}
.picker-wrapper .picker-toolbar .picker-toolbar-button:not(.picker-toolbar-cancel) {
  text-align: right;
}

img.error {
  display: none;
}

app-price:after {
  margin-left: -3px !important;
  font-size: 1.5rem !important;
}

app-gcs .back-button,
app-legals .back-button {
  display: inline;
}

grid-article-cell .unit-price .club_price,
app-grid-cell .unit-price .club_price {
  display: none;
}

app-info .scroll-content .price_article .line_through,
app-info ion-footer .price_article .line_through {
  display: none;
}
app-info .scroll-content .price_article .club_price,
app-info ion-footer .price_article .club_price {
  display: none;
}

app-order-lines app-price.discard .line_through {
  display: none;
}
app-order-lines .total price.discard .line_through {
  display: none;
}

app-price .stroke .hidden {
  opacity: 0;
}

.device-type-mobile app-price.stroke.hidden {
  opacity: 0;
  display: none;
}
.device-type-mobile app-price .deviceVirgule {
  display: none;
}
.device-type-mobile app-price .devicePoint {
  display: inline;
}

.device-type-terminal app-price.stroke.hidden {
  opacity: 0;
  display: none;
}
.device-type-terminal app-price .deviceVirgule {
  display: none;
}
.device-type-terminal app-price .devicePoint {
  display: inline;
}

.club_price {
  display: none;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
.loading-ios .loading-wrapper {
  border-radius: 17px !important;
  max-width: 270px !important;
  max-height: 90% !important;
  color: white !important;
  background: #2cb6b9 !important;
  padding: 18px 23px !important;
}

.loading-ios .loading-spinner + .loading-content {
  margin-left: 15px !important;
  font-family: "Montserrat";
  font-weight: 100;
  font-size: 1.6rem;
}

.container {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.input-inner,
.input-wrapper {
  align-items: center !important;
}

.horizontal-margin {
  margin-left: 16px;
  margin-right: 16px;
}

.divider {
  background-color: #9af5f6;
  height: 1px;
  width: 100%;
}
.divider.large {
  height: 2px;
  background-color: white;
}

.colorwhite {
  color: white !important;
}

.backgroundblack {
  background-color: #2cb6b9 !important;
}

.button_1, app-payzen-embeded #myPaymentForm button, app-take-back .scroll-content button.button_color_2, app-take-back .scroll-content button.button_color_1, .button_color_1, ion-alert .alert-wrapper .alert-button-group .alert-button:first-child, ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:first-child,
.button_2,
.button_color_2,
ion-alert .alert-wrapper .alert-button-group .alert-button:last-child,
ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:last-child,
.button_3,
.button_color_3,
.button_4 {
  border-color: white;
  transition: all 0.2s ease;
  letter-spacing: 1px;
  border-radius: 0 !important;
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: auto !important;
}

.button_simple, .button_simple_color_1 {
  transition: all 0.2s ease;
  letter-spacing: 1px;
  border-radius: 0 !important;
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: auto !important;
}

.button_1, app-payzen-embeded #myPaymentForm button, app-take-back .scroll-content button.button_color_2, app-take-back .scroll-content button.button_color_1, .button_color_1, ion-alert .alert-wrapper .alert-button-group .alert-button:first-child, ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:first-child {
  background-color: #fcea10 !important;
}
.button_1:active span, app-payzen-embeded #myPaymentForm button:active span, app-take-back .scroll-content button.button_color_2:active span, app-take-back .scroll-content button.button_color_1:active span, .button_color_1:active span, ion-alert .alert-wrapper .alert-button-group .alert-button:active:first-child span, .button_1:hover span, app-payzen-embeded #myPaymentForm button:hover span, app-take-back .scroll-content button.button_color_2:hover span, app-take-back .scroll-content button.button_color_1:hover span, .button_color_1:hover span, ion-alert .alert-wrapper .alert-button-group .alert-button:hover:first-child span {
  color: #47b4cf !important;
}
.button_1 span, app-payzen-embeded #myPaymentForm button span, app-take-back .scroll-content button.button_color_2 span, app-take-back .scroll-content button.button_color_1 span, .button_color_1 span, ion-alert .alert-wrapper .alert-button-group .alert-button:first-child span, ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:first-child span {
  color: #2cb6b9 !important;
}

.button_2, .button_color_2, ion-alert .alert-wrapper .alert-button-group .alert-button:last-child, ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:last-child {
  background-color: white !important;
  color: #2cb6b9 !important;
}
.button_2:active span, .button_color_2:active span, ion-alert .alert-wrapper .alert-button-group .alert-button:active:last-child span, .button_2:hover span, .button_color_2:hover span, ion-alert .alert-wrapper .alert-button-group .alert-button:hover:last-child span {
  color: #47b4cf !important;
}
.button_2 span, .button_color_2 span, ion-alert .alert-wrapper .alert-button-group .alert-button:last-child span, ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button:last-child span {
  color: #2cb6b9 !important;
}

.button_3, .button_color_3 {
  background-color: white !important;
  color: #2cb6b9 !important;
}
.button_3:active span, .button_color_3:active span, .button_3:hover span, .button_color_3:hover span {
  color: #47b4cf !important;
}
.button_3 span, .button_color_3 span {
  color: #2cb6b9 !important;
}

.button_4 {
  background-color: transparent !important;
  color: white !important;
}
.button_4:active span, .button_4:hover span {
  color: white !important;
}
.button_4 span {
  color: white !important;
}

.wp h2 {
  font-size: 26px !important;
}
.wp .col-qty {
  margin-top: 0 !important;
}
.wp .alert-wrapper {
  border: 1px solid white;
}

.text-input-wp {
  border: 0px solid white;
}

.ios-shortcuts {
  background-color: #2cb6b9 !important;
}

.toolbar-title-ios {
  font-size: 2rem !important;
}

.back-button {
  color: white;
  font-size: 20px;
}

.select-alert .alert-message {
  display: none;
}
.select-alert .alert-radio-group {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  margin-bottom: 30px;
}
.select-alert .alert-radio-group .alert-radio-icon {
  border-color: white;
}
.select-alert .alert-radio-group .alert-md [aria-checked=true] .alert-radio-label {
  border-color: white !important;
}
.select-alert .alert-radio-group .alert-radio-inner {
  background-color: white !important;
}
.select-alert .alert-radio-group .alert-md [aria-checked=true] .alert-radio-icon {
  border-color: white !important;
}
.select-alert .alert-radio-group .alert-radio-label {
  color: white !important;
  font-size: 1.5rem;
  white-space: normal !important;
}
.select-alert .alert-radio-icon {
  border-color: white !important;
}

.content-md,
.card-md {
  background-color: #2cb6b9 !important;
}

ion-menu .content-md,
ion-menu .card-md {
  background-color: white !important;
}
ion-menu ion-header {
  left: 0;
  top: 0;
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
}
ion-menu ion-header ion-toolbar {
  text-align: center;
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
  height: unset !important;
}
ion-menu ion-content {
  background: white !important;
  --background: $color_white !important;
}
ion-menu ion-content ion-list ion-menu-toggle ion-item ion-icon {
  color: #2cb6b9 !important;
}

app-cards-list {
  background-color: #2cb6b9 !important;
}

.bt_cmd {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

ion-menu-button {
  color: white !important;
}

ion-header ion-toolbar ion-title {
  color: #fcea10 !important;
  font-family: "Montserrat";
  font-weight: 700;
}

.alert-margintop11 {
  margin-top: 11px;
}

.header-ios {
  margin-bottom: 20px;
  height: 60px;
}
.header-ios ion-menu-button {
  /*margin-top: 20px;*/
}
.header-ios .header-background {
  /*height: 80px;*/
}

ion-header ion-toolbar {
  min-height: 70px;
  border-bottom: unset;
}
ion-header ion-toolbar.ios {
  min-height: 80px !important;
  padding-top: 35px !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
.menu-content .seperator {
  background-color: #2cb6b9 !important;
}
.menu-content ion-list.menu-list {
  padding-left: 20px;
  overflow: auto;
  border: none !important;
}
.menu-content .fixed-content {
  background-color: transparent;
}
.menu-content .scroll-content {
  overflow-y: auto;
}
.menu-content .tabbar {
  background-color: #2cb6b9;
  bottom: 0 !important;
}
.menu-content .tabbar .tab-button-icon {
  width: 40px !important;
  color: #5c5c5c;
  height: 32px !important;
}
.menu-content .tabbar .tab-button-icon.ion-md-accueil:before {
  content: "";
  color: white;
  font-size: 30px;
}
.menu-content .tabbar .tab-button-icon.ion-md-restau:before {
  content: "";
  color: white;
  font-size: 30px;
}
.menu-content .tabbar .tab-button-icon.ion-md-commande:before {
  content: "";
  color: white;
  font-size: 30px;
}
.menu-content .tabbar .tab-button-icon.ion-md-panier:before {
  content: "";
  color: white;
  font-size: 30px;
}
.menu-content .tabbar .tab-button-icon.ion-md-profil:before {
  content: "";
  color: white;
  font-size: 30px;
}
.menu-content .tabbar .tab-button-text {
  font-size: 1.2rem !important;
  letter-spacing: -0.1px;
  color: white;
  transform: scaleY(1.05);
}
.menu-content .tabbar .tab-button {
  overflow: visible;
}
.menu-content .tabbar .tab-button[aria-selected=true] ion-icon:before {
  color: #fcea10 !important;
  font-size: 30px;
}
.menu-content .tabbar .tab-button[aria-selected=true] .tab-button-text {
  color: #fcea10;
  font-family: "Montserrat";
  transform: scaleY(1.05);
}
.menu-content .list-md {
  cursor: pointer;
}
.menu-content .list-md .icon-md.accueil:before {
  content: "";
  color: #2cb6b9;
  font-size: 30px;
}
.menu-content .list-md .icon-md.restau:before {
  content: "";
  color: #2cb6b9;
  font-size: 30px;
}
.menu-content .list-md .icon-md.commande:before {
  content: "";
  color: #2cb6b9;
  font-size: 30px;
}
.menu-content .list-md .icon-md.panier:before {
  content: "";
  color: #2cb6b9;
  font-size: 30px;
}
.menu-content .list-md .icon-md.profil:before {
  content: "";
  color: #2cb6b9;
  font-size: 30px;
}
.menu-content .list-md .badge-md {
  color: #47b4cf !important;
  top: 4px !important;
  width: 34px !important;
  height: 30px !important;
  border: 2px solid #47b4cf;
  border-radius: 20px;
  text-align: center;
  background: white;
  font-family: "Lato";
}
.menu-content .list-md .label-md {
  font-family: "Montserrat";
}

.menu-content .tabbar .tab-button[aria-selected=true] .badge-ios-danger, .menu-content .tabbar .tab-button[aria-selected=true] .badge-wp-danger {
  color: #47b4cf !important;
}

.app-root > ion-tab {
  background-color: #2cb6b9;
}
.app-root > ion-tab img.placeholder {
  background-image: url("../../assets/images/default_priceless_white.png");
}
.app-root > ion-tab img.placeholder.price {
  background-image: url("../../assets/images/default_white.png");
}
.app-root > ion-tab .toolbarscroll {
  padding: 0 !important;
  width: 103%;
}
.app-root > ion-tab .toolbarscroll .toolbar-background {
  background-color: #2cb6b9 !important;
  overflow-y: hidden;
  overflow-x: hidden;
}
.app-root > ion-tab app-options .scroll-content,
.app-root > ion-tab app-bundle .scroll-content,
.app-root > ion-tab app-directory .scroll-content {
  padding: 5px !important;
}
.app-root > ion-tab app-directory .scroll-content {
  margin-top: 150px !important;
}
.app-root > ion-tab app-directory .toolbarscroll {
  background: white;
  margin-top: 0px !important;
}
.app-root > ion-tab app-options .scroll-content {
  margin-top: 170px !important;
}

/* Reset */
input {
  margin: 0 !important;
}

/* Fin: Reset */
a {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.link {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

/* .fixed-content,
.scroll-content {
    padding: 0 !important;
} */
ion-modal .modal-wrapper {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

ion-footer.buttons-bar {
  padding: 10vw;
  padding-top: 2vh;
  padding-bottom: 3vh;
  display: flex;
  justify-content: space-around;
}
img.placeholder {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-size: 0;
}

.white-button, .black-button {
  border-color: white;
  transition: all 0.2s ease;
}

.white-button {
  background-color: white !important;
  color: black !important;
}
.white-button:active {
  background-color: black !important;
  color: white !important;
}

.black-button {
  background-color: black !important;
  color: white !important;
}
.black-button:active {
  background-color: white !important;
  color: black !important;
}

.content {
  background-color: transparent;
}
.content.scroll-content-padding-default .scroll-content {
  padding: 40px !important;
}
@media screen and (max-width: 400px) {
  .content.scroll-content-padding-default .scroll-content {
    padding: 30px !important;
  }
}

.input-inner, .input-wrapper {
  align-items: center !important;
}

app-login .white-button, app-login .black-button,
app-user-form .white-button,
app-user-form .black-button,
app-password-change .white-button,
app-password-change .black-button,
app-password-lost .white-button,
app-password-lost .black-button,
app-cgv .white-button,
app-cgv .black-button,
app-mention .white-button,
app-mention .black-button,
app-order-detail .white-button,
app-order-detail .black-button,
app-orders-list .white-button,
app-orders-list .black-button,
app-profil .white-button,
app-profil .black-button,
app-store-detail .white-button,
app-store-detail .black-button,
app-stores-list .white-button,
app-stores-list .black-button {
  transition: all 0.2s ease;
}

.scroll-content {
  margin-bottom: 44px;
}

.content-centered {
  text-align: center !important;
}

.content-right {
  text-align: right !important;
}

.content-left {
  text-align: left !important;
}

.zero-padding {
  padding: 0 !important;
}

.zero-margin {
  margin: 0 !important;
}

.fullwidth {
  width: 100% !important;
}

.halfwidth {
  width: 50% !important;
}

.colorwhite {
  color: white !important;
}

.backgroundblack {
  background-color: black !important;
}

.back-button {
  font-size: 20px;
}

.capitalize {
  text-transform: capitalize;
}

.platform-ios .alert-ios .alert-button-group {
  flex-direction: column;
}
.platform-ios .alert-ios .alert-button-group .btnvalider {
  margin-right: 10px;
  min-width: 20%;
}
.platform-ios .alert-ios .alert-button-group .btncancel {
  width: 20% !important;
  min-width: 20%;
}

.wp h2 {
  font-size: 26px !important;
}
.wp .col-qty {
  margin-top: 0 !important;
}
.wp .alert-wrapper {
  border: 1px solid white;
}

.welcome-text, .version-text {
  text-align: center;
}

.pointer_none {
  pointer-events: none;
}

ion-tabs .tabbar {
  box-shadow: 0 0 0 transparent !important;
}

ion-app {
  background: transparent !important;
  background-color: transparent !important;
}
ion-app.md {
  background: transparent !important;
  background-color: transparent !important;
}
ion-app.platform-ios [menutoggle], ion-app.wp [menutoggle] {
  display: none;
}
ion-app.platform-android:not(.device-type-terminal) ion-tabs .tabbar {
  height: 0;
  display: none;
}
ion-app.platform-android:not(.device-type-terminal) ion-tabs ion-tab ion-content > div {
  margin-bottom: 0;
}

ion-badge {
  background-size: contain;
  padding: 8px !important;
  transition: all 2s ease;
  z-index: 1000;
}
@keyframes highlight {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(360deg) scale(1.2);
  }
  50% {
    transform: rotate(720deg) scale(1);
  }
  75% {
    transform: rotate(1080deg) scale(1.2);
  }
  100% {
    transform: rotate(1440deg) scale(1);
  }
}
ion-badge:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
ion-badge.highlight:before {
  animation: highlight 2s linear;
}
ion-badge.badge-md {
  position: absolute;
  left: 15px;
  top: 2px;
}
ion-badge.badge-ios-danger, ion-badge.badge-wp-danger {
  width: 26px;
  height: 27px;
  margin-top: 4px;
  padding: 0 15px !important;
  right: calc(47% - 30px) !important;
}
ion-badge.badge-ios-danger.active:before, ion-badge.badge-wp-danger.active:before {
  transform: rotate(360deg);
  filter: brightness(1.5);
}
ion-badge.badge-ios-danger::before, ion-badge.badge-wp-danger::before {
  position: absolute;
  top: -6px;
  content: " ";
  width: 92%;
  height: 100%;
  float: left;
  left: 5px;
  z-index: 0;
}
ion-badge.top-badge {
  margin-top: -7px;
  width: 30px;
}
ion-badge.top-badge:before {
  border-radius: 20px;
}
ion-badge.top-badge.badge-md {
  background: transparent !important;
  position: relative !important;
  left: -5px;
  top: 2px;
}
ion-badge .has-icon .tab-badge {
  right: calc(40% - 30px);
}
ion-badge.tab-badge {
  padding: 1px 4px;
  position: absolute;
  height: 22px;
  font-size: 1.8rem;
  line-height: 7px;
  text-align: center;
  width: 38px;
  left: 50%;
  top: -1px;
  background: transparent !important;
  border-radius: 30px;
}

.toolbar-ios ion-title, .toolbar-ios ion-title {
  padding: 0 !important;
}

.menu .image-menu-home.error {
  display: none;
}

#leftMenu .item-block {
  min-height: 3em;
}

ion-alert {
  height: 100%;
  text-align: center;
  width: 100% !important;
  min-height: 40%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  ion-alert {
    height: 50%;
    text-align: center;
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
    top: 5% !important;
    border: 2px solid white;
  }
}
ion-alert .alert-wrapper {
  border-radius: 0;
  width: 95vw !important;
  max-width: 95vw !important;
  min-height: 90vw;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  ion-alert .alert-wrapper {
    height: 100%;
    width: 100%;
    max-height: 100% !important;
    padding: 30px;
  }
}
@media screen and (min-width: 1024px) {
  ion-alert .alert-wrapper {
    min-height: 50vw;
    width: 40vw;
    max-width: 40vw;
    transform: scale(0.8);
  }
}
ion-alert .alert-wrapper .alert-title {
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (max-height: 767px) {
  ion-alert .alert-wrapper .alert-title {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 768px) {
  ion-alert .alert-wrapper .alert-title {
    margin-top: 50px;
    line-height: 3rem;
    font-size: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  ion-alert .alert-wrapper .alert-title {
    margin-top: 40px;
    font-size: 2.5rem;
  }
}
ion-alert .alert-wrapper .alert-sub-title {
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px !important;
}
ion-alert .alert-wrapper .alert-message {
  padding-left: 5vw !important;
  padding-right: 5vw !important;
  overflow-y: hidden;
  font-size: 2rem;
  margin: auto;
}
@media screen and (max-height: 600px) {
  ion-alert .alert-wrapper .alert-message {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 768px) {
  ion-alert .alert-wrapper .alert-message {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1024px) {
  ion-alert .alert-wrapper .alert-message {
    font-size: 2rem;
  }
}
ion-alert .alert-wrapper .alert-button-group {
  width: 100%;
  margin-bottom: 20px;
  justify-content: center !important;
  flex-direction: row !important;
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  ion-alert .alert-wrapper .alert-button-group {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1024px) {
  ion-alert .alert-wrapper .alert-button-group {
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
ion-alert .alert-wrapper .alert-button-group .alert-button {
  height: 45px;
  width: 40vw;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  ion-alert .alert-wrapper .alert-button-group .alert-button {
    width: 45%;
  }
}
ion-alert .alert-wrapper .alert-button-group .alert-button .button-inner {
  justify-content: center;
  text-align: center;
  margin-top: 0px;
}
ion-alert .alert-wrapper .alert-button-group .alert-button:first-child {
  border-radius: 0;
  margin: 0 8px 0 0 !important;
}
ion-alert .alert-wrapper .alert-button-group .alert-button:last-child {
  margin: 0 !important;
}
ion-alert .alert-wrapper .alert-button-group [dir=ltr] .alert-md .alert-button {
  margin: 0 !important;
}
ion-alert.alert-marginO .alert-wrapper .alert-title {
  margin-top: O !important;
}
ion-alert.alert-margin7 .alert-wrapper .alert-title {
  margin-top: 7 !important;
}
ion-alert.alert-margin11 .alert-wrapper .alert-title {
  margin-top: 11 !important;
}
ion-alert.alert-paiement .alert-title {
  margin-bottom: 50px;
  margin-top: 70px;
  color: #71dc71;
}
ion-alert.alert-paiement .alert-title:before {
  font-family: "FontAwesome";
  position: absolute;
  top: 30px;
  left: 45%;
  content: "";
  color: #71dc71;
  font-size: 4rem;
}
ion-alert.alert-paiement .alert-message {
  margin-bottom: 20px;
}
ion-alert.alert_majeur .alert-message {
  order: 3;
  padding: 10px !important;
}
@media screen and (min-width: 1024px) {
  ion-alert.alert_majeur .alert-message {
    font-size: 2rem !important;
  }
}
ion-alert.alert_majeur .alert-wrapper .alert-button-group {
  order: 2;
  width: 100%;
  margin: auto 0;
  justify-content: center !important;
  flex-direction: row !important;
  font-size: 1.3rem;
}
ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button {
  line-height: 45px;
  height: 45px;
  width: 80vw;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
  margin: 10px auto !important;
}
ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button .button-inner {
  justify-content: center;
  font-size: 30px;
  text-align: center;
  margin-top: 0px;
}
@media screen and (max-width: 374px) {
  ion-alert.alert_majeur .alert-wrapper .alert-button-group .alert-button .button-inner {
    font-size: 22px;
  }
}

.alert-ios .alert-button {
  min-width: 40vw !important;
}

.alert-ios .alert-button {
  flex: none !important;
}

[dir=ltr] .alert-md .alert-button-group {
  padding: 0 !important;
}

.item-md.item-input.input-has-focus .item-inner {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.list-md .item-input.input-has-focus:last-child {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.list-md .item-input.input-has-focus:last-child .item-inner {
  box-shadow: none;
}

.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child .item-inner {
  box-shadow: none;
}

.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.list-md .item-input.ng-invalid.ng-touched:not(.input-has-focus):last-child {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.item-md.item-block .item-inner {
  border-bottom: 0px solid transparent !important;
}

.version-text.app-mode {
  z-index: 9999;
  transition: color 0.5s;
}
.version-text.app-mode::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.5s;
}
.version-text.app-mode.localhost:hover, .version-text.app-mode.dev:hover, .version-text.app-mode.test:hover, .version-text.app-mode.staging:hover {
  color: transparent !important;
  transition: color 0.5s ease 5s;
}
.version-text.app-mode.localhost:hover::after, .version-text.app-mode.dev:hover::after, .version-text.app-mode.test:hover::after, .version-text.app-mode.staging:hover::after {
  opacity: 0.3;
  transition: opacity 0.5s ease 5s;
}
.version-text.app-mode.localhost::after {
  content: "localhost";
  background-color: white;
  color: black;
}
.version-text.app-mode.dev::after {
  content: "dev";
  background-color: blue;
}
.version-text.app-mode.test::after {
  content: "test";
  background-color: green;
}
.version-text.app-mode.staging::after {
  content: "staging";
  background-color: orange;
}

price .symbolCSSClass {
  padding: 0 2px;
}
price::after {
  content: "€";
}
price .symbolDeviseCss {
  display: none;
}

::-webkit-scrollbar {
  width: 6px !important;
}
@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 12px !important;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* the new scrollbar will have a flat appearance with the set background color */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/* this will style the thumb, ignoring the track */
::-webkit-scrollbar-button {
  background-color: transparent;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
::-webkit-scrollbar-corner {
  background-color: black;
}

.picker-toolbar-button {
  width: 100%;
}

.msg_popup_qte {
  padding: 15px 0;
  text-align: left;
  opacity: 0.8;
  line-height: 2rem;
  max-height: 200px;
  overflow-y: auto;
}

.italic_popup_qte {
  opacity: 0.8;
  padding: 5px 0 15px 0;
  display: inline-flex;
}

app-debug-settings ion-header ion-toolbar {
  height: 45px !important;
  min-height: 45px !important;
}
app-debug-settings ion-header ion-title .toolbar-title {
  color: #fcea10 !important;
  font-family: "Montserrat";
  font-weight: 700;
  width: 100%;
}
app-debug-settings .scroll-content {
  margin-bottom: 0;
}
app-debug-settings .scroll-content ion-label {
  white-space: pre-wrap;
  font-size: 1.5rem;
  padding-left: 15px;
}
app-debug-settings .scroll-content ion-select .select-text {
  font-size: 1.5rem;
  color: #fff475 !important;
}
app-debug-settings .checkbox-md .checkbox-icon {
  background-color: transparent !important;
}
app-debug-settings .item.item-md .checkbox-md {
  margin: 9px 10px 9px 4px;
  padding-left: 15px;
}
app-debug-settings ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-debug-settings ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-login .form-login {
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  font-family: "Lato" !important;
}
app-login .form-login .pass .bt_pass span {
  color: white !important;
  /*i {
      &.eye_on{
          &:after{
              color: $color_white !important;
              font-family:$fontawesome;
              content:'\f06e';
              color:grey;
              font-size: 1.2rem;
          }
      }
      &.eye_off{
          &:after{
              color: $color_white !important;
              font-family:$fontawesome;
              content:'\f070';
              color:grey;
              font-size: 1.2rem;
          }
      }
  }*/
}
app-login .form-login .pass .bt_pass span ion-icon {
  color: white !important;
  font-size: 1.2rem;
}
app-login .form-login ion-item ion-label {
  color: white !important;
}
app-login .form-login ion-item ion-input {
  color: white !important;
}
app-login ion-header {
  background-color: transparent !important;
}
app-login ion-header ion-toolbar {
  background-color: #2cb6b9 !important;
}
app-login .toolbar-background-md {
  border-color: transparent !important;
  background-color: transparent !important;
}
app-login .logo {
  width: 267px;
  height: 140px;
  content: " ";
  background: transparent url("../../../assets/images/Theme_Heyou/Accueil/logo_home.png") no-repeat center center !important;
  background-size: contain !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
app-login .forget-pass-text {
  font-family: "Lato" !important;
  color: white;
  opacity: 1;
}
app-login .button {
  border: 2px solid #2cb6b9 !important;
  height: 42px;
  width: 100%;
  font-family: "Montserrat";
}
app-login .button .button-inner {
  font-size: 2.4rem !important;
}
app-login .button.button_color_3 {
  margin-top: 20px;
}
app-login .text-mention-legal {
  color: white;
  font-size: 1.1rem;
  font-family: "Montserrat";
  opacity: 0.8;
}
app-login .text-cgv {
  color: white;
  font-size: 1.1rem;
  font-family: "Montserrat";
  opacity: 0.8;
}
app-login ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-login ion-content ion-list {
  background-color: #2cb6b9 !important;
}
app-login ion-content ion-list ion-item {
  --background: $color_1 !important;
}
app-login ion-content ion-icon {
  color: white !important;
  font-size: 1.2rem;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-home {
  background: #2cb6b9 url("../../../assets/images/Theme_Heyou/Accueil/background_home.png") no-repeat center -10px;
  background-size: cover;
}
app-home #lamaison_slide {
  display: none;
}
app-home #capao_slide {
  display: none;
}
app-home #leaderprice_slide {
  display: none;
}
app-home #beesket_slide {
  display: none;
}
app-home #waowei_logo {
  display: none;
}
app-home #enjoytacos_logo {
  display: none;
}
app-home ion-toolbar {
  padding: 0 !important;
}
app-home .scroll-content {
  margin-top: 10px !important;
  overflow-y: hidden !important;
}
app-home #logo {
  width: 267px;
  height: 140px;
  content: " ";
  background: transparent url("../../../assets/images/Theme_Heyou/Accueil/logo_home.png") no-repeat center center !important;
  background-size: contain !important;
  margin-top: -20px;
  margin-left: auto !important;
  margin-right: auto !important;
}
app-home ion-header {
  background-color: transparent !important;
}
app-home ion-header ion-toolbar {
  min-height: 70px;
  background-color: transparent !important;
  border-bottom: unset !important;
}
app-home ion-header ion-toolbar.ios {
  min-height: 80px !important;
  padding-top: 35px !important;
}
app-home .content-md {
  background-color: transparent !important;
}
app-home .toolbar-background-md {
  border-color: transparent !important;
  background-color: transparent !important;
}
app-home .toolbar-content {
  position: absolute;
  top: 0;
}
app-home .container {
  overflow-y: hidden;
}
app-home .container ion-row {
  height: 100px !important;
  margin-bottom: 15px;
}
app-home .container ion-row ion-col {
  background: transparent url("../../../assets/images/Theme_Heyou/Accueil/logo_home.png") no-repeat center top;
  background-size: cover;
  height: 89px;
  width: 238px;
}
app-home .container div.menu {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 185px);
  max-height: calc(100vh - 185px);
  justify-content: center;
}
app-home .container div.menu button {
  padding-top: 11px;
  font-weight: 600;
  width: 70%;
  height: 40px !important;
  min-height: 40px;
  margin-bottom: 0px;
  border-width: 1px !important;
  line-height: 9px !important;
  background: white !important;
  color: #2cb6b9 !important;
  transform: scaleY(1.05) !important;
  box-shadow: 3px 4px 10px 2px rgba(6, 59, 60, 0.3) !important;
  font-size: 18px;
}
app-home .container div.menu button:last-of-type {
  margin-bottom: 33px;
}
app-home .container div.menu button .button-inner {
  font-size: 2.2rem;
}
.ios app-home .container {
  margin-top: 1vh;
}

@media screen and (min-width: 1024px) {
  app-home .menu button {
    width: 30% !important;
  }
  app-home .container {
    width: 100%;
  }
  app-home .scroll-content {
    margin-top: 0px !important;
  }
}
app-home .header-ios .header-background {
  height: 80px !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-stores-list {
  background-color: #2cb6b9 !important;
}
app-stores-list .scroll-content {
  background-color: #2cb6b9 !important;
  margin-bottom: 150px;
}
app-stores-list .toolbar-title {
  color: white !important;
}
app-stores-list .toolbar-title ion-title {
  text-transform: uppercase;
}
app-stores-list .radio-md .radio-icon {
  border-color: transparent !important;
  margin-right: 15px;
}
app-stores-list .store-list .item-input ion-input,
app-stores-list .store-list .item-input ion-textarea {
  font-family: "Lato" !important;
  color: white !important;
  font-size: 1.8rem;
  border-bottom: 1px solid white;
  width: 300px;
  margin-top: 15px;
}
app-stores-list .store-list .item-input ion-input input,
app-stores-list .store-list .item-input ion-textarea input {
  color: white !important;
}
app-stores-list .store-list .item-input ion-input input::placeholder,
app-stores-list .store-list .item-input ion-textarea input::placeholder {
  color: white !important;
}
app-stores-list .store-list .container-store {
  background-color: white;
}
app-stores-list .store-list .container-store.selected {
  border: 2px solid #fcea10 !important;
}
app-stores-list .store-list .container-store .store-label div.store-info .store-name {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.9rem;
  font-family: "Montserrat";
}
app-stores-list .store-list .container-store .store-label div.store-info .store-address {
  font-family: "Montserrat";
  font-size: 1.2rem;
  line-height: 1.6rem;
}
app-stores-list .store-list .container-store .store-label div.store-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-radio ion-icon {
  font-size: 2.8rem;
  color: #2cb6b9;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-radio ion-radio .radio-icon.checkbox-checked:before,
app-stores-list .store-list .container-store .store-label div.store-link .store-radio ion-radio .radio-icon.radio-checked:before {
  color: #2cb6b9;
  display: block;
  --color-checked: $color_1 !important;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-radio ion-radio {
  color: transparent !important;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-radio ion-radio .check-radio {
  color: #2cb6b9 !important;
  --color-checked: $color_1 !important;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-info {
  margin-top: 15px;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-info ion-icon {
  font-size: 2.6rem;
  color: #2cb6b9;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-link a {
  font-size: 2.3rem;
  color: #2cb6b9;
}
app-stores-list .store-list .container-store .store-label div.store-link .store-link a ion-icon {
  font-size: 2.5rem !important;
  color: #2cb6b9;
}
app-stores-list .stores:last-child {
  margin-bottom: 100px !important;
}
app-stores-list .ios-shortcuts {
  font-family: "Lato";
  border: 0px solid transparent !important;
  background-color: #2cb6b9;
  letter-spacing: 1px;
  justify-content: space-between !important;
  display: flex !important;
  line-height: 26px;
  border-bottom: 1px solid white !important;
  border-top: 1px solid white !important;
}
app-stores-list .ios-shortcuts div {
  overflow-x: auto;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}
app-stores-list .ios-shortcuts div button {
  width: 50%;
  white-space: nowrap;
  font-size: 2rem;
}
app-stores-list .ios-shortcuts div button.selected {
  color: #2cb6b9;
  font-size: 2rem;
}
app-stores-list .map .item-input ion-input,
app-stores-list .map .item-input ion-textarea {
  font-family: "Lato" !important;
  color: white !important;
  font-size: 1.8rem;
  border-bottom: 1px solid white;
  width: 300px;
  margin-top: 15px;
}
app-stores-list .map .item-input ion-input input,
app-stores-list .map .item-input ion-textarea input {
  color: white !important;
}
app-stores-list .map .item-input ion-input input::placeholder,
app-stores-list .map .item-input ion-textarea input::placeholder {
  color: white !important;
}
app-stores-list .map .list-md .item-input:last-child {
  border-bottom: 0px solid transparent !important;
}
app-stores-list .map .label-md {
  margin: 0px 8px 0px 0;
}
app-stores-list .map ion-label {
  justify-content: flex-end;
}
app-stores-list .map ion-label .button_geoloc {
  display: flex;
}
app-stores-list .map .button_valid {
  box-shadow: 3px 4px 10px 2px rgba(204, 239, 240, 0.3) !important;
  float: right;
  background: #1c8f92;
  color: white;
  border-radius: 10px;
  height: 32px;
  font-size: 1rem;
  line-height: 1.2rem;
}
app-stores-list .map .button_valid ion-icon {
  margin-top: -2px;
  margin-left: 10px;
}
app-stores-list .map .button_valid:hover {
  background: #1c8f92;
  opacity: 0.8;
}
app-stores-list .map .button_geoloc {
  float: left;
  border-radius: 10px;
  height: 32px;
  font-size: 1rem;
  line-height: 1.2rem;
  background: #fcea10;
  color: black;
}
app-stores-list .map .button_geoloc ion-icon {
  margin-top: -2px;
}
app-stores-list .map .button_geoloc:hover {
  background: #fcea10;
  opacity: 0.8;
}
app-stores-list .map .leaflet-container {
  width: auto !important;
}
app-stores-list .map .leaflet-container .leaflet-popup {
  min-width: 260px;
  bottom: -60px !important;
  left: -130px !important;
}
app-stores-list .map .leaflet-container .leaflet-popup-content {
  min-width: 240px;
}
app-stores-list .map .leaflet-container .leaflet-popup-content a {
  font-size: 1.5rem;
  line-height: 3rem;
}
app-stores-list .map .leaflet-container .leaflet-popup-content h2 {
  white-space: normal;
}
app-stores-list .map .leaflet-container .leaflet-popup-content ion-button {
  background: #fcea10;
  border-radius: 10px;
}
app-stores-list ion-footer {
  background: transparent;
}
app-stores-list ion-footer button.button_color_1 {
  line-height: 4px !important;
}
app-stores-list ion-content.ios ion-input {
  padding-top: 30px;
  font-size: 30px !important;
  z-index: 999;
  padding-bottom: 30px;
}
app-stores-list ion-content.ios .ionlist_button_geoloc ion-item ion-input {
  color: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 30px;
}
app-stores-list ion-content.ios .ionlist_button_geoloc ion-item ion-input input {
  font-family: "Lato" !important;
  font-size: 1.6rem !important;
  color: white !important;
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid white !important;
  border-radius: 0 !important;
  padding-left: 3px;
  padding-right: 3px;
  height: 40px !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-store-detail ion-content {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-store-detail ion-content ion-list {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-store-detail ion-content ion-list ion-item {
  background: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-store-detail ion-list {
  padding: 10px 10px !important;
}
app-store-detail .toolbar-title {
  color: white !important;
  font-size: 2rem;
}
app-store-detail h2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.8rem;
  white-space: normal !important;
  line-height: 26px;
  text-align: center !important;
  margin-bottom: 15px;
  color: white;
  text-transform: none !important;
}
app-store-detail .store-name {
  color: #fcea10;
  font-size: 1.2rem !important;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
}
app-store-detail ion-label {
  color: white !important;
}
app-store-detail ion-avatar {
  display: none;
}
app-store-detail ion-footer {
  background: #2cb6b9;
}
app-store-detail ion-footer button.button_color_1 {
  line-height: 4px !important;
}
app-store-detail .address,
app-store-detail .horaires,
app-store-detail .tel,
app-store-detail .peculiarity {
  color: white !important;
}
app-store-detail ion-item,
app-store-detail ion-grid,
app-store-detail ion-row,
app-store-detail ion-col {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

app-directory ion-header {
  background: #2cb6b9;
}
app-directory ion-toolbar {
  background: #2cb6b9;
}
app-directory ion-toolbar ion-title {
  background: #2cb6b9;
}
app-directory ion-toolbar .toolbar-background-md {
  border-bottom: 1px solid white !important;
}
app-directory ion-toolbar .toolbar-title {
  text-align: left;
  padding: 9px 16px;
  text-overflow: ellipsis;
}
app-directory ion-toolbar .rewind-navigation {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
app-directory .ios-shortcuts {
  line-height: 26px;
  font-family: "Lato";
  border-bottom: 1px solid white !important;
  border-top: 0 solid transparent;
  width: 100%;
  justify-content: space-evenly;
}
app-directory .ios-shortcuts.formenupos {
  display: none !important;
}
app-directory .ios-shortcuts div {
  overflow-x: auto;
  display: flex;
}
app-directory .ios-shortcuts div button {
  white-space: nowrap;
  font-size: 1.5rem;
  color: white;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: transparent;
}
app-directory .ios-shortcuts div button.selected {
  background: white;
  color: #2cb6b9;
  font-size: 1.5rem;
}
app-directory .menu {
  background: #2cb6b9;
}
app-directory .menu .menu-scrollable {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
app-directory .menu .menu-scrollable .menu-button {
  color: white;
  text-align: center;
  font-size: 1.2rem;
  flex-direction: column;
  min-width: auto !important;
}
app-directory .menu .menu-scrollable .menu-button.depth1 {
  min-height: 105px;
  height: 105px;
  margin-top: 5px;
  font-family: "Lato";
  text-shadow: 1px 2px 3px #085051;
}
app-directory .menu .menu-scrollable .menu-button.selected-mainCategory {
  border-bottom: 2px solid white;
  font-family: "Lato";
}
app-directory .menu .menu-scrollable .menu-button .image-menu {
  min-width: 139px;
  max-height: 79px;
  max-width: 100px;
}
app-directory .menu .menu-scrollable .menu-button .text-center {
  max-width: 100px;
}
app-directory .bar-buttons-ios .pin_icon {
  margin-top: -20px;
}
app-directory .bar-buttons-ios .content-md {
  background: #2cb6b9;
}
@media screen and (max-width: 1023px) {
  app-directory .bar-buttons-ios .directory_cart {
    display: none;
  }
}
app-directory ion-content {
  background: #2cb6b9;
  --background: $color_1;
}
app-directory ion-content.ios .product_list {
  margin-top: 170px;
}

.grid-cell-container {
  margin-top: 10px;
}

grid-article-cell,
app-grid-cell {
  padding: 0 2px;
}
grid-article-cell:last-child,
app-grid-cell:last-child {
  margin-bottom: 20px !important;
}
grid-article-cell .disabled,
app-grid-cell .disabled {
  pointer-events: none;
  cursor: none;
}
grid-article-cell .disabled button,
app-grid-cell .disabled button {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}
grid-article-cell .disabled:hover,
app-grid-cell .disabled:hover {
  box-shadow: 0 6px 9px -4px rgba(50, 50, 50, 0.3);
  transition: all 0.2s ease-in-out;
  transform: translateY(-3px);
}
grid-article-cell > div,
app-grid-cell > div {
  transform: translateY(0px);
  transition: all 0.2s ease-in-out;
}
grid-article-cell > div.disabled,
app-grid-cell > div.disabled {
  opacity: 0.9;
}
grid-article-cell .design_grid.container_image,
app-grid-cell .design_grid.container_image {
  padding-top: 10px;
  height: auto;
  max-height: 156px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 3px 0 3px;
}
@media screen and (min-width: 768px) {
  grid-article-cell .design_grid.container_image,
app-grid-cell .design_grid.container_image {
    padding: 10px 10px 0 10px;
  }
}
grid-article-cell .design_grid.container_image img,
app-grid-cell .design_grid.container_image img {
  cursor: pointer;
  height: 100%;
  max-height: 137px !important;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd,
app-grid-cell .design_grid.container_image button.button_4.bt_cmd {
  display: none;
  width: 98%;
  height: 23px;
  cursor: default;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  bottom: 96px;
  left: 1%;
  -webkit-box-shadow: 2px 1px 3px 1px rgba(25, 89, 153, 0.26) !important;
  -moz-box-shadow: 2px 1px 3px 1px rgba(25, 89, 153, 0.26) !important;
  box-shadow: 2px 1px 3px 1px rgba(25, 89, 153, 0.26) !important;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd span,
app-grid-cell .design_grid.container_image button.button_4.bt_cmd span {
  letter-spacing: 0px;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd span div,
app-grid-cell .design_grid.container_image button.button_4.bt_cmd span div {
  font-size: 1.15rem;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd ion-icon,
app-grid-cell .design_grid.container_image button.button_4.bt_cmd ion-icon {
  margin-left: 6px;
  margin-right: 0px;
}
grid-article-cell .design_line.container_image,
app-grid-cell .design_line.container_image {
  height: 80px;
  text-align: center;
  width: 140px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
  margin-right: -20px;
}
grid-article-cell .design_line.container_image img,
app-grid-cell .design_line.container_image img {
  max-width: 100%;
  max-height: 100%;
}
grid-article-cell .checkbox,
app-grid-cell .checkbox {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 40px;
  top: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /*&:before {
      margin-left: 6%;
  }*/
}
grid-article-cell .checkbox span.checkbox_text,
app-grid-cell .checkbox span.checkbox_text {
  display: flex;
  margin-left: -20%;
  line-height: 28px;
}
grid-article-cell .checkbox ion-icon,
app-grid-cell .checkbox ion-icon {
  color: #2cb6b9 !important;
  font-size: 1.9rem;
  padding-right: 5px;
}
grid-article-cell .cart,
app-grid-cell .cart {
  pointer-events: initial;
  display: flex;
  align-items: center;
  background: none;
  background-size: cover !important;
  cursor: pointer;
  position: absolute;
  opacity: 1 !important;
  width: 30px;
  height: 30px;
}
grid-article-cell .cart:before,
app-grid-cell .cart:before {
  font-family: Ionicons;
  font-size: 36px;
  position: relative;
  border-radius: 50%;
  line-height: 30px;
  opacity: 1 !important;
}
grid-article-cell .properties,
app-grid-cell .properties {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 999;
  position: absolute;
  width: 100%;
}
grid-article-cell .properties .info,
app-grid-cell .properties .info {
  display: flex;
  cursor: pointer;
  width: 67px;
  height: 63px;
  background: transparent url("../../../assets/images/Theme_Heyou/Common/background_info.png") no-repeat center center !important;
  border-top-right-radius: 9px;
  top: 0px;
  right: -1px;
  position: absolute;
  background-size: contain;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  grid-article-cell .properties .info,
app-grid-cell .properties .info {
    transform: scale(0.7) translate(13px, -14px);
  }
}
grid-article-cell .properties .info:before,
app-grid-cell .properties .info:before {
  /*font-family: Ionicons;*/
  /*content: "\f312";*/
  font-size: 30px;
  position: absolute;
  left: 43px;
  font-style: italic;
  text-align: 0;
  top: 3px;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
}
grid-article-cell .properties .info ion-icon,
app-grid-cell .properties .info ion-icon {
  width: 30px;
  height: 30px;
  margin-left: 30px;
  color: white;
}
grid-article-cell .properties .breaking_div,
app-grid-cell .properties .breaking_div {
  clip-path: polygon(22% 0, 81% 0, 100% 84%, 100% 96%, 0 96%, 0 83%);
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  font-size: 0.7em;
  letter-spacing: 2px;
  right: 0;
  transform: rotate(43deg) translate(36%, -100%);
  font-family: "Montserrat";
  font-weight: 700;
  z-index: 1;
  line-height: 1.3em;
  transform-origin: 35% 0;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
}
grid-article-cell .properties .breaking_div div,
app-grid-cell .properties .breaking_div div {
  padding: 3% 26% 2% 18%;
  line-height: 1.3em;
  font-size: 1.1em;
  margin-top: -2px;
}
@media screen and (max-width: 320px) {
  grid-article-cell .properties .breaking_div,
app-grid-cell .properties .breaking_div {
    transform: scale(0.8) rotate(43deg) translate(45%, -106%) !important;
    transform-origin: 45% 0 !important;
    font-size: 0.5em;
  }
}
grid-article-cell .cart,
app-grid-cell .cart {
  display: none !important;
  width: 26px;
  height: 26px;
  top: 71px;
  left: 83%;
  opacity: 1 !important;
  justify-content: center;
  border-radius: 20px;
}
@media screen and (min-width: 1024px) {
  grid-article-cell .cart,
app-grid-cell .cart {
    left: 88% !important;
  }
}
grid-article-cell .cart:before,
app-grid-cell .cart:before {
  display: block;
  content: "";
  font-size: 21px;
  text-align: center;
}
grid-article-cell .price_button,
app-grid-cell .price_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
}
grid-article-cell .price_button .global_price,
app-grid-cell .price_button .global_price {
  position: relative;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
}
grid-article-cell .price_button .global_price .unit-price,
app-grid-cell .price_button .global_price .unit-price {
  text-align: center;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 5px;
  display: flex;
  background-color: #fcea10;
}
grid-article-cell .price_button .global_price .unit-price .euro,
app-grid-cell .price_button .global_price .unit-price .euro {
  margin-left: 3px;
  padding: 3px 20px 0 3px;
}
grid-article-cell .price_button .global_price .unit-price .euro .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro .withoutDecimalCSSClass {
  color: transparent;
  opacity: 0;
}
grid-article-cell .price_button .global_price .unit-price .euro .stroke,
app-grid-cell .price_button .global_price .unit-price .euro .stroke {
  text-decoration: line-through;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price,
app-grid-cell .price_button .global_price .unit-price .euro app-price {
  display: flex;
  font-family: "Lato";
  width: auto;
}
grid-article-cell .price_button .global_price .unit-price.binome-layer-price,
app-grid-cell .price_button .global_price .unit-price.binome-layer-price {
  width: 118px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
grid-article-cell .price_button .global_price .unit-price.binome-layer-price.unit,
app-grid-cell .price_button .global_price .unit-price.binome-layer-price.unit {
  width: 100%;
}
grid-article-cell .price_button .global_price .unit-price.binome-layer-price.hundred,
app-grid-cell .price_button .global_price .unit-price.binome-layer-price.hundred {
  width: 120px;
}
grid-article-cell .price_button .global_price .unit-price.binome-offer-price,
app-grid-cell .price_button .global_price .unit-price.binome-offer-price {
  width: 118px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
grid-article-cell .price_button .global_price .unit-price.binome-offer-price.unit,
app-grid-cell .price_button .global_price .unit-price.binome-offer-price.unit {
  width: 105px;
}
grid-article-cell .price_button .global_price .unit-price.binome-offer-price.hundred,
app-grid-cell .price_button .global_price .unit-price.binome-offer-price.hundred {
  width: 120px;
}
grid-article-cell .price_button .global_price .unit-price .decimal,
app-grid-cell .price_button .global_price .unit-price .decimal {
  font-size: smaller;
}
grid-article-cell .price_button .global_price .rate-price,
app-grid-cell .price_button .global_price .rate-price {
  font-family: "Montserrat";
  text-align: right;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  grid-article-cell .price_button .global_price .rate-price,
app-grid-cell .price_button .global_price .rate-price {
    padding-top: 0 !important;
    font-size: 1.7vw !important;
  }
}
grid-article-cell .price_button .global_price .layer-price,
app-grid-cell .price_button .global_price .layer-price {
  position: absolute;
  top: -20px;
  right: 0;
  letter-spacing: 0.1rem;
  font-family: "Lato";
  display: flex;
  z-index: 1;
}
grid-article-cell .price_button .global_price .layer-price .club_price,
app-grid-cell .price_button .global_price .layer-price .club_price {
  min-width: 75px;
  font-family: "Lato";
  border-top-left-radius: 5px;
  text-align: left;
  padding-left: 5px;
  margin-top: 2px;
}
grid-article-cell .price_button .global_price .layer-price .club_price .club,
app-grid-cell .price_button .global_price .layer-price .club_price .club {
  text-transform: uppercase;
  padding-left: 1px;
}
grid-article-cell .price_button .global_price .layer-price .line_through,
app-grid-cell .price_button .global_price .layer-price .line_through {
  top: 12px;
  left: 6px;
  width: 40px;
  height: 2px;
  z-index: 2;
  transform: rotate(-23deg);
  position: absolute;
}
grid-article-cell .price_button .global_price .layer-price .euro,
app-grid-cell .price_button .global_price .layer-price .euro {
  display: inline-flex;
  margin-top: 2px;
  border-top-left-radius: 0;
  text-align: center;
  min-width: 65px;
  height: 25px;
  right: 4px;
  position: absolute;
}
grid-article-cell .price_button .global_price .layer-price .euro.unit,
app-grid-cell .price_button .global_price .layer-price .euro.unit {
  min-width: 50px;
}
grid-article-cell .price_button .global_price .layer-price .euro.unit app-price,
app-grid-cell .price_button .global_price .layer-price .euro.unit app-price {
  min-width: 50px;
  border-bottom-right-radius: 0;
  padding-left: 9px;
}
grid-article-cell .price_button .global_price .layer-price .euro.unit app-price .line_through,
app-grid-cell .price_button .global_price .layer-price .euro.unit app-price .line_through {
  left: 13px;
  width: 31px;
}
grid-article-cell .price_button .global_price .layer-price .euro.hundred,
app-grid-cell .price_button .global_price .layer-price .euro.hundred {
  min-width: 68px;
}
grid-article-cell .price_button .global_price .layer-price .euro.hundred app-price .line_through,
app-grid-cell .price_button .global_price .layer-price .euro.hundred app-price .line_through {
  left: 18px;
  width: 35px;
  transform: rotate(-22deg);
}
grid-article-cell .price_button .global_price .layer-price .euro app-price,
app-grid-cell .price_button .global_price .layer-price .euro app-price {
  font-family: "Lato";
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  border-top-right-radius: 0;
  /*&:after {
      content: "";
      //    clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      position: absolute;
  }*/
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass,
grid-article-cell .price_button .global_price .layer-price .euro app-price .decimalCSSClass,
grid-article-cell .price_button .global_price .layer-price .euro app-price .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .decimalCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .withoutDecimalCSSClass {
  z-index: 1;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .withoutDecimalCSSClass {
  opacity: 0;
  display: none;
}
grid-article-cell .price_button .global_price .offer-price,
app-grid-cell .price_button .global_price .offer-price {
  letter-spacing: 0.1rem;
  font-family: "Lato";
  display: flex;
  z-index: 1;
  margin-top: -10px;
}
grid-article-cell .price_button .global_price .offer-price .club_price,
app-grid-cell .price_button .global_price .offer-price .club_price {
  min-width: 75px;
  font-family: "Lato";
  border-top-left-radius: 5px;
  text-align: left;
  padding-left: 5px;
  margin-top: 2px;
}
grid-article-cell .price_button .global_price .offer-price .club_price .club,
app-grid-cell .price_button .global_price .offer-price .club_price .club {
  text-transform: uppercase;
  padding-left: 1px;
}
grid-article-cell .price_button .global_price .offer-price .line_through,
app-grid-cell .price_button .global_price .offer-price .line_through {
  top: 12px;
  left: 6px;
  width: 40px;
  height: 2px;
  z-index: 2;
  transform: rotate(-23deg);
  position: absolute;
}
grid-article-cell .price_button .global_price .offer-price .euro,
app-grid-cell .price_button .global_price .offer-price .euro {
  display: inline-flex;
  margin-left: -20px;
  margin-top: 2px;
  border-top-left-radius: 0;
  text-align: center;
  min-width: 65px;
  height: 25px;
  justify-content: center;
  position: relative;
}
grid-article-cell .price_button .global_price .offer-price .euro.unit,
app-grid-cell .price_button .global_price .offer-price .euro.unit {
  min-width: 50px;
}
grid-article-cell .price_button .global_price .offer-price .euro.unit app-price,
app-grid-cell .price_button .global_price .offer-price .euro.unit app-price {
  min-width: 50px;
  border-bottom-right-radius: 0;
  padding-left: 9px;
}
grid-article-cell .price_button .global_price .offer-price .euro.unit app-price .line_through,
app-grid-cell .price_button .global_price .offer-price .euro.unit app-price .line_through {
  left: 13px;
  width: 31px;
}
grid-article-cell .price_button .global_price .offer-price .euro.hundred,
app-grid-cell .price_button .global_price .offer-price .euro.hundred {
  min-width: 68px;
}
grid-article-cell .price_button .global_price .offer-price .euro.hundred app-price .line_through,
app-grid-cell .price_button .global_price .offer-price .euro.hundred app-price .line_through {
  left: 18px;
  width: 35px;
  transform: rotate(-22deg);
}
grid-article-cell .price_button .global_price .offer-price .euro app-price,
app-grid-cell .price_button .global_price .offer-price .euro app-price {
  font-family: "Lato";
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  border-top-right-radius: 5px;
  /*&:after {
      content: "";
      //    clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      position: absolute;
  }*/
}
grid-article-cell .price_button .global_price .offer-price .euro app-price .integerCSSClass,
grid-article-cell .price_button .global_price .offer-price .euro app-price .decimalCSSClass,
grid-article-cell .price_button .global_price .offer-price .euro app-price .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .offer-price .euro app-price .integerCSSClass,
app-grid-cell .price_button .global_price .offer-price .euro app-price .decimalCSSClass,
app-grid-cell .price_button .global_price .offer-price .euro app-price .withoutDecimalCSSClass {
  z-index: 1;
}
grid-article-cell .price_button .global_price .offer-price .euro app-price .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .offer-price .euro app-price .withoutDecimalCSSClass {
  opacity: 0;
  display: none;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon,
app-grid-cell .price_button .addCart .bt_add_cart_icon {
  height: 33px;
  width: 120px;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  grid-article-cell .price_button .addCart .bt_add_cart_icon,
app-grid-cell .price_button .addCart .bt_add_cart_icon {
    width: 54px;
  }
}
grid-article-cell .price_button .addCart .bt_add_cart_icon:focus .icon_cart,
app-grid-cell .price_button .addCart .bt_add_cart_icon:focus .icon_cart {
  animation: rotate 1s ease infinite;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon .text,
app-grid-cell .price_button .addCart .bt_add_cart_icon .text {
  margin-left: 32px;
  opacity: 0.8;
  text-align: left;
}
@media screen and (max-width: 768px) {
  grid-article-cell .price_button .addCart .bt_add_cart_icon .text,
app-grid-cell .price_button .addCart .bt_add_cart_icon .text {
    display: none;
  }
}
grid-article-cell .price_button .addCart .bt_add_cart_icon .icon_cart,
app-grid-cell .price_button .addCart .bt_add_cart_icon .icon_cart {
  margin-top: -1px;
  position: absolute;
  left: 12px;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon .icon_cart:before,
app-grid-cell .price_button .addCart .bt_add_cart_icon .icon_cart:before {
  content: "" !important;
  font-family: "FontAwesome";
  font-size: 2.7rem;
  font-weight: 700;
  opacity: 0.6;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-5deg);
  }
  66% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(5deg);
  }
}
grid-article-cell .label,
app-grid-cell .label {
  height: 80px;
  padding-top: 10px;
  display: flex;
}
grid-article-cell .label .title,
app-grid-cell .label .title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-family: "Montserrat";
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  width: 95%;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 56px;
  text-overflow: ellipsis !important;
  white-space: wrap;
  overflow: hidden;
}
grid-article-cell .label .description,
app-grid-cell .label .description {
  justify-content: center;
  display: none;
}
grid-article-cell .controls_grid_cell,
app-grid-cell .controls_grid_cell {
  min-height: 57px;
}
grid-article-cell .controls_grid_cell .article_qte,
app-grid-cell .controls_grid_cell .article_qte {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
}
grid-article-cell .controls_grid_cell .article_qte .row,
app-grid-cell .controls_grid_cell .article_qte .row {
  width: 100%;
}
grid-article-cell .controls_grid_cell .article_qte .row button,
app-grid-cell .controls_grid_cell .article_qte .row button {
  cursor: pointer;
}
grid-article-cell .controls_grid_cell .article_qte .row button.activated,
app-grid-cell .controls_grid_cell .article_qte .row button.activated {
  background: transparent !important;
}
grid-article-cell .controls_grid_cell .article_qte .row button ion-icon,
app-grid-cell .controls_grid_cell .article_qte .row button ion-icon {
  font-size: 3rem;
}
grid-article-cell .controls_grid_cell .article_qte .row button.qte_min, grid-article-cell .controls_grid_cell .article_qte .row button.qte_max,
app-grid-cell .controls_grid_cell .article_qte .row button.qte_min,
app-grid-cell .controls_grid_cell .article_qte .row button.qte_max {
  height: 30px;
  width: 50px;
  border: 0 solid transparent;
  background-color: transparent !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
grid-article-cell .controls_grid_cell .article_qte .row button.qte_min.hidden, grid-article-cell .controls_grid_cell .article_qte .row button.qte_max.hidden,
app-grid-cell .controls_grid_cell .article_qte .row button.qte_min.hidden,
app-grid-cell .controls_grid_cell .article_qte .row button.qte_max.hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  grid-article-cell .controls_grid_cell .article_qte .row button.qte_max,
app-grid-cell .controls_grid_cell .article_qte .row button.qte_max {
    margin-left: -20px !important;
  }
}
grid-article-cell .controls_grid_cell .article_qte ion-row,
app-grid-cell .controls_grid_cell .article_qte ion-row {
  width: 100%;
}
grid-article-cell .controls_grid_cell .article_qte ion-row button,
app-grid-cell .controls_grid_cell .article_qte ion-row button {
  cursor: pointer;
}
grid-article-cell .controls_grid_cell .article_qte ion-row button.activated,
app-grid-cell .controls_grid_cell .article_qte ion-row button.activated {
  background: transparent !important;
}
grid-article-cell .controls_grid_cell .article_qte ion-row button ion-icon,
app-grid-cell .controls_grid_cell .article_qte ion-row button ion-icon {
  font-size: 1.6rem;
}
grid-article-cell .controls_grid_cell .article_qte ion-row button .qte_min,
grid-article-cell .controls_grid_cell .article_qte ion-row button .qte_max,
app-grid-cell .controls_grid_cell .article_qte ion-row button .qte_min,
app-grid-cell .controls_grid_cell .article_qte ion-row button .qte_max {
  /*height: 30px;*/
  /*width: 50px;*/
  border: 0 solid transparent;
  background-color: transparent !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
grid-article-cell .controls_grid_cell .article_qte ion-row button .qte_min .hidden,
grid-article-cell .controls_grid_cell .article_qte ion-row button .qte_max .hidden,
app-grid-cell .controls_grid_cell .article_qte ion-row button .qte_min .hidden,
app-grid-cell .controls_grid_cell .article_qte ion-row button .qte_max .hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  grid-article-cell .controls_grid_cell .article_qte ion-row button .qte_max,
app-grid-cell .controls_grid_cell .article_qte ion-row button .qte_max {
    margin-left: -20px !important;
  }
}
grid-article-cell .controls_grid_cell .article_qte ion-row button .hidden,
app-grid-cell .controls_grid_cell .article_qte ion-row button .hidden {
  display: none;
}
grid-article-cell .controls_grid_cell .qte,
app-grid-cell .controls_grid_cell .qte {
  margin-top: 2px !important;
}

grid-article-cell,
app-grid-cell {
  padding: 0;
}
grid-article-cell > div,
app-grid-cell > div {
  background: white;
  border-radius: 10px;
}
grid-article-cell > div:hover,
app-grid-cell > div:hover {
  box-shadow: 0 6px 9px -4px rgba(50, 50, 50, 0.3);
  transition: all 0.2s ease-in-out;
  transform: translateY(-3px);
}
grid-article-cell .design_grid.container_image,
app-grid-cell .design_grid.container_image {
  padding: 15px 3px 0 3px !important;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd:hover:not(.disable-hover),
app-grid-cell .design_grid.container_image button.button_4.bt_cmd:hover:not(.disable-hover) {
  background-color: #d2d2d2;
}
grid-article-cell .design_grid.container_image button.button_4.bt_cmd ion-icon,
app-grid-cell .design_grid.container_image button.button_4.bt_cmd ion-icon {
  color: white;
}
grid-article-cell .div_grid_cell,
app-grid-cell .div_grid_cell {
  box-shadow: 0 0 0 0 white;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
grid-article-cell .div_grid_cell.checked,
app-grid-cell .div_grid_cell.checked {
  box-shadow: 0 0 0 3px #47b4cf;
  transition: all 0.2s linear;
}
grid-article-cell .cart,
app-grid-cell .cart {
  background: color_3;
}
grid-article-cell .cart:before,
app-grid-cell .cart:before {
  color: #47b4cf;
}
grid-article-cell .properties .info:before,
app-grid-cell .properties .info:before {
  color: white;
}
grid-article-cell .properties .breaking_div,
app-grid-cell .properties .breaking_div {
  background: #2cb6b9;
  transform: scale(0.8) rotate(43deg) translate(45%, -106%) !important;
  transform-origin: 49% 0 !important;
}
grid-article-cell .properties .breaking_div div,
app-grid-cell .properties .breaking_div div {
  padding: 3% 22% 2% 17% !important;
  color: white;
  margin-top: -3px;
  font-size: 0.8em;
}
grid-article-cell .cart,
app-grid-cell .cart {
  background: #92fdff;
}
grid-article-cell .cart:before,
app-grid-cell .cart:before {
  color: white;
}
grid-article-cell .label,
app-grid-cell .label {
  display: flex;
  height: 55px;
  flex-direction: column;
}
grid-article-cell .label .title,
app-grid-cell .label .title {
  color: #2cb6b9;
  font-size: 1.1em;
  height: 40px;
  position: absolute;
}
grid-article-cell .label .description,
app-grid-cell .label .description {
  opacity: 0.9;
  font-size: 1.3rem;
  line-height: 1.4rem;
  display: none !important;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  padding: 0 15px;
  z-index: -1;
}
grid-article-cell .price_button,
app-grid-cell .price_button {
  position: relative;
  width: 100%;
  padding: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}
grid-article-cell .price_button .global_price,
app-grid-cell .price_button .global_price {
  width: 100%;
  flex-direction: row !important;
  padding: 0 5px;
}
grid-article-cell .price_button .global_price .unit-price,
app-grid-cell .price_button .global_price .unit-price {
  background: #fcea10;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-clip-path: polygon(0 0, calc(100% - 6px) 0, calc(100% - 21px) 100%, 0 100%);
  clip-path: polygon(0 0, calc(100% - 6px) 0, calc(100% - 21px) 100%, 0 100%);
  border-bottom-left-radius: 5px;
}
grid-article-cell .price_button .global_price .unit-price.binome-layer-price,
app-grid-cell .price_button .global_price .unit-price.binome-layer-price {
  border-top-left-radius: 0;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price,
app-grid-cell .price_button .global_price .unit-price .euro app-price {
  color: #2fafc8;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price .integerCSSClass,
grid-article-cell .price_button .global_price .unit-price .euro app-price .decimalCSSClass,
grid-article-cell .price_button .global_price .unit-price .euro app-price .withoutDecimalCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro app-price .integerCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro app-price .decimalCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro app-price .withoutDecimalCSSClass {
  z-index: 1;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price .integerCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro app-price .integerCSSClass {
  font-size: 2.2rem;
  line-height: 2.1rem;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price .decimalCSSClass,
app-grid-cell .price_button .global_price .unit-price .euro app-price .decimalCSSClass {
  font-size: 1.2rem;
  margin-top: 0.6rem;
}
grid-article-cell .price_button .global_price .unit-price .euro app-price:after,
app-grid-cell .price_button .global_price .unit-price .euro app-price:after {
  font-family: arial;
  font-size: 1rem;
  font-weight: 700;
  margin-left: -15px !important;
}
grid-article-cell .price_button .global_price .layer-price,
app-grid-cell .price_button .global_price .layer-price {
  justify-content: flex-end;
}
grid-article-cell .price_button .global_price .layer-price .club_price,
app-grid-cell .price_button .global_price .layer-price .club_price {
  display: block;
  width: 100%;
  right: 20px;
  position: absolute;
  min-width: 140px !important;
  background-color: #2fafc8;
  color: black;
  height: 18px;
  line-height: 18px;
  letter-spacing: -0.01rem;
  font-size: 0.8rem;
}
grid-article-cell .price_button .global_price .layer-price .club_price .club,
app-grid-cell .price_button .global_price .layer-price .club_price .club {
  color: black;
  font-weight: 400;
  font-size: 0.8rem;
}
grid-article-cell .price_button .global_price .layer-price .line_through,
app-grid-cell .price_button .global_price .layer-price .line_through {
  background-color: #2fafc8;
}
grid-article-cell .price_button .global_price .layer-price .euro,
app-grid-cell .price_button .global_price .layer-price .euro {
  background-color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
grid-article-cell .price_button .global_price .layer-price .euro.unit,
app-grid-cell .price_button .global_price .layer-price .euro.unit {
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 22% 100%);
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 22% 100%);
}
grid-article-cell .price_button .global_price .layer-price .euro.unit app-price,
app-grid-cell .price_button .global_price .layer-price .euro.unit app-price {
  background: #2cb6b9;
}
grid-article-cell .price_button .global_price .layer-price .euro.unit app-price::after,
app-grid-cell .price_button .global_price .layer-price .euro.unit app-price::after {
  -webkit-clip-path: polygon(4px 0, calc(100% - 1px) 0, calc(100% - 10px) 100%, 14px 100%);
  clip-path: polygon(4px 0, calc(100% - 1px) 0, calc(100% - 10px) 100%, 14px 100%);
  content: "";
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  background: #fff;
}
grid-article-cell .price_button .global_price .layer-price .euro.hundred,
app-grid-cell .price_button .global_price .layer-price .euro.hundred {
  min-width: 68px;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price,
app-grid-cell .price_button .global_price .layer-price .euro app-price {
  color: black;
  background: white;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass {
  font-size: 1.6rem;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass:after,
app-grid-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass:after {
  content: "€";
  color: #000;
  font-size: 0.8rem;
  bottom: 0.7rem;
  position: relative;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .decimalCSSClass,
app-grid-cell .price_button .global_price .layer-price .euro app-price .decimalCSSClass {
  font-size: 0.8rem;
  margin-top: 0.75rem;
  right: 0.6rem;
  position: relative;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass:after,
app-grid-cell .price_button .global_price .layer-price .euro app-price .integerCSSClass:after {
  color: white;
}
grid-article-cell .price_button .global_price .layer-price .euro app-price:after,
app-grid-cell .price_button .global_price .layer-price .euro app-price:after {
  font-size: 0.8rem;
  font-weight: 700;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon,
app-grid-cell .price_button .addCart .bt_add_cart_icon {
  text-transform: none;
  color: black;
  background-color: #dad9d9;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon:hover,
app-grid-cell .price_button .addCart .bt_add_cart_icon:hover {
  background-color: #eceaea !important;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon:hover .text,
app-grid-cell .price_button .addCart .bt_add_cart_icon:hover .text {
  color: #ea6e8f;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon:hover .icon_cart,
app-grid-cell .price_button .addCart .bt_add_cart_icon:hover .icon_cart {
  color: #ea6e8f !important;
}
grid-article-cell .price_button .addCart .bt_add_cart_icon .icon_cart,
app-grid-cell .price_button .addCart .bt_add_cart_icon .icon_cart {
  color: black !important;
}
grid-article-cell .price_button .addCart .button-md:hover:not(.disable-hover), grid-article-cell .price_button .addCart ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):first-child, ion-alert .alert-wrapper .alert-button-group grid-article-cell .price_button .addCart .alert-button:hover:not(.disable-hover):first-child, grid-article-cell .price_button .addCart ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):last-child, ion-alert .alert-wrapper .alert-button-group grid-article-cell .price_button .addCart .alert-button:hover:not(.disable-hover):last-child,
app-grid-cell .price_button .addCart .button-md:hover:not(.disable-hover),
app-grid-cell .price_button .addCart ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):first-child,
ion-alert .alert-wrapper .alert-button-group app-grid-cell .price_button .addCart .alert-button:hover:not(.disable-hover):first-child,
app-grid-cell .price_button .addCart ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):last-child,
ion-alert .alert-wrapper .alert-button-group app-grid-cell .price_button .addCart .alert-button:hover:not(.disable-hover):last-child {
  background-color: #eceaea !important;
}
grid-article-cell .controls_grid_cell .qte,
app-grid-cell .controls_grid_cell .qte {
  font-size: 1.5rem;
  color: #2cb6b9;
}
grid-article-cell .controls_grid_cell .qte_min,
grid-article-cell .controls_grid_cell .qte_max,
app-grid-cell .controls_grid_cell .qte_min,
app-grid-cell .controls_grid_cell .qte_max {
  background: transparent !important;
}
grid-article-cell .controls_grid_cell .qte_min .ion-md-remove-circle:before,
grid-article-cell .controls_grid_cell .qte_max .ion-md-remove-circle:before,
app-grid-cell .controls_grid_cell .qte_min .ion-md-remove-circle:before,
app-grid-cell .controls_grid_cell .qte_max .ion-md-remove-circle:before {
  color: #2cb6b9 !important;
  text-shadow: 4px 1px 2px #daddea !important;
  font-size: 2.7rem;
}
grid-article-cell .controls_grid_cell .qte_min .ion-md-add-circle:before,
grid-article-cell .controls_grid_cell .qte_max .ion-md-add-circle:before,
app-grid-cell .controls_grid_cell .qte_min .ion-md-add-circle:before,
app-grid-cell .controls_grid_cell .qte_max .ion-md-add-circle:before {
  color: #2cb6b9 !important;
  text-shadow: 4px 1px 2px #daddea !important;
  font-size: 2.7rem;
}
grid-article-cell .controls_grid_cell .qte_min ion-icon,
grid-article-cell .controls_grid_cell .qte_max ion-icon,
app-grid-cell .controls_grid_cell .qte_min ion-icon,
app-grid-cell .controls_grid_cell .qte_max ion-icon {
  color: #2cb6b9 !important;
  text-shadow: 4px 1px 2px #daddea !important;
  font-size: 1.6rem;
}
grid-article-cell .button-md:hover:not(.disable-hover), grid-article-cell ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):first-child, ion-alert .alert-wrapper .alert-button-group grid-article-cell .alert-button:hover:not(.disable-hover):first-child, grid-article-cell ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):last-child, ion-alert .alert-wrapper .alert-button-group grid-article-cell .alert-button:hover:not(.disable-hover):last-child,
app-grid-cell .button-md:hover:not(.disable-hover),
app-grid-cell ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):first-child,
ion-alert .alert-wrapper .alert-button-group app-grid-cell .alert-button:hover:not(.disable-hover):first-child,
app-grid-cell ion-alert .alert-wrapper .alert-button-group .alert-button:hover:not(.disable-hover):last-child,
ion-alert .alert-wrapper .alert-button-group app-grid-cell .alert-button:hover:not(.disable-hover):last-child {
  background-color: #2cb6b9 !important;
}

app-quote .toolbar-title, app-quote-review .toolbar-title {
  margin-top: 0 !important;
}
app-quote .scroll-content, app-quote-review .scroll-content {
  background: #2cb6b9;
  margin-bottom: 120px;
}
app-quote .scroll-content button, app-quote-review .scroll-content button {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
app-quote .paniervide-text, app-quote-review .paniervide-text {
  color: white;
  font-size: 1rem;
}
app-quote .quote-header .title, app-quote-review .quote-header .title {
  font-size: 4vw;
  color: white;
}
app-quote ion-footer, app-quote-review ion-footer {
  background: #2cb6b9;
}
app-quote ion-footer button, app-quote ion-footer .button_color_2, app-quote-review ion-footer button, app-quote-review ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-quote ion-content, app-quote-review ion-content {
  --background: $color_1 !important;
}
app-quote .mobile_button, app-quote-review .mobile_button {
  margin-left: auto !important;
  margin-right: auto !important;
}
@media screen and (min-width: 1024px) {
  app-quote .mobile_button, app-quote-review .mobile_button {
    display: none;
  }
}
app-quote .widget_button, app-quote-review .widget_button {
  padding-bottom: 50px;
  width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  app-quote .widget_button, app-quote-review .widget_button {
    display: none;
  }
}
app-quote .widget_button button, app-quote-review .widget_button button {
  margin-left: auto !important;
  margin-right: auto !important;
}
app-quote .widget_button button.novisible, app-quote-review .widget_button button.novisible {
  display: none !important;
}
app-quote .widget_button button.visible, app-quote-review .widget_button button.visible {
  display: flex !important;
}
app-quote app-order-lines app-price ion-icon, app-quote-review app-order-lines app-price ion-icon {
  margin-left: 0px !important;
  font-size: 1rem !important;
  color: #fcea10 !important;
}
app-quote ion-header ion-toolbar, app-quote-review ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-order-lines app-price ion-icon {
  color: #fcea10 !important;
  padding-left: 20px !important;
}
app-order-lines .store-name {
  color: white !important;
  font-family: "Montserrat";
  margin-left: 32px !important;
}
app-order-lines .list-header {
  color: white;
}
app-order-lines button, app-order-lines .button, app-order-lines ion-icon {
  color: white;
}
app-order-lines .order-line .level0, app-order-lines .order-line .level1, app-order-lines .order-line .level2, app-order-lines .order-line .level0_price {
  font-family: "Montserrat";
}
app-order-lines .order-line .level0 .article_name, app-order-lines .order-line .level1 .article_name, app-order-lines .order-line .level2 .article_name, app-order-lines .order-line .level0_price .article_name {
  color: #fcea10;
}
app-order-lines .order-line .level0 .btn_cart_personalize, app-order-lines .order-line .level1 .btn_cart_personalize, app-order-lines .order-line .level2 .btn_cart_personalize, app-order-lines .order-line .level0_price .btn_cart_personalize {
  color: #92fdff;
}
app-order-lines .order-line .level0 .btn_cart_personalize span, app-order-lines .order-line .level1 .btn_cart_personalize span, app-order-lines .order-line .level2 .btn_cart_personalize span, app-order-lines .order-line .level0_price .btn_cart_personalize span {
  padding-right: 10px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 1024px) {
  app-order-lines .order-line .level0 .btn_cart_personalize span, app-order-lines .order-line .level1 .btn_cart_personalize span, app-order-lines .order-line .level2 .btn_cart_personalize span, app-order-lines .order-line .level0_price .btn_cart_personalize span {
    display: none;
  }
}
app-order-lines .order-line .level0 .article_price, app-order-lines .order-line .level1 .article_price, app-order-lines .order-line .level2 .article_price, app-order-lines .order-line .level0_price .article_price {
  color: #fcea10;
}
app-order-lines .order-line .level0 .article_name {
  padding-top: 12px;
}
app-order-lines .order-line .level0 .article_qte .qte {
  color: #fcea10 !important;
  font-size: 1.8rem;
  padding-top: 3px;
}
app-order-lines .order-line .level0 .article_qte button {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
app-order-lines .order-line app-price:after {
  margin-top: 3px;
  margin-left: 2px;
}
app-order-lines .order-line .level1 {
  padding-left: 20px !important;
}
app-order-lines .order-line .level1 .article_name {
  color: #fcea10;
}
app-order-lines .order-line .level1 app-price:after {
  margin-top: 0px;
}
app-order-lines .order-line .level2 {
  padding-left: 40px !important;
}
app-order-lines .order-line .level2 .article_name {
  color: #2cb6b9;
}
app-order-lines .order-line .level2 app-price:after {
  margin-top: 0px;
}
app-order-lines .order-line .icon-create::before {
  color: #92fdff;
}
app-order-lines .btn_cart_update {
  color: #92fdff;
}
app-order-lines .btn_cart_update span {
  padding-right: 10px;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}
app-order-lines .list-header {
  background: white;
  font-weight: 700;
  color: white;
}
app-order-lines .total {
  color: #fcea10;
  font-family: "Montserrat";
}
app-order-lines .total .price {
  color: #fcea10;
}
app-order-lines .total app-price:after {
  margin-top: 10px;
  font-size: 2rem !important;
  margin-left: 2px;
}
app-order-lines .total app-price.discard:after {
  margin-top: 5px;
}
app-order-lines .tva_detail, app-order-lines .tva_titre {
  color: white;
  font-size: 1.8rem;
}
app-order-lines .divider {
  background: white;
}
app-order-lines .divider_compose {
  background: transparent !important;
}
app-order-lines .divider_compose div {
  background: white;
}
app-order-lines ion-footer button {
  margin-left: auto !important;
  margin-right: auto !important;
}
app-order-lines ion-icon {
  font-size: 1.2rem;
}

app-validation-order {
  background-color: #2cb6b9 !important;
}
app-validation-order ion-content {
  background-color: transparent !important;
}
app-validation-order a {
  color: white;
  font-family: "Lato";
}
app-validation-order p {
  color: white !important;
  font-family: "Lato";
  font-size: 1.5rem !important;
  padding-left: 20px !important;
}
app-validation-order ion-label {
  color: white;
  font-family: "Lato";
  font-size: 2.2rem;
}
app-validation-order ion-list {
  background: #2cb6b9 !important;
}
app-validation-order ion-list ion-item, app-validation-order ion-list .item-block {
  background-color: transparent;
  color: white;
  font-family: "Lato";
}
app-validation-order ion-list ion-item ion-label, app-validation-order ion-list .item-block ion-label {
  font-size: 2.2rem;
}
app-validation-order ion-list ion-item ion-checkbox .checkbox-icon, app-validation-order ion-list ion-item ion-checkbox .radio-icon, app-validation-order ion-list ion-item ion-radio .checkbox-icon, app-validation-order ion-list ion-item ion-radio .radio-icon, app-validation-order ion-list .item-block ion-checkbox .checkbox-icon, app-validation-order ion-list .item-block ion-checkbox .radio-icon, app-validation-order ion-list .item-block ion-radio .checkbox-icon, app-validation-order ion-list .item-block ion-radio .radio-icon {
  top: -3px;
  /*&.checkbox-checked, &.radio-checked {
      background-image:none !important;
      &:before{
          font-family: Ionicons;
          content:"\f2bc";
          color : $color_white;
          font-size: 25px;
      }
  }*/
}
app-validation-order ion-list ion-item ion-checkbox .checkbox-icon:before, app-validation-order ion-list ion-item ion-checkbox .radio-icon:before, app-validation-order ion-list ion-item ion-radio .checkbox-icon:before, app-validation-order ion-list ion-item ion-radio .radio-icon:before, app-validation-order ion-list .item-block ion-checkbox .checkbox-icon:before, app-validation-order ion-list .item-block ion-checkbox .radio-icon:before, app-validation-order ion-list .item-block ion-radio .checkbox-icon:before, app-validation-order ion-list .item-block ion-radio .radio-icon:before {
  content: "";
}
app-validation-order ion-list ion-item ion-checkbox.white, app-validation-order ion-list ion-item ion-radio.white, app-validation-order ion-list .item-block ion-checkbox.white, app-validation-order ion-list .item-block ion-radio.white {
  filter: none;
}
app-validation-order ion-list ion-item .radio-md .radio-icon, app-validation-order ion-list .item-block .radio-md .radio-icon {
  border: 0px solid transparent;
}
app-validation-order ion-list .item-cover {
  border-bottom: 0.5px solid white;
}
app-validation-order .amount p {
  font-size: 2.2rem;
}
app-validation-order .amount .text {
  font-size: 1.1rem;
  line-height: 20px;
}
app-validation-order .item-radio-checked.item ion-label {
  color: white !important;
}
app-validation-order .cgu-item .item-inner {
  border-bottom: 0 solid transparent !important;
}
app-validation-order .cgu-item .item-inner ion-label {
  font-size: 1.4rem !important;
  color: white !important;
}
app-validation-order .cgu-item ion-label {
  font-size: 0.9rem !important;
  color: white !important;
  padding-left: 5px !important;
}
app-validation-order .cgu-item .checkbox-icon, app-validation-order .cgu-item .radio-icon {
  border: 0 solid transparent;
  width: 30px;
  height: 30px;
  border-radius: 0% !important;
  background-image: none !important;
  background-color: transparent;
}
app-validation-order .cgu-item .checkbox-icon:before, app-validation-order .cgu-item .radio-icon:before {
  font-family: Ionicons;
  content: "";
  color: white;
  font-size: 25px;
}
app-validation-order .cgu-item .checkbox-icon.checkbox-checked, app-validation-order .cgu-item .checkbox-icon.radio-checked, app-validation-order .cgu-item .radio-icon.checkbox-checked, app-validation-order .cgu-item .radio-icon.radio-checked {
  background-image: none !important;
}
app-validation-order .cgu-item .checkbox-icon.checkbox-checked:before, app-validation-order .cgu-item .checkbox-icon.radio-checked:before, app-validation-order .cgu-item .radio-icon.checkbox-checked:before, app-validation-order .cgu-item .radio-icon.radio-checked:before {
  font-family: Ionicons;
  content: "";
  color: white;
  font-size: 25px;
}
app-validation-order .cgu-item .checkbox-icon .alert-checkbox-icon, app-validation-order .cgu-item .radio-icon .alert-checkbox-icon {
  border-radius: 0 !important;
}
app-validation-order .item.item-md .checkbox-md {
  margin: 9px 8px 9px 4px;
}
app-validation-order ion-footer {
  background: #2cb6b9 !important;
}
app-validation-order ion-footer button, app-validation-order ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-validation-order ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-validation-order ion-content ion-item {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-validation-order ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-validation-order ion-radio {
  /*--color: transparent !important;
  --color-checked: transparent;*/
}
app-validation-order ion-radio .radio-inner {
  width: 25px !important;
  height: 40px !important;
}
app-validation-order .radio-inner {
  width: 55% !important;
  height: 100% !important;
}

app-bundle {
  background: #2cb6b9;
}
app-bundle ion-header {
  background: #2cb6b9;
}
app-bundle .toolbar-content {
  background: #2cb6b9;
}
app-bundle .toolbar-title {
  margin-left: -20px;
  font-size: 18px !important;
}
app-bundle .scroll-content {
  background: #2cb6b9;
}
app-bundle .step_menu {
  border-bottom: 1px solid lightgray;
}
app-bundle .step_menu div.img_label {
  font-size: 2rem;
  color: white;
}
app-bundle p {
  color: #2cb6b9;
}
app-bundle .button_color_1 {
  margin-right: auto;
  min-width: 58%;
  margin-left: auto;
}
app-bundle .button_color_2 {
  margin-left: 10px !important;
  min-width: 45%;
}
app-bundle ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-bundle ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-bundle .img_bar {
  background-color: #2cb6b9 !important;
}

app-info ion-content {
  background: #2cb6b9;
  --background: $color_1;
}
app-info .scroll-content {
  overflow-y: hidden !important;
  margin-top: 10px !important;
}
app-info .toolbar-title {
  margin-left: -20px;
}
app-info .title {
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fcea10;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
app-info .price_article {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: right;
  margin-top: 10px;
  display: inline-flex;
  align-self: center;
  align-content: right;
  height: 50px;
  flex-direction: row;
}
app-info .price_article .layer-price {
  bottom: -20px;
  letter-spacing: 0.1rem;
  font-family: "Lato";
  display: inline-flex;
  align-self: center;
  align-content: center;
  position: absolute;
  right: 27px;
  z-index: 2;
  opacity: 0.8;
}
app-info .price_article .layer-price .club_price {
  display: none;
}
app-info .price_article .layer-price .line_through {
  background-color: white;
  top: 15px;
  left: 18px;
  width: 65%;
  height: 2px;
  position: absolute;
  z-index: 3;
  display: flex !important;
}
app-info .price_article .layer-price .euro {
  display: inline-flex;
  margin-left: -22px;
  background: transparent;
  color: #38423f !important;
  text-align: center;
  min-width: 76px;
  height: 25px;
  justify-content: center;
  position: relative;
}
app-info .price_article .layer-price .euro.unit {
  min-width: 55px;
}
app-info .price_article .layer-price .euro.unit app-price {
  padding-left: 10px;
}
app-info .price_article .layer-price .euro.unit app-price .line_through {
  left: 15px;
  width: 31px;
}
app-info .price_article .layer-price .euro.hundred {
  min-width: 68px;
}
app-info .price_article .layer-price .euro.hundred app-price {
  padding-left: 8px;
}
app-info .price_article .layer-price .euro.hundred app-price .line_through {
  left: 18px;
  width: 39px;
  top: 11px;
  transform: rotate(-22deg);
}
app-info .price_article .layer-price .euro app-price {
  font-family: "Lato";
  color: white;
  font-weight: 700;
  padding-left: 8px;
  padding-top: 2px;
  opacity: 0.7;
}
app-info .price_article .layer-price .euro app-price .integerCSSClass, app-info .price_article .layer-price .euro app-price .decimalCSSClass, app-info .price_article .layer-price .euro app-price .withoutDecimalCSSClass {
  z-index: 1;
}
app-info .price_article .layer-price .euro app-price .withoutDecimalCSSClass {
  opacity: 0;
  display: none;
  font-size: 0;
}
app-info .price_article .layer-price .euro app-price .integerCSSClass {
  font-size: 1.8rem;
}
app-info .price_article .layer-price .euro app-price .decimalCSSClass {
  font-size: 1.8rem;
  margin-top: 0.8rem;
  right: 0.6rem;
  position: relative;
}
app-info .price_article .layer-price .euro app-price:after {
  content: "";
  position: relative;
  font-weight: 700;
  margin-left: -5px;
  font-size: 1.5rem;
}
app-info .price_article .layer-price .euro app-price :after {
  content: "";
  position: relative;
  font-weight: 700;
  margin-left: -5px;
  font-size: 1.5rem;
}
app-info .price_article .layer-price .euro app-price ion-icon {
  margin-left: 0px !important;
  font-size: 1rem;
}
app-info .price_article .unit-price {
  background: transparent;
  justify-content: left !important;
  /*bottom: -70px;*/
  align-self: right;
  align-content: right;
  display: inline-flex;
  position: absolute;
  right: -6px;
  z-index: 1;
}
app-info .price_article .unit-price .euro {
  margin-left: 3px;
  line-height: 83px;
  color: white !important;
  width: 140px;
  height: 85px;
  background-size: contain;
}
app-info .price_article .unit-price .euro .withoutDecimalCSSClass:after {
  left: 3px;
}
app-info .price_article .unit-price .euro app-price {
  font-family: "Lato";
  color: white;
  width: auto;
  padding-right: 6px;
  font-size: 1.9rem;
  font-weight: 700;
}
app-info .price_article .unit-price .euro app-price .integerCSSClass, app-info .price_article .unit-price .euro app-price .decimalCSSClass, app-info .price_article .unit-price .euro app-price .withoutDecimalCSSClass {
  z-index: 1;
}
app-info .price_article .unit-price .euro app-price .integerCSSClass {
  font-size: 1.9rem;
  line-height: 2.5rem;
}
app-info .price_article .unit-price .euro app-price .withoutDecimalCSSClass {
  opacity: 0;
  display: none;
  font-size: 0;
}
app-info .price_article .unit-price .euro app-price .decimalCSSClass {
  font-size: 1.9rem;
  margin-left: -5px;
}
app-info .price_article .unit-price .euro app-price:after {
  font-size: smaller;
  font-weight: 700;
  bottom: 1px;
  position: relative;
}
app-info .price_article .unit-price .euro app-price ion-icon {
  margin-left: 0px !important;
  font-size: 1rem;
}
app-info .price_article .unit-price.binome-layer-price {
  width: 114px;
}
app-info .price_article .unit-price.binome-layer-price .euro {
  padding-top: 7px;
}
app-info .price_article .unit-price.binome-layer-price.unit {
  width: 108px;
}
app-info .price_article .unit-price.binome-layer-price.hundred {
  width: 118px;
}
app-info .description {
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 2rem;
  font-weight: 100;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
app-info .attributes .item-md.item-block .item-inner {
  border-bottom: 0 solid transparent !important;
}
app-info .attributes .label-md {
  margin: 0 !important;
  font-size: 2rem;
  color: white !important;
  font-family: "Montserrat";
}
app-info .attributes .label-md .item .label-md {
  font-size: 1.8rem;
  font-family: "Montserrat" !important;
}
app-info .attributes .item-block {
  min-height: 20px;
}
app-info .container_image {
  background: #2cb6b9;
  padding-bottom: 10px;
  padding-top: 15px;
}
app-info img {
  margin: auto;
  display: inherit;
  height: 90%;
  margin-top: 20px;
}
app-info .text-input {
  color: white;
}
app-info.qte_min .ion-md-remove-circle:before, app-info.qte_max .ion-md-remove-circle:before {
  color: white !important;
  text-shadow: 4px 1px 2px #DADDEA !important;
  font-size: 1.2em;
}
app-info.qte_min .ion-md-add-circle:before, app-info.qte_max .ion-md-add-circle:before {
  color: white !important;
  text-shadow: 4px 1px 2px #DADDEA !important;
  font-size: 1.2em;
}
app-info ion-footer {
  background: #2cb6b9;
}
app-info ion-footer .heavy {
  color: white !important;
  font-size: 1.5rem;
}
app-info ion-footer ion-icon {
  color: white !important;
  width: 30px;
  height: 30px;
}
app-info ion-footer button.bt_cmd {
  width: 320px !important;
  margin-bottom: 15px !important;
  margin-left: auto;
  margin-right: auto;
  cursor: default;
}
app-info ion-footer button.bt_cmd span {
  font-size: 1.8rem;
}
app-info ion-footer button.bt_cmd .check_dispo {
  display: none;
}
app-info ion-footer button.bt_add {
  padding-left: 35px;
  margin-bottom: 10px !important;
  font-size: 2.2rem;
}
app-info ion-footer button.bt_add span {
  font-size: 2rem;
}
app-info ion-footer button.bt_add ion-icon {
  display: none;
}
@media screen and (min-width: 768px) {
  app-info ion-footer button.qte_min, app-info ion-footer button.qte_max {
    width: 50px !important;
  }
}
app-info ion-item {
  --background: $color_1 !important;
  color: white !important;
  font-size: 1.4rem;
}

app-options .grid-cell-container {
  background: transparent !important;
}
app-options ion-title {
  color: white !important;
  font-family: "Montserrat" !important;
}
app-options ion-title .toolbar-title {
  margin-top: 10px !important;
  margin-left: -22px;
}
app-options ion-title .toolbar-title .subtitle {
  opacity: 0.7;
  font-size: 1.5rem;
}
app-options ion-content {
  --background: $color_1 !important;
  background-color: #2cb6b9 !important;
  background: #2cb6b9 !important;
}
app-options .content.article-page-layout-line {
  background-color: #2cb6b9 !important;
}
app-options .content.article-page-layout-line .scroll-content {
  background: #2cb6b9 !important;
}
.ios app-options .content .scroll-content {
  margin-top: 65px !important;
}

app-options .content.article-page-layout-line .scroll-content .checkbox {
  height: 25% !important;
  top: 24% !important;
  left: 10px !important;
  z-index: 1;
  float: left;
  color: #2cb6b9;
}
app-options .content.article-page-layout-line .scroll-content .checkbox:before {
  color: #2cb6b9;
  font-size: 30px;
}
app-options .content.article-page-layout-line .scroll-content p {
  color: #2cb6b9;
  width: 72%;
  line-height: 1.6rem;
  padding-left: 40px;
}
app-options .content.article-page-layout-line .scroll-content .container-cart {
  display: none;
}
app-options .content.article-page-layout-line .scroll-content .grid-cell-container.single-element {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}
app-options .content.article-page-layout-line .scroll-content .grid-cell-container.single-element grid-article-cell,
app-options .content.article-page-layout-line .scroll-content .grid-cell-container.single-element app-grid-cell {
  width: 100%;
}
app-options .content.article-page-layout-line .scroll-content .deposit_text {
  font-family: "Montserrat" !important;
  color: #2cb6b9;
}
app-options .content.article-page-layout-grid {
  background-color: #2cb6b9 !important;
}
app-options .content.article-page-layout-grid .scroll-content {
  background: #2cb6b9 !important;
}
.ios app-options .content .scroll-content {
  margin-top: 65px !important;
}

app-options .content.article-page-layout-grid .scroll-content .checkbox {
  height: 25% !important;
  top: 38% !important;
  left: 7px !important;
  z-index: 1;
  float: left;
  color: #2cb6b9;
}
app-options .content.article-page-layout-grid .scroll-content .checkbox:before {
  color: #2cb6b9;
  font-size: 30px;
}
app-options .content.article-page-layout-grid .scroll-content p {
  color: #2cb6b9;
  width: 100%;
  line-height: 1.6rem;
}
app-options .content.article-page-layout-grid .scroll-content .container-cart {
  display: none;
}
app-options ion-footer {
  background: #2cb6b9;
  height: 70px;
  line-height: 70px;
}
app-options ion-footer button {
  font-size: 2.3rem;
}
app-options ion-footer .button_color_1 {
  min-width: 47%;
  margin-left: auto;
}
app-options ion-footer .button_color_2 {
  min-width: 47%;
}

app-cgv, app-gcs, app-legals {
  /* List Definitions */
}
app-cgv .toolbar-background, app-gcs .toolbar-background, app-legals .toolbar-background {
  border: none !important;
}
app-cgv .header-wp .scroll-content, app-gcs .header-wp .scroll-content, app-legals .header-wp .scroll-content {
  margin-top: 70px !important;
}
app-cgv .toolbar-title, app-gcs .toolbar-title, app-legals .toolbar-title {
  color: white !important;
}
app-cgv h1, app-gcs h1, app-legals h1 {
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 8px;
}
app-cgv .cgv_content, app-gcs .cgv_content, app-legals .cgv_content {
  padding: 20px;
  text-align: justify;
}
app-cgv ol, app-gcs ol, app-legals ol {
  margin-bottom: 0cm;
}
app-cgv ul, app-gcs ul, app-legals ul {
  margin: 0;
  padding: 0;
  margin-left: 15px;
}
app-cgv ul > li, app-gcs ul > li, app-legals ul > li {
  margin-top: 15px;
}
app-cgv ul > ul > li, app-gcs ul > ul > li, app-legals ul > ul > li {
  margin: 0;
}
app-cgv ion-content, app-gcs ion-content, app-legals ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-cgv ion-header ion-toolbar, app-gcs ion-header ion-toolbar, app-legals ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-profile .welcome {
  font-size: 1.4rem !important;
  line-height: 1.9rem;
}
app-profile .deco {
  display: none;
  width: 121px;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
  background: white url("../../../assets/images/Theme_Heyou/Common/deco.png") no-repeat center !important;
  margin-bottom: 15px;
}
app-profile .visuel_profil {
  width: 100%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  /*Comment by sajid*/
  /*background: white url('../../../assets/images/Theme_Heyou/Common/visuel_profil.png') no-repeat !important;*/
  margin-bottom: 15px;
  background-size: contain;
}
app-profile [dir=ltr] .card-ios, app-profile [dir=rtl] .card-ios {
  border: 1px solid #2cb6b9;
}
app-profile .store-detail {
  display: flex;
}
app-profile .store-detail ion-card, app-profile .store-detail ion-list {
  margin: 10px auto !important;
}
app-profile .store-detail .store-name {
  font-size: 1.4rem !important;
  line-height: 1.9rem;
}
app-profile .store-detail p {
  font-size: 1.2rem !important;
  line-height: 1.5rem !important;
}
app-profile .store-detail ion-list {
  width: 86vw !important;
}
app-profile .store-detail ion-list ion-button {
  width: 100% !important;
}
app-profile .store-detail ion-card {
  width: 86vw !important;
}
app-profile .store-detail ion-card ion-card-title {
  color: white !important;
  font-size: 2rem !important;
  font-family: "Montserrat";
  /*color:$color_1;*/
}
app-profile .store-detail ion-card p {
  color: white;
  text-align: center;
}
app-profile .store-detail ion-card .ico {
  display: table-cell;
  width: 70px !important;
  height: 70px !important;
  background: white url("../../../assets/images/Theme_Heyou/Common/deco.png") no-repeat center !important;
  background-size: contain;
  background-position: center;
}
app-profile h2 {
  font-family: "Montserrat";
  color: white;
}
app-profile ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-profile ion-content ion-card, app-profile ion-content ion-list {
  background: #2cb6b9 !important;
}
app-profile ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-orders-list .title_bar {
  background-color: #fcea10 !important;
  font-family: "Montserrat";
  color: #2cb6b9;
}
app-orders-list .title_bar ion-label {
  color: #2cb6b9 !important;
}
app-orders-list .link {
  color: white !important;
}
app-orders-list ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-orders-list ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-orders-list ion-label {
  color: white;
}
app-orders-list ion-item {
  color: white !important;
}
app-orders-list ion-item ion-label {
  font-family: "Lato";
}
app-orders-list .title_bar {
  font-family: "Montserrat";
}
app-orders-list .button-accordion:hover, app-orders-list .button-accordion:focus {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
app-orders-list .button-accordion ion-icon {
  color: #2cb6b9;
  font-size: 1.7rem;
}
app-orders-list app-price ion-icon {
  padding-left: 10px !important;
}
app-orders-list ion-item.see-more-item {
  --border-color: $color_white !important;
  /*--border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0))));*/
}

app-take-back .title {
  margin-bottom: 0px !important;
  text-align: left !important;
}
app-take-back .scroll-content {
  background: #fcea10 !important !important;
  overflow-y: hidden !important;
  margin-top: 0 !important;
}
app-take-back .scroll-content button.button_color_1 {
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: -200px !important !important;
  height: auto;
}
app-take-back .scroll-content button.button_color_2 {
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 15px;
  margin-top: 15px;
  height: auto;
}
app-take-back ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-take-back ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-take-away .title {
  margin-bottom: 0px !important;
  text-align: left !important;
}
app-take-away .title .toolbar-title {
  text-transform: none;
}
app-take-away .grid {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  app-take-away .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  app-take-away .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
app-take-away .grid .button_icon_1 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/store.png") center center no-repeat;
  background-size: contain;
}
app-take-away .grid .button_icon_2 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/take-away.png") center center no-repeat;
  background-size: contain;
}
app-take-away .grid .button_icon_1, app-take-away .grid .button_icon_2 {
  /*font-family: $second-font;*/
  /*color: $color_white;*/
}
app-take-away .scroll-content {
  background: #2cb6b9 !important !important;
  overflow-y: hidden !important;
}
app-take-away ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-take-away ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-mention .header-wp .scoll-content {
  margin-top: 70px !important;
}
app-mention .toolbar-title {
  color: white !important;
}
app-mention p {
  text-align: justify;
  font-size: 2.5rem !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-user-form .edit-button + ion-icon {
  color: #aaa;
}
app-user-form .edit-button:checked + ion-icon {
  color: #fff;
}
app-user-form .content.editable .error.active {
  color: #fcea10;
}
app-user-form .content.editable .error.active + .mandatory:before {
  color: #fcea10;
}
app-user-form .form-register {
  margin-left: auto !important;
  margin-right: auto !important;
  font-family: "Lato" !important;
  /*.radio-icon {
      &:before {
          color: $color_white !important;
      }
  }*/
}
app-user-form .form-register ion-radio {
  --color: $color_white !important;
  --color-checked: $color_white !important;
  color: white !important;
  color-checked: white !important;
}
app-user-form .form-register ion-label {
  color: white !important;
}
app-user-form .form-register .item-input ion-input,
app-user-form .form-register .item-input ion-textarea {
  color: white;
}
app-user-form .form-register .item-input ion-input input,
app-user-form .form-register .item-input ion-textarea input {
  font-family: "Lato" !important;
  color: white !important;
}
app-user-form .form-register .item-input ion-input input::placeholder,
app-user-form .form-register .item-input ion-textarea input::placeholder {
  color: white !important;
}
app-user-form .form-register ion-item {
  --background: $color_1 !important;
}
app-user-form .form-register ion-item .bt_pass span {
  color: white !important;
  /*i {
      &.eye_on {
          &:after {
              color: $color_white !important;
          }
      }
      &.eye_off {
          &:after {
              color: $color_white !important;
          }
      }
  }*/
}
app-user-form .form-register ion-item .bt_pass span ion-icon {
  color: white !important;
  font-size: 1.2rem;
}
app-user-form .check_offre .checkbox-icon:before {
  color: #2cb6b9;
}
app-user-form .check_offre .checkbox-icon:before {
  color: white;
}
app-user-form .accept {
  font-size: 1rem;
}
app-user-form .accept a {
  color: white;
}
app-user-form ion-item {
  --background: $color_1 !important;
  font-family: "Lato";
}
app-user-form ion-item .datetime .datetime-text {
  color: white;
}
app-user-form ion-item .itemcontent {
  color: white;
}
app-user-form ion-item .itemcontent input::placeholder,
app-user-form ion-item .itemcontent .datetime-placeholder {
  color: white;
}
app-user-form ion-item .itemcontent ion-textarea,
app-user-form ion-item .itemcontent ion-input,
app-user-form ion-item .itemcontent .datetime {
  font-family: "Lato";
}
app-user-form ion-item ion-label {
  color: white;
}
app-user-form ion-item .radio-md.radio-disabled,
app-user-form ion-item .item-md.item-radio-disabled ion-label {
  opacity: 1 !important;
}
app-user-form .button_color_1.modif {
  margin-top: 50px;
  width: auto !important;
  background: #2cb6b9 !important;
  color: white !important;
}
app-user-form .button_color_1.modif span {
  font-size: 1.4rem;
  color: white !important;
  opacity: 0.8;
}
app-user-form .password-icon {
  color: white !important;
}
app-user-form .ion-item-dob {
  --border-color: $color_white !important;
}
app-user-form ion-footer {
  background: #2cb6b9;
}
app-user-form ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-user-form ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-user-form ion-radio {
  border: 1px solid white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-bottom: 4px;
  color: white;
}
app-user-form ion-checkbox {
  --border-color-checked: $color_white !important;
}
app-user-form ion-datetime {
  color: white !important;
  border-bottom: 1px solid white !important;
}

app-recapmenu {
  background-color: #2cb6b9 !important;
}
app-recapmenu .back-button {
  display: none !important;
}
app-recapmenu .content {
  background-color: #2cb6b9 !important;
}
app-recapmenu .content .fixed-content {
  background-color: #2cb6b9 !important;
}
app-recapmenu .content .scroll-content {
  background-color: #2cb6b9;
  margin-bottom: 90px;
}
app-recapmenu .item-container {
  background-color: #2cb6b9 !important;
}
app-recapmenu .item-container .item-article {
  background-color: #2cb6b9 !important;
}
app-recapmenu .title {
  line-height: 70px;
  font-family: "Montserrat";
}
app-recapmenu .item-md ion-avatar ion-img, app-recapmenu .item-md ion-avatar img {
  width: auto;
  height: 40px;
}
app-recapmenu ion-list {
  margin-top: 70px !important;
  display: block !important;
}
app-recapmenu ion-list ion-label {
  font-family: "Lato";
  font-size: 1.8rem;
}
app-recapmenu ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-recapmenu ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-payzen-register .scroll-content {
  background: #2cb6b9;
}
app-payzen-register p {
  font-size: 1.3rem !important;
  color: white;
  /*padding: 15px;*/
}
app-payzen-register ion-footer {
  padding-left: 5px !important;
  padding-right: 5px !important;
  background: #2cb6b9;
}
app-payzen-register ion-footer ion-button.halfwidth:first-of-type {
  margin-right: 8px !important;
  border: 0;
}
app-payzen-register ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
  --padding-start: 20px;
}
app-payzen-register ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-payzen-embeded .toolbar-title:after {
  font-family: "FontAwesome";
  color: white;
}
app-payzen-embeded #myPaymentForm .kr-do-register .kr-checkbox-label {
  color: white;
}
app-payzen-embeded #myPaymentForm .kr-checkbox-label {
  font-family: "Montserrat" !important;
}
app-payzen-embeded #myPaymentForm .kr-embedded.kr-help-button-inner-field .kr-payment-button,
app-payzen-embeded #myPaymentForm .kr-embedded.kr-help-button-inner-field .kr-form-error {
  font-family: "Lato" !important;
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-checkbox-control-indicator {
  background-color: #2cb6b9;
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-field-control input:checked ~ .kr-checkbox-control-indicator,
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
  background-color: #2cb6b9;
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-checkbox-control-indicator {
  border: 1px solid white;
}
app-payzen-embeded #myPaymentForm {
  padding: 1rem;
  text-align: center;
  box-sizing: border-box;
  min-width: 100%;
  width: 100vw !important;
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  app-payzen-embeded #myPaymentForm {
    min-width: 300px !important;
    width: 300px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  app-payzen-embeded #myPaymentForm {
    min-width: 300px !important;
    width: 300px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
app-payzen-embeded #myPaymentForm .kr-embedded {
  margin-left: auto;
  margin-right: auto;
  width: 90% !important;
}
app-payzen-embeded #myPaymentForm .kr-do-register .kr-checkbox-label {
  /*color: $color_white;*/
  font-size: 1.8rem;
  line-height: 36px;
  padding-left: 10px;
}
app-payzen-embeded #myPaymentForm .kr-checkbox-label {
  /*font-family: $main-font !important;*/
}
app-payzen-embeded #myPaymentForm .kr-do-register .kr-checkbox-layout {
  display: inline-flex;
}
app-payzen-embeded #myPaymentForm .kr-embedded.kr-help-button-inner-field .kr-payment-button,
app-payzen-embeded #myPaymentForm .kr-embedded.kr-help-button-inner-field .kr-form-error {
  width: 100% !important;
  /*font-family: $second-font !important;*/
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-checkbox-control-indicator {
  /*background-color: $color_1;*/
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-field-control input:checked ~ .kr-checkbox-control-indicator,
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
  /*background-color: $color_1;*/
}
app-payzen-embeded #myPaymentForm .kr-embedded .kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper .kr-checkbox-control-indicator {
  /*border: 1px solid $color_white;*/
  border-radius: 10px;
}
app-payzen-embeded #myPaymentForm button {
  /*@extend .button_1;*/
  line-height: 26px;
  height: 48px !important;
  width: 100% !important;
}
app-payzen-embeded #myPaymentForm button span {
  font-size: 1.1rem !important;
}
app-payzen-embeded .kr-embedded {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 90% !important;
}
app-payzen-embeded ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-payzen-embeded ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-payzen {
  background: white !important;
}
app-payzen iframe {
  border: 0;
  background: white !important;
}
app-payzen body#lyra {
  background: white !important;
}
app-payzen body#lyra #blocksSection {
  background: white !important;
}
app-payzen #blocksSection {
  background: white !important;
}
app-payzen #lyra {
  background: white !important;
}
app-payzen #rightBlock, app-payzen .color2, app-payzen #paymentResult .message, app-payzen #forwardMsg .message {
  background-color: white !important;
}
app-payzen #blockScreenBackground {
  background-color: white !important;
}
app-payzen h2.titleContainer {
  font-size: 17px !important;
}
app-payzen ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-payzen ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-cross-sellings .grid-cell-container {
  margin-top: 20px;
  background: transparent !important;
}
app-cross-sellings ion-title {
  color: white !important;
  font-family: "Montserrat" !important;
}
app-cross-sellings ion-title .toolbar-title {
  margin-top: 10px !important;
  text-align: center;
}
app-cross-sellings ion-title .toolbar-title .subtitle {
  opacity: 0.7;
  font-size: 1.5rem;
  text-align: center;
}
app-cross-sellings .content {
  background-color: #2cb6b9 !important;
}
app-cross-sellings .content.article-page-layout-line .scroll-content {
  background: #2cb6b9 !important;
}
.ios page-options .content .scroll-content {
  margin-top: 65px !important;
}

app-cross-sellings .content.article-page-layout-line .scroll-content .checkbox {
  height: 25% !important;
  top: 24% !important;
  left: 10px !important;
  z-index: 1;
  float: left;
}
app-cross-sellings .content.article-page-layout-line .scroll-content .checkbox:before {
  color: #2cb6b9;
  font-size: 30px;
}
app-cross-sellings .content.article-page-layout-line .scroll-content p {
  color: #2cb6b9;
  width: 75%;
  line-height: 1.6rem;
  padding-left: 40px;
}
app-cross-sellings .content.article-page-layout-grid .scroll-content {
  background: #2cb6b9 !important;
}
.ios page-options .content .scroll-content {
  margin-top: 65px !important;
}

app-cross-sellings .content.article-page-layout-grid .scroll-content .checkbox {
  height: 25% !important;
  top: 38% !important;
  left: 7px !important;
  z-index: 1;
  float: left;
  color: #2cb6b9;
}
app-cross-sellings .content.article-page-layout-grid .scroll-content .checkbox:before {
  color: #2cb6b9;
  font-size: 30px;
}
app-cross-sellings .content.article-page-layout-grid .scroll-content p {
  color: white;
  width: 100%;
  line-height: 1.6rem;
}
app-cross-sellings ion-footer {
  background: #2cb6b9;
  height: 70px;
  line-height: 70px;
}
app-cross-sellings ion-footer button {
  font-size: 2.3rem;
}
app-cross-sellings ion-footer button {
  font-size: 2rem !important;
}
app-cross-sellings ion-footer .button_color_2 {
  margin-left: 10px !important;
}
app-cross-sellings ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-cross-sellings ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-code-scan p, app-code-scan li, app-code-scan hr, app-code-scan div, app-code-scan input {
  margin: auto;
  font-family: "Montserrat";
  color: white;
  font-size: 2.2rem;
  font-weight: 400;
}
app-code-scan .scan-overlay {
  background: #2cb6b9 !important;
  width: 100%;
  height: 100%;
  text-align: center;
}
app-code-scan .scan-overlay p {
  font-size: 1.4rem !important;
}
app-code-scan .digicode ion-input input {
  background: #2cb6b9 !important;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 1.8rem;
  color: white !important;
  width: 200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}
app-code-scan .digicode ion-input input::placeholder {
  color: white;
  text-align: center;
  font-size: 1.5rem;
}
app-code-scan .scroll-content {
  background-color: transparent !important;
}
app-code-scan ion-content {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/background_scan.png") no-repeat center 200px !important;
  color: white;
}
app-code-scan ion-content.content-md, app-code-scan ion-content.card-md {
  background-color: transparent !important;
}
app-code-scan ion-footer {
  background: #2cb6b9 !important;
}
app-code-scan ion-footer button, app-code-scan ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-code-scan ion-content {
  background-color: transparent !important;
  --background: transparent !important;
}
app-code-scan ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-scan .ios-shortcuts div button {
  background: #2cb6b9;
  color: white;
}
app-scan .ios-shortcuts div button.selected {
  background: white;
  color: black;
}
app-scan ion-content {
  background-color: transparent !important;
  --background: transparent !important;
}
app-scan ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-code-type .scroll-content {
  margin-bottom: 100px;
}
app-code-type p, app-code-type li, app-code-type hr, app-code-type div, app-code-type input {
  /*margin: auto;*/
  font-family: "Montserrat";
  color: white;
  font-size: 1.7rem;
  font-weight: 500;
  color: white !important;
}
app-code-type .digicode {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
app-code-type .digicode ion-input input {
  background: #2cb6b9 !important;
  border: 1px solid white;
  border-radius: 5px;
  height: 45px;
  line-height: 40px;
  font-size: 2rem;
  color: white !important;
  width: 200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}
app-code-type .digicode ion-input input::placeholder {
  color: white;
  text-align: center;
  font-size: 3rem;
}
app-code-type ion-footer {
  background: #2cb6b9;
}
app-code-type ion-footer button, app-code-type ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-code-type ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-code-type ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-nickname p, app-nickname li, app-nickname hr, app-nickname div, app-nickname input {
  margin: auto;
  font-family: "Montserrat";
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
}
app-nickname p {
  padding-top: 20px;
}
app-nickname .digicode {
  text-align: center;
}
app-nickname .pseudo ion-input input {
  background: #2cb6b9 !important;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 2.5rem;
  color: white !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}
app-nickname .pseudo ion-input input::placeholder {
  color: white;
  text-align: center;
  font-size: 2.5rem;
}
app-nickname ion-content {
  color: white;
  background: #2cb6b9 !important;
}
app-nickname ion-footer {
  background: #2cb6b9;
}
app-nickname ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-nickname ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-pick-up .scroll-content {
  background: #2cb6b9;
}
app-pick-up .grid {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
app-pick-up .grid .button_icon_0 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_0.png") center center no-repeat;
  background-size: contain;
}
app-pick-up .grid .button_icon_1 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_1.png") center center no-repeat;
  background-size: contain;
}
app-pick-up .grid .button_icon_2 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_2.png") center center no-repeat;
  background-size: contain;
}
app-pick-up .grid .button_icon_3 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_3.png") center center no-repeat;
  background-size: contain;
}
app-pick-up .grid .button_icon_4 ion-icon {
  background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_4.png") center center no-repeat;
  background-size: contain;
}
app-pick-up .grid .button_icon_5 ion-icon {
  /*Comment by sajid*/
  /*background: transparent url("../../../assets/images/Theme_Heyou/Common/pick_5.png") center center no-repeat;*/
  background-size: contain;
}
@media screen and (min-width: 768px) and (max-width: 1999px) {
  app-pick-up .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  app-pick-up .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (min-width: 1024px) {
  app-pick-up .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) and (max-width: 1999px) {
  app-pick-up .grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1200px) {
  app-pick-up .grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}
app-pick-up .grid .button_icon_0, app-pick-up .grid .button_icon_1, app-pick-up .grid .button_icon_2, app-pick-up .grid .button_icon_3, app-pick-up .grid .button_icon_4, app-pick-up .grid .button_icon_5 {
  color: white;
  font-family: "Lato";
}
app-pick-up ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-pick-up ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-pick-up-place {
  text-align: center;
}
app-pick-up-place .scroll-content {
  background: #2cb6b9;
}
app-pick-up-place ion-footer {
  background: #2cb6b9 !important;
}
app-pick-up-place ion-footer button, app-pick-up-place ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-pick-up-place ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-pick-up-place ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-pick-up-hour .scroll-content, app-pick-up-hour ion-footer {
  background: #2cb6b9;
}
app-pick-up-hour hr.separator {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0.3), white, rgba(255, 255, 0, 0.3));
}
app-pick-up-hour hr.separator_1 {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 0, 0));
}
app-pick-up-hour hr.separator_2 {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 0, 0));
}
app-pick-up-hour hr.separator_day {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), white, rgba(255, 255, 0, 0));
}
app-pick-up-hour hr.separator_outadated {
  background-color: #fcea10;
}
app-pick-up-hour .text-hour {
  color: white;
  background: #2cb6b9 !important;
}
app-pick-up-hour .slot-text {
  background-color: #00fff0;
  padding: 80px;
  font-family: MontserratBold;
  font-size: 1.4rem;
  color: white;
  text-align: center;
  opacity: 1;
  text-transform: uppercase;
}
app-pick-up-hour .selected {
  background-color: #fcea10;
  color: #1c8f92;
  opacity: 1;
}
app-pick-up-hour .unavailable {
  background-color: #de2121;
  opacity: 0.7;
}
app-pick-up-hour .disabled {
  background-color: #6f7574;
  opacity: 0.7;
}
app-pick-up-hour .outdated {
  opacity: 0.7;
}
app-pick-up-hour ion-slides ion-slide {
  color: white;
  font-family: "Montserrat";
  background: #2cb6b9;
}
app-pick-up-hour .swiper-container {
  height: 40px !important;
  background: #2cb6b9;
}
app-pick-up-hour .swiper-slide {
  font-size: 2.2rem;
  cursor: pointer;
}
app-pick-up-hour .swiper-button-prev ion-icon, app-pick-up-hour .swiper-button-next ion-icon {
  font-size: 3.2rem !important;
  opacity: 0.8;
}
app-pick-up-hour .swiper-button-prev ion-icon:before, app-pick-up-hour .swiper-button-next ion-icon:before {
  color: white;
}
app-pick-up-hour ion-footer {
  background: #2cb6b9 !important;
}
app-pick-up-hour ion-footer button, app-pick-up-hour ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-pick-up-hour ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-pick-up-hour ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-password-lost ion-content, app-password-lost ion-list, app-password-lost ion-item, app-password-lost ion-label, app-password-change ion-content, app-password-change ion-list, app-password-change ion-item, app-password-change ion-label {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-password-lost ion-footer, app-password-change ion-footer {
  /*background: transparent !important;*/
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-password-lost ion-header ion-toolbar, app-password-change ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-password-lost .form-password, app-password-change .form-password {
  margin-left: auto !important;
  margin-right: auto !important;
}
app-password-lost ion-icon, app-password-lost ion-label, app-password-change ion-icon, app-password-change ion-label {
  color: white !important;
}

app-reservation .scroll-content, app-reservation ion-footer {
  background: #2cb6b9;
  font-size: 2rem;
  line-height: 2.5rem;
  color: white;
}
app-reservation hr.separator {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0.3), white, rgba(255, 255, 0, 0.3));
}
app-reservation hr.separator_1 {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 0, 0));
}
app-reservation hr.separator_2 {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 0, 0));
}
app-reservation hr.separator_day {
  background-image: linear-gradient(to right, rgba(255, 255, 0, 0), white, rgba(255, 255, 0, 0));
}
app-reservation hr.separator_outadated {
  background-color: #fcea10;
}
app-reservation .text-hour {
  color: white;
  background-color: #1c8f92;
  padding: 5px;
  font-size: 1.6rem !important;
}
app-reservation .slot-text {
  background-color: #00fff0;
  font-family: MontserratBold;
  padding: 75px;
  font-size: 1.4rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
app-reservation h2 {
  padding-top: 8px !important;
}
app-reservation h3 {
  margin-top: 12px !important;
}
app-reservation .selected {
  background-color: #fcea10;
  color: #1c8f92;
}
app-reservation .unavailable {
  background-color: #de2121;
}
app-reservation .disabled {
  background-color: #6f7574;
}
app-reservation .outdated {
  opacity: 0.5;
}
app-reservation ion-slides ion-slide {
  color: white;
  font-family: "Montserrat";
  background: #2cb6b9;
}
app-reservation .swiper-container {
  background: #2cb6b9;
}
app-reservation .swiper-slide {
  font-size: 1.6rem !important;
  cursor: pointer;
}
app-reservation .calendar {
  font-size: 1.6rem !important;
}
app-reservation .calendar .monthview-datetable {
  background-color: white !important;
}
app-reservation .calendar .monthview-datetable th, app-reservation .calendar .monthview-datetable td:not(.monthview-disabled) {
  color: #2cb6b9 !important;
}
app-reservation .calendar .monthview-datetable th small {
  font-size: 65% !important;
}
app-reservation .monthview-selected {
  color: grey !important;
  background: #d3dcf0 !important;
}
app-reservation .back_left, app-reservation .back_right {
  color: white !important;
}
app-reservation .back_left span, app-reservation .back_right span {
  font-size: 2.2rem;
}
app-reservation .back_left ion-icon, app-reservation .back_right ion-icon {
  font-size: 1.6rem;
  color: white !important;
}
app-reservation .back_left ion-icon:before, app-reservation .back_right ion-icon:before {
  color: white;
}
app-reservation .title {
  font-family: "Montserrat";
}
app-reservation ion-footer {
  background: #2cb6b9 !important;
}
app-reservation ion-footer button, app-reservation ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-reservation ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-reservation ion-content h2 {
  color: white !important;
}
app-reservation ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-reservation ion-header ion-toolbar ion-button {
  margin-top: unset !important;
}
app-reservation ion-header ion-toolbar ion-icon {
  color: white !important;
  height: 20px !important;
  width: 20px !important;
}

app-cards-list ion-label {
  color: white;
}
app-cards-list .scroll-content {
  /*   background:$color_1 url('../../../assets/images/Theme_Heyou/Common/background_grid.png') no-repeat center bottom !important;
    background-size: cover !important; */
  margin-bottom: 0 !important;
  padding-left: 15px;
}
app-cards-list .content,
app-cards-list .content-md {
  background: transparent !important;
}
app-cards-list ion-item {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-cards-list ion-item div {
  color: white !important;
  font-family: Arial !important;
}
app-cards-list ion-item ion-label {
  margin: 0 !important;
  font-family: "Lato";
  padding-left: 15px;
}
app-cards-list .horizontal-margin ion-label {
  display: flex;
  flex-direction: column;
  width: 100%;
}
app-cards-list .button_close {
  width: 30px;
  display: flex;
  align-self: flex-end;
  margin-bottom: -32px;
}
app-cards-list .button_close button {
  background-color: transparent;
}
app-cards-list .divider {
  background-color: white !important;
}
app-cards-list ion-footer {
  background: transparent !important;
}
app-cards-list ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-cards-list ion-list, app-cards-list ion-item, app-cards-list ion-item-divider {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
  color: white !important;
  --ion-item-border-color: $color_white !important;
  /*border-bottom: 1px solid var(--ion-item-border-color, rgba(255, 255, 255)) !important;*/
}
app-cards-list ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-cards-list .title_bar {
  font-family: "Montserrat";
}

app-delivery-address .scroll-content {
  background: #2cb6b9;
}
app-delivery-address ion-footer {
  background: #2cb6b9 !important;
}
app-delivery-address ion-footer button, app-delivery-address ion-footer .button_color_2 {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fcea10 !important;
}
app-delivery-address ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-delivery-address ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

app-upsellings .grid-cell-container {
  background: transparent !important;
}
app-upsellings ion-title {
  color: white !important;
  font-family: "Montserrat" !important;
}
app-upsellings ion-title .toolbar-title {
  margin-top: 10px !important;
  margin-left: -22px;
}
app-upsellings ion-title .toolbar-title .subtitle {
  opacity: 0.7;
  font-size: 1.5rem;
}
app-upsellings .content.article-page-layout-line {
  background-color: #2cb6b9 !important;
}
app-upsellings .content.article-page-layout-line .scroll-content {
  background: #2cb6b9 !important;
}
.ios app-options .content .scroll-content {
  margin-top: 65px !important;
}

app-upsellings .content.article-page-layout-line .scroll-content .checkbox {
  height: 25% !important;
  top: 24% !important;
  left: 10px !important;
  z-index: 1;
  float: left;
  /*&:before {
      color: $color_1;
      font-size: 30px;
  }*/
}
app-upsellings .content.article-page-layout-line .scroll-content p {
  color: #2cb6b9;
  width: 72%;
  line-height: 1.6rem;
  padding-left: 40px;
}
app-upsellings .content.article-page-layout-line .scroll-content .container-cart {
  display: none;
}
app-upsellings .content.article-page-layout-line .scroll-content .grid-cell-container.single-element {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}
app-upsellings .content.article-page-layout-line .scroll-content .grid-cell-container.single-element grid-article-cell,
app-upsellings .content.article-page-layout-line .scroll-content .grid-cell-container.single-element app-grid-cell {
  width: 100%;
}
app-upsellings .content.article-page-layout-grid {
  background-color: #2cb6b9 !important;
}
app-upsellings .content.article-page-layout-grid .scroll-content {
  background: #2cb6b9 !important;
}
.ios app-options .content .scroll-content {
  margin-top: 65px !important;
}

app-upsellings .content.article-page-layout-grid .scroll-content .checkbox {
  height: 25% !important;
  top: 38% !important;
  left: 7px !important;
  z-index: 1;
  float: left;
  color: #2cb6b9;
  /*&:before {
      color: $color_1;
      font-size: 30px;
  }*/
}
app-upsellings .content.article-page-layout-grid .scroll-content p {
  color: white;
  width: 100%;
  line-height: 1.6rem;
}
app-upsellings .content.article-page-layout-grid .scroll-content .container-cart {
  display: none;
}
app-upsellings ion-footer {
  background: #2cb6b9;
  height: 70px;
  line-height: 70px;
}
app-upsellings ion-footer button {
  font-size: 2.3rem;
}
app-upsellings ion-footer .button_color_1 {
  min-width: 47%;
  margin-left: auto;
}
app-upsellings ion-footer .button_color_2 {
  min-width: 47%;
}
app-upsellings ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}
app-upsellings ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-upsellings grid-article-cell .checkbox, app-upsellings app-grid-cell .checkbox {
  color: #2cb6b9;
}

app-modal-command .margintop {
  margin-top: 20px;
}
app-modal-command .text {
  color: white;
  font-size: 2.5rem;
  font-family: "Montserrat";
}
app-modal-command .text div {
  width: 100%;
}
app-modal-command p {
  color: white !important;
  font-family: "Montserrat";
}
app-modal-command .title {
  font-size: 1.8rem !important;
  font-family: "Montserrat";
}
app-modal-command .back-button {
  color: white;
  margin-top: -20px !important;
}
app-modal-command .content-md, app-modal-command .card-md {
  /*comment by sajid*/
  /*background:$color_1 url('../../../assets/images/Theme_Heyou/Common/background_grid.png') repeat center bottom !important;*/
  background: #2cb6b9 url("../../../assets/images/Theme_Heyou/Common/background.png") repeat center bottom !important;
  background-size: contain !important;
}
app-modal-command ion-content {
  background-color: #2cb6b9 !important;
  --background: $color_1 !important;
}
app-modal-command ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

planning .planning-bloc {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}
planning .separator-bloc {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
  width: 100%;
}
planning .slot {
  margin: -2px 5px 1px 5px;
  border-radius: 10px;
  width: 96%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  planning .slot {
    width: 99%;
  }
}
planning .slot .slot-text {
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 99%;
  display: flex;
  text-transform: uppercase;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome/Regular/FontAwesome.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LatoLight";
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?v=1.0.0");
  src: url("../../assets/fonts/Lato/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Lato/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Lato/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Lato/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Lato/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Regular/quicksand-Regular.ttf?v=1.0.0") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandBold";
  src: url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/Bold/quicksand-Bold.ttf?v=1.0.0") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuicksandExraBold";
  src: url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Quicksand/ExtraBold/quicksand-ExtraBold.ttf?v=1.0.0") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champagne";
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Champagne/Regular/Champagne-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Champagne/Regular/Champagne-Regular.svg?v=1.0.0#ChampagneRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBold";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold");*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneBoldItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Bold_Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ChampagneItalic";
  /*Comment by sajid*/
  /*@include fontdef($FontPathChampagne, Champagne, $FontVersion, "Italic");*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Regular/Montserrat-Regular.svg?v=1.0.0#MontserratRegular") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?v=1.0.0");
  src: url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff2?v=1.0.0") format("woff2"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.woff?v=1.0.0") format("woff"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf?v=1.0.0") format("truetype"), url("../../assets/fonts/Montserrat/Bold/Montserrat-Bold.svg?v=1.0.0#MontserratBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
app-tabs ion-tabs ion-tab-bar {
  background: #2cb6b9;
}
app-tabs ion-tabs ion-tab-bar ion-tab-button {
  background: #2cb6b9;
  color: white;
  font-family: "Montserrat";
  -webkit-transform: scaleY(1.05);
  transform: scaleY(1.05);
  min-height: 5.6rem;
  font-weight: normal;
}
app-tabs ion-tabs ion-tab-bar ion-tab-button ion-icon {
  width: 40px !important;
  color: white;
  height: 32px !important;
  margin-top: 1px;
  min-width: 2.4rem;
  font-size: 2.4rem;
  transform-origin: 50% 150%;
}
app-tabs ion-tabs ion-tab-bar ion-tab-button ion-label {
  color: white;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.1px;
  -webkit-transform: scaleY(1.05);
  transform: scaleY(1.05);
}
app-tabs ion-tabs ion-tab-bar ion-tab-button img.placeholder {
  background-image: url("../../../assets/images/default_priceless_white.png");
}
app-tabs ion-tabs ion-tab-bar ion-tab-button img.placeholder.price {
  background-image: url("../../../assets/images/default_white.png");
}
app-tabs ion-tabs ion-tab-bar ion-tab-button ion-badge {
  font-size: 1.3rem !important;
  top: 10px !important;
  left: 40px !important;
}
app-tabs ion-tabs ion-tab-bar ion-tab-button.selected ion-label {
  color: #fcea10;
}
app-tabs ion-tabs ion-tab-bar ion-tab-button.selected ion-icon {
  color: #fcea10;
}
@media screen and (min-width: 1024px) {
  app-tabs ion-tabs .menu button {
    width: 30% !important;
  }
  app-tabs ion-tabs .container {
    width: 100%;
  }
  app-tabs ion-tabs .scroll-content {
    margin-top: 0px !important;
  }
}

app-order-detail ion-content {
  --background: $color_1 !important;
  background: #2cb6b9 !important;
}
app-order-detail .scroll-content {
  margin-bottom: 120px;
}
app-order-detail .scroll-content button {
  box-shadow: 0px 0px 0px 0px rgba(16, 128, 130, 0.26) !important;
}
app-order-detail .qrcode-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
}
app-order-detail .qrcode-section div {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}
app-order-detail .qrcode-section app-price ion-icon {
  margin-left: -5px !important;
  font-size: 12px;
}
app-order-detail .qrcode-section qrcode {
  margin-bottom: 5px !important;
}
app-order-detail ion-header ion-toolbar {
  border-bottom: 1px solid white !important;
}

@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile {
    /*   app-directory {
        .ios-shortcuts {
            div {
                justify-content: space-between !important;
            }
        }
        .grid-cell-container {
            padding-top: 20px;
            align-content: flex-start !important;
        }
    }
    app-directory,
    app-options,
    app-bundle,
    app-cross-sellings {
        .grid-cell-container {
            width: 100%;
            display: grid;
            grid-template-columns: 33% 33% 33%;
            grid-gap: 2px;
            margin-top: 15px;
            margin-bottom: 70px;
            margin-left: 10px;
            &.single-element {
                width: 100%;
                display: grid;
                grid-template-columns: 100%;
                grid-article-cell,
                grid-cell {
                    flex: 0 0 50% !important;
                    width: 50% !important;
                    max-width: 50% !important;
                }
            }
            grid-article-cell,
            grid-cell {
                flex: 0 0 90% !important;
                width: 95% !important;
                max-width: 95% !important;
                padding: 10px 5px 10px 5px !important;
                .container_max {
                    height: 150px !important;
                }
            }
            .design_grid .container_max img {
                max-height: 100%;
                margin-left: -17px;
                //      max-width: 100%;
            }
            .container_image {
                padding-bottom: 16px;
                img {
                    cursor: pointer;
                    max-height: 114% !important;
                }
                button.button_4.bt_cmd {
                    width: 96%;
                    bottom: 103px;
                    left: 2%;
                }
            }
            p {
                font-size: 1.5rem !important;
                line-height: 2rem !important;
                margin-top: 10px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            .layer-price {
                bottom: 41px;
                left: 8px;
                .euro {
                    // clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                    .unit {
                        //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                        price:after {
                            //   clip-path: polygon(0 0, 100% 0, 74% 100%, 26% 100%);
                        }
                    }
                    price:after {
                        //    clip-path: polygon(0 0, 100% 0, 77% 100%, 23% 100%);
                    }
                }
            }
            .unit-price {
                left: 8px !important;
                background-size: contain !important;
            }
            .info,
            .cart {
                display: flex;
                align-items: center;
                background: none;
                background-size: cover !important;
                cursor: pointer;
                position: absolute;
                opacity: 1 !important;
                background: #dadada;
                width: 30px;
                height: 30px;
                &:before {
                    font-family: Ionicons;
                    color: $color_white;
                    font-size: 30px;
                    position: relative;
                    border-radius: 50%;
                    line-height: 30px;
                    opacity: 1 !important;
                }
            }
            .info {
                width: 25px !important;
                height: 25px !important;
                left: 88% !important;
                top: 126px !important;
                opacity: 1 !important;
                background: $color_7;
                justify-content: center;
                @media screen and (min-width: $large-screen) {
                    // IPAD PROD
                    left: 92% !important;
                }
                &:before {
                    font-family: Ionicons;
                    content: "\f312";
                    color: $color_white;
                    font-size: 32px;
                    position: absolute;
                }
            }
            .cart {
                width: 25px !important;
                height: 25px !important;
                left: 88% !important;
                top: 139px !important;
                opacity: 1 !important;
                background: $color_7;
                justify-content: center;
                @media screen and (min-width: $large-screen) {
                    // IPAD PROD
                    left: 92% !important;
                }
                &:before {
                    font-size: 21px;
                    position: absolute;
                }
            }
            .checkbox {
                left: 56% !important;
                top: 84% !important;
                &:before {
                    font-size: 24px;
                }
            }
            .breaking_div {
                top: 7px;
                right: -37px;
                @media screen and (min-width: 1024px) and (max-width: 1025px) {
                    right: -80px;
                }
            }
            .addCart {
                top: 84% !important;
            }
        }
    } */
  }
  .device-type-mobile ion-app {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile ion-app.md {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile ion-page {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile ion-modal {
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
  }
  .device-type-mobile ion-alert ion-backdrop {
    background-color: transparent !important;
    border: 0px solid transparent !important;
  }
  .device-type-mobile ion-alert .alert-wrapper {
    width: 75vw !important;
    max-width: 75vw !important;
  }
  .device-type-mobile #leftMenu .scroll-content {
    margin-top: 0;
    background: white !important;
  }
  .device-type-mobile ion-title .toolbar-title .subtitle {
    width: 100%;
  }
  .device-type-mobile .tabbar {
    margin-left: auto !important;
    margin-right: auto !important;
    background: transparent !important;
    position: relative;
    margin-top: calc(100vh - 56px) !important;
  }
  .device-type-mobile .tabbar a.tab-button {
    height: 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
    -moz-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
    box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
  }
  .device-type-mobile .tabbar a.tab-button:hover, .device-type-mobile .tabbar a.tab-button:focus {
    opacity: 1 !important;
  }
  .device-type-mobile .tabbar .tab-button-icon {
    width: 28px !important;
    height: 30px !important;
    margin-top: 0;
  }
  .device-type-mobile .tabbar .tab-button-icon:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-icon.ion-md-restau:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-icon.ion-md-commande:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-text {
    font-size: 1.8rem !important;
    margin-top: -2px;
  }
  .device-type-mobile .tabbar ion-badge.tab-badge {
    height: auto !important;
    font-size: 15px !important;
    line-height: 7px;
    width: 38px;
  }
  .device-type-mobile .tabbar ion-badge.tab-badge:before {
    top: -2px;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] {
    background: white;
    background-size: cover;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] .tab-button-icon {
    transform: scale(1.05);
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-icon {
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-icon:before {
    color: #2cb6b9 !important;
    font-size: 33px !important;
    margin-top: 1px;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] .tab-button-text {
    font-size: 1.6rem !important;
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-badge.tab-badge {
    height: auto !important;
    font-size: 15px !important;
    line-height: 7px;
    width: 38px;
    border: 1px solid #2cb6b9 !important;
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-badge.tab-badge:before {
    top: -2px;
    color: #2cb6b9 !important;
  }
  .device-type-mobile app-home {
    background-size: cover !important;
  }
  .device-type-mobile app-home ion-header {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-home .flag {
    width: 40px !important;
  }
  .device-type-mobile app-home .menu {
    width: 40%;
    justify-content: flex-start !important;
  }
  .device-type-mobile app-home .menu button {
    width: 40% !important;
    margin-bottom: 30px !important;
  }
  .device-type-mobile app-home .scroll-content {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-home .scroll-content #logo {
    width: 454px;
    height: 178px;
  }
  .device-type-mobile app-home .container {
    width: 100%;
    margin-top: 50px;
  }
  .device-type-mobile app-home .container ion-row {
    height: 220px !important;
  }
  .device-type-mobile app-info ion-footer button:not(.qte_min, .qte_max) {
    width: 320px !important;
  }
  .device-type-mobile app-info ion-footer button.qte_max,
.device-type-mobile app-info ion-footer button.qte_min {
    width: 50px !important;
  }
  .device-type-mobile app-login {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-login .scroll-content {
    margin-top: 50px !important;
  }
  .device-type-mobile app-login .scroll-content .form-login {
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-login .scroll-content .form-login .text-input-md {
    width: 100% !important;
  }
  .device-type-mobile app-profile .scroll-content button {
    width: 320px !important;
  }
  .device-type-mobile app-password-lost .form-login {
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-password-lost .form-login .text-input-md {
    width: 100% !important;
  }
  .device-type-mobile app-stores-list ion-item {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-stores-list ion-footer button {
    width: 320px !important;
  }
  .device-type-mobile app-store-detail ion-footer button {
    width: 320px !important;
  }
  .device-type-mobile app-payzen-register button {
    width: 15% !important;
  }
  .device-type-mobile app-reservation ion-content .scroll-content {
    margin-bottom: 200px !important !important;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 {
    grid-template-columns: 80% 20%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 .article_qte {
    font-size: 2rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 .article_price {
    font-size: 2.2rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level1 {
    grid-template-columns: 80% 20%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level1 .article_price app-price {
    font-size: 1.6rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level2 {
    grid-template-columns: 80% 20%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level2 .article_price app-price {
    font-size: 1.6rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0_price {
    grid-template-columns: 85% 15%;
  }
  .device-type-mobile app-order-lines .order-line .divider_compose div {
    width: 15%;
  }
  .device-type-mobile app-order-lines .total {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .device-type-mobile app-order-lines .tva_titre,
.device-type-mobile app-order-lines .tva_detail {
    width: 42% !important;
    margin-left: 58%;
  }
  .device-type-mobile app-quote ion-footer button,
.device-type-mobile app-quote-review ion-footer button {
    width: 30% !important;
    background: #fcea10 !important;
  }
  .device-type-mobile app-directory .img_bar,
.device-type-mobile app-options .img_bar,
.device-type-mobile app-bundle .img_bar,
.device-type-mobile app-cross-sellings .img_bar,
.device-type-mobile app-upsellings .img_bar {
    justify-content: space-between !important;
  }
  .device-type-mobile app-directory .scroll-content,
.device-type-mobile app-options .scroll-content,
.device-type-mobile app-bundle .scroll-content,
.device-type-mobile app-cross-sellings .scroll-content,
.device-type-mobile app-upsellings .scroll-content {
    margin-bottom: 145px !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container,
.device-type-mobile app-options .scroll-content .grid-cell-container,
.device-type-mobile app-bundle .scroll-content .grid-cell-container,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    margin-top: 15px;
    margin-bottom: 70px;
    margin-left: 10px;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container.single-element,
.device-type-mobile app-options .scroll-content .grid-cell-container.single-element,
.device-type-mobile app-bundle .scroll-content .grid-cell-container.single-element,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container.single-element,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container.single-element {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container.single-element grid-article-cell,
.device-type-mobile app-directory .scroll-content .grid-cell-container.single-element app-grid-cell,
.device-type-mobile app-options .scroll-content .grid-cell-container.single-element grid-article-cell,
.device-type-mobile app-options .scroll-content .grid-cell-container.single-element app-grid-cell,
.device-type-mobile app-bundle .scroll-content .grid-cell-container.single-element grid-article-cell,
.device-type-mobile app-bundle .scroll-content .grid-cell-container.single-element app-grid-cell,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container.single-element grid-article-cell,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container.single-element app-grid-cell,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container.single-element grid-article-cell,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container.single-element app-grid-cell {
    flex: 0 0 50% !important;
    width: 50% !important;
    max-width: 50% !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container grid-article-cell,
.device-type-mobile app-directory .scroll-content .grid-cell-container app-grid-cell,
.device-type-mobile app-options .scroll-content .grid-cell-container grid-article-cell,
.device-type-mobile app-options .scroll-content .grid-cell-container app-grid-cell,
.device-type-mobile app-bundle .scroll-content .grid-cell-container grid-article-cell,
.device-type-mobile app-bundle .scroll-content .grid-cell-container app-grid-cell,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container grid-article-cell,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container app-grid-cell,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container grid-article-cell,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container app-grid-cell {
    flex: 0 0 90% !important;
    width: 95% !important;
    max-width: 95% !important;
    padding: 10px 5px 10px 5px !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container grid-article-cell .container_max,
.device-type-mobile app-directory .scroll-content .grid-cell-container app-grid-cell .container_max,
.device-type-mobile app-options .scroll-content .grid-cell-container grid-article-cell .container_max,
.device-type-mobile app-options .scroll-content .grid-cell-container app-grid-cell .container_max,
.device-type-mobile app-bundle .scroll-content .grid-cell-container grid-article-cell .container_max,
.device-type-mobile app-bundle .scroll-content .grid-cell-container app-grid-cell .container_max,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container grid-article-cell .container_max,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container app-grid-cell .container_max,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container grid-article-cell .container_max,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container app-grid-cell .container_max {
    height: 150px !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .design_grid .container_max img,
.device-type-mobile app-options .scroll-content .grid-cell-container .design_grid .container_max img,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .design_grid .container_max img,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .design_grid .container_max img,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .design_grid .container_max img {
    max-height: 100%;
    margin-left: -17px;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .container_image,
.device-type-mobile app-options .scroll-content .grid-cell-container .container_image,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .container_image,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .container_image,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .container_image {
    padding-bottom: 16px;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .container_image img,
.device-type-mobile app-options .scroll-content .grid-cell-container .container_image img,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .container_image img,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .container_image img,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .container_image img {
    cursor: pointer;
    max-height: 114% !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .container_image button.button_4.bt_cmd,
.device-type-mobile app-options .scroll-content .grid-cell-container .container_image button.button_4.bt_cmd,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .container_image button.button_4.bt_cmd,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .container_image button.button_4.bt_cmd,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .container_image button.button_4.bt_cmd {
    width: 96%;
    bottom: 103px;
    left: 2%;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container p,
.device-type-mobile app-options .scroll-content .grid-cell-container p,
.device-type-mobile app-bundle .scroll-content .grid-cell-container p,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container p,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container p {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .layer-price,
.device-type-mobile app-options .scroll-content .grid-cell-container .layer-price,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .layer-price,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .layer-price,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .layer-price {
    bottom: 41px;
    left: 8px;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .unit-price,
.device-type-mobile app-options .scroll-content .grid-cell-container .unit-price,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .unit-price,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .unit-price,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .unit-price {
    left: 8px !important;
    background-size: contain !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .info,
.device-type-mobile app-directory .scroll-content .grid-cell-container .cart,
.device-type-mobile app-options .scroll-content .grid-cell-container .info,
.device-type-mobile app-options .scroll-content .grid-cell-container .cart,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .info,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .cart,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .info,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .cart,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .info,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .cart {
    display: flex;
    align-items: center;
    background: none;
    background-size: cover !important;
    cursor: pointer;
    position: absolute;
    opacity: 1 !important;
    background: #dadada;
    width: 30px;
    height: 30px;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-directory .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-options .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-options .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .cart:before {
    font-family: Ionicons;
    color: white;
    font-size: 30px;
    position: relative;
    border-radius: 50%;
    line-height: 30px;
    opacity: 1 !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .info,
.device-type-mobile app-options .scroll-content .grid-cell-container .info,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .info,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .info,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .info {
    width: 25px !important;
    height: 25px !important;
    left: 88% !important;
    top: 126px !important;
    opacity: 1 !important;
    background: #92fdff;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) and (min-width: 1366px) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .info,
.device-type-mobile app-options .scroll-content .grid-cell-container .info,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .info,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .info,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .info {
    left: 92% !important;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-options .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .info:before,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .info:before {
    font-family: Ionicons;
    content: "";
    color: white;
    font-size: 32px;
    position: absolute;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .cart,
.device-type-mobile app-options .scroll-content .grid-cell-container .cart,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .cart,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .cart,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .cart {
    width: 25px !important;
    height: 25px !important;
    left: 88% !important;
    top: 139px !important;
    opacity: 1 !important;
    background: #92fdff;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) and (min-width: 1366px) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .cart,
.device-type-mobile app-options .scroll-content .grid-cell-container .cart,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .cart,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .cart,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .cart {
    left: 92% !important;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-options .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .cart:before,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .cart:before {
    font-size: 21px;
    position: absolute;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .checkbox,
.device-type-mobile app-options .scroll-content .grid-cell-container .checkbox,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .checkbox,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .checkbox,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .checkbox {
    left: 56% !important;
    top: 84% !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container .checkbox:before,
.device-type-mobile app-options .scroll-content .grid-cell-container .checkbox:before,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .checkbox:before,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .checkbox:before,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .checkbox:before {
    font-size: 24px;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-options .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .breaking_div {
    top: 7px;
    right: -37px;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) and (min-width: 1024px) and (max-width: 1025px) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-options .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .breaking_div,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .breaking_div {
    right: -80px;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .device-type-mobile app-directory .scroll-content .grid-cell-container .addCart,
.device-type-mobile app-options .scroll-content .grid-cell-container .addCart,
.device-type-mobile app-bundle .scroll-content .grid-cell-container .addCart,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container .addCart,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container .addCart {
    top: 84% !important;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .device-type-mobile ion-app {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile ion-app.md {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile #leftMenu .menu-left-android .scroll-content {
    overflow-y: hidden !important;
  }
  .device-type-mobile .ion-page .bar-button-menutoggle {
    padding: 0 2px !important;
  }
  .device-type-mobile .ion-page .mainfont-button:not(.qte_min, .qte_max), .device-type-mobile .ion-page ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):first-child, ion-alert .alert-wrapper .alert-button-group .device-type-mobile .ion-page .alert-button:not(.qte_min, .qte_max):first-child, .device-type-mobile .ion-page ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):last-child, ion-alert .alert-wrapper .alert-button-group .device-type-mobile .ion-page .alert-button:not(.qte_min, .qte_max):last-child, .device-type-mobile .ion-page .button_1:not(.qte_min, .qte_max),
.device-type-mobile .ion-page .button_2:not(.qte_min, .qte_max),
.device-type-mobile .ion-page .button_3:not(.qte_min, .qte_max),
.device-type-mobile .ion-page .button_4:not(.qte_min, .qte_max), .device-type-mobile .ion-page .button_color_1:not(.qte_min, .qte_max),
.device-type-mobile .ion-page .button_color_2:not(.qte_min, .qte_max),
.device-type-mobile .ion-page .button_color_3:not(.qte_min, .qte_max), .device-type-mobile .ion-page ion-footer.buttons-bar button:not(.qte_min, .qte_max), ion-footer.buttons-bar .device-type-mobile .ion-page button:not(.qte_min, .qte_max), .device-type-mobile .ion-page app-payzen-embeded #myPaymentForm button:not(.qte_min, .qte_max), app-payzen-embeded #myPaymentForm .device-type-mobile .ion-page button:not(.qte_min, .qte_max) {
    height: 40px;
    width: 320px;
  }
  .device-type-mobile .ion-page .mainfont-button:not(.qte_min, .qte_max) .button-inner, .device-type-mobile .ion-page ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):first-child .button-inner, ion-alert .alert-wrapper .alert-button-group .device-type-mobile .ion-page .alert-button:not(.qte_min, .qte_max):first-child .button-inner, .device-type-mobile .ion-page ion-alert .alert-wrapper .alert-button-group .alert-button:not(.qte_min, .qte_max):last-child .button-inner, ion-alert .alert-wrapper .alert-button-group .device-type-mobile .ion-page .alert-button:not(.qte_min, .qte_max):last-child .button-inner, .device-type-mobile .ion-page .button_1:not(.qte_min, .qte_max) .button-inner,
.device-type-mobile .ion-page .button_2:not(.qte_min, .qte_max) .button-inner,
.device-type-mobile .ion-page .button_3:not(.qte_min, .qte_max) .button-inner,
.device-type-mobile .ion-page .button_4:not(.qte_min, .qte_max) .button-inner, .device-type-mobile .ion-page .button_color_1:not(.qte_min, .qte_max) .button-inner,
.device-type-mobile .ion-page .button_color_2:not(.qte_min, .qte_max) .button-inner,
.device-type-mobile .ion-page .button_color_3:not(.qte_min, .qte_max) .button-inner, .device-type-mobile .ion-page ion-footer.buttons-bar button:not(.qte_min, .qte_max) .button-inner, ion-footer.buttons-bar .device-type-mobile .ion-page button:not(.qte_min, .qte_max) .button-inner, .device-type-mobile .ion-page app-payzen-embeded #myPaymentForm button:not(.qte_min, .qte_max) .button-inner, app-payzen-embeded #myPaymentForm .device-type-mobile .ion-page button:not(.qte_min, .qte_max) .button-inner {
    font-size: 2.5rem !important;
  }
  .device-type-mobile .ion-page ion-header,
.device-type-mobile .ion-page ion-content {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative !important;
  }
  .device-type-mobile .ion-page ion-header .back-button-icon-md,
.device-type-mobile .ion-page ion-content .back-button-icon-md {
    font-size: 4rem;
    font-weight: normal;
  }
  .device-type-mobile .ion-page ion-header .container,
.device-type-mobile .ion-page ion-content .container {
    width: 1024px !important;
    height: 100vh !important;
    align-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile .ion-page ion-header .scroll-content,
.device-type-mobile .ion-page ion-content .scroll-content {
    width: 1024px !important;
    max-width: 1024px !important;
    align-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
    background: transparent;
  }
  .device-type-mobile .ion-page ion-header ion-toolbar ion-title,
.device-type-mobile .ion-page ion-content ion-toolbar ion-title {
    text-align: center !important;
  }
  .device-type-mobile .ion-page ion-header ion-toolbar ion-title .toolbar-content,
.device-type-mobile .ion-page ion-content ion-toolbar ion-title .toolbar-content {
    text-align: center !important;
    width: 100% !important;
  }
  .device-type-mobile .ion-page ion-header ion-toolbar ion-title .toolbar-content .toolbar-title-md,
.device-type-mobile .ion-page ion-header ion-toolbar ion-title .toolbar-content .toolbar-title,
.device-type-mobile .ion-page ion-content ion-toolbar ion-title .toolbar-content .toolbar-title-md,
.device-type-mobile .ion-page ion-content ion-toolbar ion-title .toolbar-content .toolbar-title {
    font-size: 2.8rem !important;
    text-align: center !important;
    width: 100% !important;
  }
  .device-type-mobile ion-title .toolbar-title .subtitle {
    width: 100%;
  }
  .device-type-mobile .tabbar {
    width: 1024px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: transparent !important;
    position: relative;
    margin-top: calc(100vh - 56px) !important;
  }
  .device-type-mobile .tabbar a.tab-button {
    height: 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
    -moz-box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
    box-shadow: 2px 2px 3px 1px rgba(25, 89, 153, 0.5) !important;
    background: #2cb6b9;
    margin-right: 5px;
  }
  .device-type-mobile .tabbar a.tab-button:hover, .device-type-mobile .tabbar a.tab-button:focus {
    opacity: 1 !important;
  }
  .device-type-mobile .tabbar .tab-button-icon {
    width: 28px !important;
    height: 30px !important;
    margin-top: 0;
  }
  .device-type-mobile .tabbar .tab-button-icon:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-icon.ion-md-restau:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-icon.ion-md-commande:before {
    font-size: 32px !important;
  }
  .device-type-mobile .tabbar .tab-button-text {
    font-size: 1.8rem !important;
    margin-top: -2px;
  }
  .device-type-mobile .tabbar ion-badge.tab-badge {
    height: auto !important;
    font-size: 15px !important;
    line-height: 7px;
    width: 38px;
  }
  .device-type-mobile .tabbar ion-badge.tab-badge:before {
    top: -2px;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] {
    background: white;
    background-size: cover;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] .tab-button-icon {
    transform: scale(1.05);
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-icon {
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-icon:before {
    color: #2cb6b9 !important;
    font-size: 33px !important;
    margin-top: 1px;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] .tab-button-text {
    font-size: 1.6rem !important;
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-badge.tab-badge {
    height: auto !important;
    font-size: 15px !important;
    line-height: 7px;
    width: 38px;
    border: 1px solid #2cb6b9 !important;
    color: #2cb6b9 !important;
  }
  .device-type-mobile .tabbar .tab-button[aria-selected=true] ion-badge.tab-badge:before {
    top: -2px;
    color: #2cb6b9 !important;
  }
  .device-type-mobile ion-modal {
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
  }
  .device-type-mobile ion-alert .alert-wrapper {
    margin-top: 40px;
    min-height: 36vh !important;
    width: 40vw !important;
    max-width: 40vw !important;
    transform: scale(1) !important;
    box-shadow: 5px 5px 10px -10px #656565 !important;
    border-radius: 13px !important;
  }
  .device-type-mobile ion-alert .alert-wrapper .alert-button-group {
    width: 100%;
    margin-bottom: 90px;
  }
  .device-type-mobile ion-alert .alert-wrapper .alert-button-group .alert-button {
    padding: 4px;
  }
  .device-type-mobile ion-alert .alert-wrapper .alert-button-group .alert-button .button-inner {
    font-size: 2rem !important;
  }
  .device-type-mobile ion-alert .alert-wrapper .alert-title {
    margin-top: 50px;
    font-size: 2.5rem !important;
    line-height: 3.5rem;
  }
  .device-type-mobile ion-alert .alert-wrapper .alert-message {
    font-size: 2.2rem !important;
    line-height: 2.5rem;
  }
  .device-type-mobile ion-alert.alert_majeur .alert-title {
    margin-bottom: 30px;
  }
  .device-type-mobile ion-alert.alert_majeur .alert-message {
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 50px;
    font-family: "Lato";
    margin-top: -30px;
  }
  .device-type-mobile ion-alert.alert_majeur .alert-button {
    width: 30vw !important;
    margin: 50px 0 0 0 !important;
  }
  .device-type-mobile app-home ion-header {
    height: 1px;
    min-height: 1px !important;
  }
  .device-type-mobile app-home ion-header .bar_android {
    margin-top: 4px;
  }
  .device-type-mobile app-home ion-header .flag {
    width: 40px !important;
  }
  .device-type-mobile app-home #logo {
    margin-top: 60px;
  }
  .device-type-mobile app-home .tabbar {
    background: transparent !important;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    background: #2cb6b9 !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content .container {
    margin-top: 0 !important;
    align-content: center !important;
    height: 90vh;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content .container .container_logo {
    position: absolute;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content .container div.menu {
    width: 90% !important;
    display: flex !important;
    margin-top: 100px !important;
    overflow-y: auto;
    align-items: flex-start;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content .container div.menu button {
    width: 280px !important;
  }
  .device-type-mobile app-home.ion-page ion-content .scroll-content .container div.menu button .button-inner {
    display: flex;
    font-size: 2rem !important;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .device-type-mobile app-home .tabbar {
    background: transparent !important;
  }
  .device-type-mobile app-login {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-login .scroll-content {
    margin-top: 50px !important;
  }
  .device-type-mobile app-login .scroll-content .form-login {
    width: 30%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-login .scroll-content .form-login .text-input-md {
    width: 100% !important;
  }
  .device-type-mobile app-login .scroll-content .item-md.item-block .item-inner {
    padding-right: 0;
  }
  .device-type-mobile app-password-lost .form-password,
.device-type-mobile app-password-change .form-password {
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-password-lost .form-password .text-input-md,
.device-type-mobile app-password-change .form-password .text-input-md {
    width: 100% !important;
  }
  .device-type-mobile app-user-form {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-user-form .scroll-content {
    margin-bottom: 250px !important;
  }
  .device-type-mobile app-user-form .form-register {
    width: 50% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-user-form .button_color_1.modif .button-inner {
    font-size: 1.4rem !important;
  }
  .device-type-mobile app-stores-list {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-stores-list .leaflet-container {
    height: 50vh !important;
  }
  .device-type-mobile app-store-detail {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-store-detail ion-footer button {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-orders-list {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-orders-list .scroll-content {
    margin-bottom: 120px !important;
  }
  .device-type-mobile app-order-detail {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-order-detail .scroll-content {
    margin-bottom: 120px !important;
  }
  .device-type-mobile app-info {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-info .ion-grid {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-info .scroll-content {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-info .scroll-content .container_price {
    background: #2cb6b9 !important;
    margin-top: -50px;
  }
  .device-type-mobile app-info .scroll-content .container_price .price_article {
    text-align: right !important;
    background: #2cb6b9 !important;
    color: white !important;
    width: 50% !important;
  }
  .device-type-mobile app-info .scroll-content .container_price .price_article .price {
    color: white;
  }
  .device-type-mobile app-info .scroll-content .container_text {
    height: calc(100vh - 460px) !important;
  }
  .device-type-mobile app-info .scroll-content .title {
    color: white;
  }
  .device-type-mobile app-info .scroll-content .description {
    color: white;
    text-align: center !important;
  }
  .device-type-mobile app-info ion-footer .controls {
    width: 300px;
  }
  .device-type-mobile app-info ion-footer .controls button {
    bottom: 0px !important;
  }
  .device-type-mobile app-info ion-footer button:not(.qte_min, .qte_max) {
    bottom: 20px;
  }
  .device-type-mobile app-info ion-footer button.bt_cmd .button-inner div {
    font-size: 1.6rem !important;
  }
  .device-type-mobile app-options ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-cross-sellings ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-upsellings ion-header ion-toolbar .toolbar-content {
    margin-right: 8%;
  }
  .device-type-mobile app-options .scroll-content,
.device-type-mobile app-cross-sellings .scroll-content,
.device-type-mobile app-upsellings .scroll-content {
    margin-bottom: 150px !important;
  }
  .device-type-mobile app-options ion-footer button,
.device-type-mobile app-cross-sellings ion-footer button,
.device-type-mobile app-upsellings ion-footer button {
    width: 250px;
    max-width: 250px;
  }
  .device-type-mobile app-recapmenu {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-recapmenu .scroll-content {
    margin-bottom: 150px !important;
  }
  .device-type-mobile app-recapmenu ion-footer .button_color_2 {
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 33% !important;
    width: 33% !important;
  }
  .device-type-mobile app-validation-order {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-validation-order ion-list,
.device-type-mobile app-validation-order .cgu-item {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .device-type-mobile app-validation-order ion-footer button {
    width: 320px !important;
  }
  .device-type-mobile app-validation-order ion-footer button span {
    font-size: 2rem !important;
    font-weight: 700 !important;
    letter-spacing: -0.1px !important;
  }
  .device-type-mobile app-payzen {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-payzen .scroll-content {
    margin-bottom: 125px !important;
  }
  .device-type-mobile app-pick-up {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-pick-up .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-pick-up-place {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-pick-up-place .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-pick-up-hour {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-pick-up-hour .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-code-type {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-code-type .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-take-away {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-take-away .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-take-back {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-take-back .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-bundle .scroll-content {
    margin-top: 0 !important;
  }
  .device-type-mobile app-quote,
.device-type-mobile app-quote-review {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-quote .scroll-content,
.device-type-mobile app-quote-review .scroll-content {
    margin-top: 0 !important;
    margin-bottom: 222px !important;
  }
  .device-type-mobile app-quote .scroll-content .large.divider,
.device-type-mobile app-quote-review .scroll-content .large.divider {
    margin-bottom: 100px;
    opacity: 0.4;
  }
  .device-type-mobile app-quote ion-footer button,
.device-type-mobile app-quote-review ion-footer button {
    width: 320px !important;
  }
  .device-type-mobile app-quote ion-footer button span,
.device-type-mobile app-quote-review ion-footer button span {
    font-size: 2rem !important;
    font-weight: 700 !important;
    letter-spacing: -0.1px !important;
  }
  .device-type-mobile app-profile {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-profile ion-list,
.device-type-mobile app-profile ion-card,
.device-type-mobile app-profile .card-md {
    width: 50% !important;
  }
  .device-type-mobile app-profile ion-list button,
.device-type-mobile app-profile ion-card button,
.device-type-mobile app-profile .card-md button {
    width: 320px !important;
  }
  .device-type-mobile app-profile ion-list button span,
.device-type-mobile app-profile ion-card button span,
.device-type-mobile app-profile .card-md button span {
    font-size: 2rem !important;
    font-weight: 700 !important;
    letter-spacing: -0.1px !important;
  }
  .device-type-mobile app-payzen-register {
    background-color: #2cb6b9 !important;
  }
  .device-type-mobile app-payzen-register button {
    width: 15% !important;
  }
  .device-type-mobile app-reservation {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-reservation ion-content .scroll-content {
    margin-top: 10px !important;
    margin-bottom: 200px !important !important;
  }
  .device-type-mobile app-idle .scroll-content .bt_close {
    width: 50px !important;
  }
  .device-type-mobile app-directory .toolbar .toolbar-title {
    text-align: center;
  }
  .device-type-mobile app-directory ion-header .ios-shortcuts div {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .device-type-mobile app-directory ion-header ion-toolbar {
    border-bottom: 0px solid transparent;
    background-color: transparent;
  }
  .device-type-mobile app-directory ion-header ion-toolbar button {
    font-size: 2rem !important;
    padding: 20px 10px 16px 10px !important;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-badge {
    font-size: 1.7rem;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-badge:before {
    height: 24px;
  }
  .device-type-mobile app-directory .scroll-content {
    background: transparent !important;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar {
    border-bottom: 0px solid transparent;
    background-color: transparent;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar ion-title {
    width: 100% !important !important;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar ion-title .toolbar-title {
    margin-left: 8% !important;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar button {
    font-size: 2rem !important;
    padding: 20px 10px 16px 10px !important;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar ion-badge {
    font-size: 1.7rem;
  }
  .device-type-mobile app-directory .scroll-content ion-toolbar ion-badge:before {
    height: 24px;
  }
  .device-type-mobile app-directory ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-cross-sellings ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-upsellings ion-header ion-toolbar .toolbar-content {
    margin-left: 8%;
  }
  .device-type-mobile app-directory ion-header ion-toolbar,
.device-type-mobile app-options ion-header ion-toolbar,
.device-type-mobile app-bundle ion-header ion-toolbar,
.device-type-mobile app-cross-sellings ion-header ion-toolbar,
.device-type-mobile app-upsellings ion-header ion-toolbar {
    border-bottom: 0px solid transparent;
  }
  .device-type-mobile app-directory ion-header ion-toolbar button,
.device-type-mobile app-options ion-header ion-toolbar button,
.device-type-mobile app-bundle ion-header ion-toolbar button,
.device-type-mobile app-cross-sellings ion-header ion-toolbar button,
.device-type-mobile app-upsellings ion-header ion-toolbar button {
    font-size: 2rem !important;
    padding: 20px 10px 16px 10px !important;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-title,
.device-type-mobile app-options ion-header ion-toolbar ion-title,
.device-type-mobile app-bundle ion-header ion-toolbar ion-title,
.device-type-mobile app-cross-sellings ion-header ion-toolbar ion-title,
.device-type-mobile app-upsellings ion-header ion-toolbar ion-title {
    width: 90% !important !important;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-title .toolbar-title,
.device-type-mobile app-options ion-header ion-toolbar ion-title .toolbar-title,
.device-type-mobile app-bundle ion-header ion-toolbar ion-title .toolbar-title,
.device-type-mobile app-cross-sellings ion-header ion-toolbar ion-title .toolbar-title,
.device-type-mobile app-upsellings ion-header ion-toolbar ion-title .toolbar-title {
    margin-top: 0px !important;
  }
  .device-type-mobile app-directory ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-options ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-bundle ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-cross-sellings ion-header ion-toolbar .toolbar-content,
.device-type-mobile app-upsellings ion-header ion-toolbar .toolbar-content {
    margin-left: 8%;
  }
  .device-type-mobile app-directory ion-header ion-toolbar .toolbar-md .toolbar-title,
.device-type-mobile app-options ion-header ion-toolbar .toolbar-md .toolbar-title,
.device-type-mobile app-bundle ion-header ion-toolbar .toolbar-md .toolbar-title,
.device-type-mobile app-cross-sellings ion-header ion-toolbar .toolbar-md .toolbar-title,
.device-type-mobile app-upsellings ion-header ion-toolbar .toolbar-md .toolbar-title {
    margin-left: 0;
  }
  .device-type-mobile app-directory ion-header ion-toolbar .toolbar-md .toolbar-title .subtitle,
.device-type-mobile app-options ion-header ion-toolbar .toolbar-md .toolbar-title .subtitle,
.device-type-mobile app-bundle ion-header ion-toolbar .toolbar-md .toolbar-title .subtitle,
.device-type-mobile app-cross-sellings ion-header ion-toolbar .toolbar-md .toolbar-title .subtitle,
.device-type-mobile app-upsellings ion-header ion-toolbar .toolbar-md .toolbar-title .subtitle {
    font-size: 2rem;
    margin-top: -10px !important;
    font-family: "Lato";
    opacity: 0.5;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-badge,
.device-type-mobile app-options ion-header ion-toolbar ion-badge,
.device-type-mobile app-bundle ion-header ion-toolbar ion-badge,
.device-type-mobile app-cross-sellings ion-header ion-toolbar ion-badge,
.device-type-mobile app-upsellings ion-header ion-toolbar ion-badge {
    font-size: 1.7rem;
  }
  .device-type-mobile app-directory ion-header ion-toolbar ion-badge:before,
.device-type-mobile app-options ion-header ion-toolbar ion-badge:before,
.device-type-mobile app-bundle ion-header ion-toolbar ion-badge:before,
.device-type-mobile app-cross-sellings ion-header ion-toolbar ion-badge:before,
.device-type-mobile app-upsellings ion-header ion-toolbar ion-badge:before {
    height: 24px;
  }
  .device-type-mobile app-directory .bar-button-md:first-child,
.device-type-mobile app-options .bar-button-md:first-child,
.device-type-mobile app-bundle .bar-button-md:first-child,
.device-type-mobile app-cross-sellings .bar-button-md:first-child,
.device-type-mobile app-upsellings .bar-button-md:first-child {
    margin-left: 22px;
    font-size: 4rem;
  }
  .device-type-mobile app-directory .scroll-content,
.device-type-mobile app-options .scroll-content,
.device-type-mobile app-bundle .scroll-content,
.device-type-mobile app-cross-sellings .scroll-content,
.device-type-mobile app-upsellings .scroll-content {
    background: transparent !important;
    padding-top: 0 !important;
    margin-bottom: 125px;
  }
  .device-type-mobile app-directory .scroll-content .img_bar,
.device-type-mobile app-options .scroll-content .img_bar,
.device-type-mobile app-bundle .scroll-content .img_bar,
.device-type-mobile app-cross-sellings .scroll-content .img_bar,
.device-type-mobile app-upsellings .scroll-content .img_bar {
    background: #2cb6b9 !important;
  }
  .device-type-mobile app-directory .scroll-content .grid-cell-container,
.device-type-mobile app-options .scroll-content .grid-cell-container,
.device-type-mobile app-bundle .scroll-content .grid-cell-container,
.device-type-mobile app-cross-sellings .scroll-content .grid-cell-container,
.device-type-mobile app-upsellings .scroll-content .grid-cell-container {
    margin-top: 15px !important;
    margin-bottom: 100px;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .properties .breaking_div,
.device-type-mobile app-directory .scroll-content app-grid-cell .properties .breaking_div,
.device-type-mobile app-options .scroll-content grid-article-cell .properties .breaking_div,
.device-type-mobile app-options .scroll-content app-grid-cell .properties .breaking_div,
.device-type-mobile app-bundle .scroll-content grid-article-cell .properties .breaking_div,
.device-type-mobile app-bundle .scroll-content app-grid-cell .properties .breaking_div,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .properties .breaking_div,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .properties .breaking_div,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .properties .breaking_div,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .properties .breaking_div {
    transform: rotate(44deg) translate(29%, -119%) !important;
    transform-origin: 34% 0 !important;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .properties .breaking_div div,
.device-type-mobile app-directory .scroll-content app-grid-cell .properties .breaking_div div,
.device-type-mobile app-options .scroll-content grid-article-cell .properties .breaking_div div,
.device-type-mobile app-options .scroll-content app-grid-cell .properties .breaking_div div,
.device-type-mobile app-bundle .scroll-content grid-article-cell .properties .breaking_div div,
.device-type-mobile app-bundle .scroll-content app-grid-cell .properties .breaking_div div,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .properties .breaking_div div,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .properties .breaking_div div,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .properties .breaking_div div,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .properties .breaking_div div {
    padding: 3% 15% 2% 20% !important;
    line-height: 1.3em;
    font-size: 1.2em;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .label .description,
.device-type-mobile app-directory .scroll-content app-grid-cell .label .description,
.device-type-mobile app-options .scroll-content grid-article-cell .label .description,
.device-type-mobile app-options .scroll-content app-grid-cell .label .description,
.device-type-mobile app-bundle .scroll-content grid-article-cell .label .description,
.device-type-mobile app-bundle .scroll-content app-grid-cell .label .description,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .label .description,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .label .description,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .label .description,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .label .description {
    top: 215px;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .checkbox,
.device-type-mobile app-directory .scroll-content app-grid-cell .checkbox,
.device-type-mobile app-options .scroll-content grid-article-cell .checkbox,
.device-type-mobile app-options .scroll-content app-grid-cell .checkbox,
.device-type-mobile app-bundle .scroll-content grid-article-cell .checkbox,
.device-type-mobile app-bundle .scroll-content app-grid-cell .checkbox,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .checkbox,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .checkbox,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .checkbox,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .checkbox {
    top: 70%;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .checkbox:before,
.device-type-mobile app-directory .scroll-content app-grid-cell .checkbox:before,
.device-type-mobile app-options .scroll-content grid-article-cell .checkbox:before,
.device-type-mobile app-options .scroll-content app-grid-cell .checkbox:before,
.device-type-mobile app-bundle .scroll-content grid-article-cell .checkbox:before,
.device-type-mobile app-bundle .scroll-content app-grid-cell .checkbox:before,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .checkbox:before,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .checkbox:before,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .checkbox:before,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .checkbox:before {
    margin-left: 4%;
  }
  .device-type-mobile app-directory .scroll-content grid-article-cell .checkbox span.checkbox_text,
.device-type-mobile app-directory .scroll-content app-grid-cell .checkbox span.checkbox_text,
.device-type-mobile app-options .scroll-content grid-article-cell .checkbox span.checkbox_text,
.device-type-mobile app-options .scroll-content app-grid-cell .checkbox span.checkbox_text,
.device-type-mobile app-bundle .scroll-content grid-article-cell .checkbox span.checkbox_text,
.device-type-mobile app-bundle .scroll-content app-grid-cell .checkbox span.checkbox_text,
.device-type-mobile app-cross-sellings .scroll-content grid-article-cell .checkbox span.checkbox_text,
.device-type-mobile app-cross-sellings .scroll-content app-grid-cell .checkbox span.checkbox_text,
.device-type-mobile app-upsellings .scroll-content grid-article-cell .checkbox span.checkbox_text,
.device-type-mobile app-upsellings .scroll-content app-grid-cell .checkbox span.checkbox_text {
    margin-left: -30%;
  }
  .device-type-mobile app-directory ion-footer,
.device-type-mobile app-options ion-footer,
.device-type-mobile app-bundle ion-footer,
.device-type-mobile app-cross-sellings ion-footer,
.device-type-mobile app-upsellings ion-footer {
    background: transparent !important;
    margin-left: auto;
    margin-right: auto;
    height: 75px;
  }
  .device-type-mobile app-directory ion-footer .button_color_1,
.device-type-mobile app-options ion-footer .button_color_1,
.device-type-mobile app-bundle ion-footer .button_color_1,
.device-type-mobile app-cross-sellings ion-footer .button_color_1,
.device-type-mobile app-upsellings ion-footer .button_color_1 {
    margin-right: 10px !important;
    min-width: 30% !important;
    width: 30% !important;
    opacity: 0.8;
    margin-left: 0 !important;
  }
  .device-type-mobile app-directory ion-footer .button_color_2,
.device-type-mobile app-options ion-footer .button_color_2,
.device-type-mobile app-bundle ion-footer .button_color_2,
.device-type-mobile app-cross-sellings ion-footer .button_color_2,
.device-type-mobile app-upsellings ion-footer .button_color_2 {
    min-width: 30% !important;
    width: 30% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 {
    grid-template-columns: 85% 15%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 .article_qte {
    font-size: 2rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0 .article_price {
    font-size: 2.2rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level1 {
    grid-template-columns: 85% 15%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level1 .article_price app-price {
    font-size: 1.6rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level2 {
    grid-template-columns: 85% 15%;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level2 .article_price app-price {
    font-size: 1.6rem;
  }
  .device-type-mobile app-order-lines .order-line ion-row.level0_price {
    grid-template-columns: 85% 15%;
  }
  .device-type-mobile app-order-lines .order-line .divider_compose div {
    width: 15%;
  }
  .device-type-mobile app-order-lines .tva_titre,
.device-type-mobile app-order-lines .tva_detail {
    width: 42% !important;
    margin-left: 58%;
  }
  .device-type-mobile app-language-list ion-footer {
    background: transparent;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .showCartClass .directory_cart .container_order_lines {
    padding: 10px 1px 10px 0px;
  }
  .showCartClass .directory_cart .container_order_lines app-order-lines .store-name {
    margin-top: 0px;
    margin-left: auto !important;
    font-size: 2rem !important;
  }
  .showCartClass .directory_cart .container_order_lines app-order-lines .container_list {
    overflow-y: auto;
    height: calc(100vh - 362px);
    padding-left: 5px;
  }
  .showCartClass .directory_cart .container_order_lines app-order-lines .container_total {
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 5px;
  }
  .showCartClass .directory_cart .container_order_lines app-order-lines .container_total .total {
    margin-bottom: 0;
  }
  .showCartClass .directory_cart .buttons-bar {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
  .showCartClass .directory_cart .buttons-bar button {
    height: 40px;
    background-color: #fcea10 !important !important;
  }
  .showCartClass .cart-position-top {
    display: flex;
    flex-direction: column-reverse;
  }
  .showCartClass .cart-position-top .contain {
    display: flex;
    flex-direction: column-reverse;
  }
  .showCartClass .cart-position-bottom {
    display: flex;
    flex-direction: column;
  }
  .showCartClass .cart-position-bottom .contain {
    display: flex;
    flex-direction: column;
  }
  .showCartClass .cart-position-left {
    display: flex;
    flex-direction: row;
  }
  .showCartClass .cart-position-left .contain {
    display: flex;
    flex-direction: row-reverse;
  }
  .showCartClass .cart-position-right {
    display: flex;
    flex-direction: row;
  }
  .showCartClass .cart-position-right .contain {
    display: flex;
    flex-direction: row;
  }
  .showCartClass .cart-position-top .contain {
    width: 100% !important;
    justify-content: space-between;
    display: flex;
  }
  .showCartClass .cart-position-top .contain .grid-cell-container {
    width: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: calc(100vh - 513px);
  }
  .showCartClass .cart-position-top .contain.carousel_show {
    width: 100% !important;
  }
  .showCartClass .cart-position-top .contain.carousel_show .grid-cell-container {
    height: calc(100vh - 275px);
  }
  .showCartClass .cart-position-top .contain.carousel_show .container_list {
    height: calc(100vh - 470px);
  }
  .showCartClass .cart-position-top .directory_cart {
    width: 100% !important;
  }
  .showCartClass .cart-position-top .container_list {
    width: 100%;
    height: 150px !important;
  }
  .showCartClass .cart-position-top .container_order_lines app-price {
    font-size: 1.5rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level0 .article_name {
    font-size: 1.4rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level0 .article_qte {
    font-size: 2rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level0 .article_price {
    font-size: 2rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level1 .article_name {
    font-size: 1.3rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level1 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level2 .article_name {
    font-size: 1.2rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level2 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line ion-row.level0_price .article_price {
    font-size: 2.2rem;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line .divider_compose div {
    width: 25%;
  }
  .showCartClass .cart-position-top .container_order_lines .order-line:last-of-type .divider {
    display: none;
  }
  .showCartClass .cart-position-top .container_order_lines .divider {
    background: #1c8f92;
  }
  .showCartClass .cart-position-top .container_order_lines .total {
    padding-left: 5px;
    padding-right: 5px;
  }
  .showCartClass .cart-position-top .container_order_lines .total > span {
    font-size: 1.8rem;
  }
  .showCartClass .cart-position-top .container_order_lines .total .price price {
    font-size: 1.8rem;
  }
  .showCartClass .cart-position-top .container_order_lines .tva_titre, .showCartClass .cart-position-top .container_order_lines .tva_detail {
    margin-left: 57%;
    line-height: 1.5rem;
  }
  .showCartClass .cart-position-top .container_order_lines .tva_titre span,
.showCartClass .cart-position-top .container_order_lines .tva_titre app-price, .showCartClass .cart-position-top .container_order_lines .tva_detail span,
.showCartClass .cart-position-top .container_order_lines .tva_detail app-price {
    text-align: right;
    font-size: 1rem !important;
  }
  .showCartClass .cart-position-top .container_order_lines .buttons-bar {
    margin-bottom: 0;
  }
  .showCartClass .cart-position-top .container_order_lines .buttons-bar button {
    color: #1c8f92;
    height: 34px !important;
  }
  .showCartClass .cart-position-top .container_total {
    width: 100%;
  }
  .showCartClass .cart-position-bottom .contain {
    width: 100% !important;
    justify-content: space-between;
    display: flex;
    height: calc(100vh - 156px);
  }
  .showCartClass .cart-position-bottom .contain .grid-cell-container {
    width: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
  .showCartClass .cart-position-bottom .contain.carousel_show {
    width: 100% !important;
    height: calc(100vh - 275px) !important;
  }
  .showCartClass .cart-position-bottom .contain.carousel_show .grid-cell-container {
    height: 360px;
  }
  .showCartClass .cart-position-bottom .contain.carousel_show .container_list {
    height: calc(100vh - 470px);
  }
  .showCartClass .cart-position-bottom .directory_cart {
    width: 100% !important;
  }
  .showCartClass .cart-position-bottom .container_list {
    width: 100%;
    height: 100px !important;
  }
  .showCartClass .cart-position-bottom .container_order_lines app-price {
    font-size: 1.5rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level0 .article_name {
    font-size: 1.4rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level0 .article_qte {
    font-size: 2rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level0 .article_price {
    font-size: 2rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level1 .article_name {
    font-size: 1.3rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level1 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level2 .article_name {
    font-size: 1.2rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level2 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line ion-row.level0_price .article_price {
    font-size: 2.2rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line .divider_compose div {
    width: 25%;
  }
  .showCartClass .cart-position-bottom .container_order_lines .order-line:last-of-type .divider {
    display: none;
  }
  .showCartClass .cart-position-bottom .container_order_lines .divider {
    background: #1c8f92;
  }
  .showCartClass .cart-position-bottom .container_order_lines .total {
    padding-left: 5px;
    padding-right: 5px;
  }
  .showCartClass .cart-position-bottom .container_order_lines .total > span {
    font-size: 1.8rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .total .price app-price {
    font-size: 1.8rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .tva_titre, .showCartClass .cart-position-bottom .container_order_lines .tva_detail {
    margin-left: 57%;
    line-height: 1.5rem;
  }
  .showCartClass .cart-position-bottom .container_order_lines .tva_titre span,
.showCartClass .cart-position-bottom .container_order_lines .tva_titre app-price, .showCartClass .cart-position-bottom .container_order_lines .tva_detail span,
.showCartClass .cart-position-bottom .container_order_lines .tva_detail app-price {
    text-align: right;
    font-size: 1rem !important;
  }
  .showCartClass .cart-position-bottom .container_order_lines .buttons-bar {
    margin-bottom: 0;
  }
  .showCartClass .cart-position-bottom .container_order_lines .buttons-bar button {
    color: #1c8f92;
    height: 34px !important;
  }
  .showCartClass .cart-position-bottom .container_total {
    width: 100%;
  }
  .showCartClass .cart-position-left .contain,
.showCartClass .cart-position-right .contain {
    width: 100% !important;
    justify-content: space-between;
    display: flex;
  }
  .showCartClass .cart-position-left .contain .grid-cell-container,
.showCartClass .cart-position-right .contain .grid-cell-container {
    width: 70% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: calc(100vh - 158px);
  }
  .showCartClass .cart-position-left .contain.carousel_show,
.showCartClass .cart-position-right .contain.carousel_show {
    width: 100% !important;
    height: calc(100vh - 265px) !important !important;
  }
  .showCartClass .cart-position-left .contain.carousel_show .grid-cell-container,
.showCartClass .cart-position-right .contain.carousel_show .grid-cell-container {
    height: calc(100vh - 290px) !important;
  }
  .showCartClass .cart-position-left .contain.carousel_show .container_list,
.showCartClass .cart-position-right .contain.carousel_show .container_list {
    border-bottom: 1px solid #1c8f92;
    height: calc(100vh - 472px) !important;
  }
  .showCartClass .cart-position-left .container_total,
.showCartClass .cart-position-right .container_total {
    width: 307px;
  }
  .showCartClass .cart-position-left .container_total button,
.showCartClass .cart-position-right .container_total button {
    height: 35px !important;
  }
  .showCartClass .cart-position-left .container_order_lines app-price,
.showCartClass .cart-position-right .container_order_lines app-price {
    font-size: 1.5rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line,
.showCartClass .cart-position-right .container_order_lines .order-line {
    width: 100%;
    padding-right: 0;
    padding-top: 10px 0;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0 {
    grid-template-columns: 65% 35%;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0 .article_name,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0 .article_name {
    font-size: 1.4rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0 .article_qte,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0 .article_qte {
    font-size: 2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0 .article_price,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0 .article_price {
    font-size: 2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level1,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level1 {
    grid-template-columns: 65% 35%;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level1 .article_name,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level1 .article_name {
    font-size: 1.3rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level1 .article_price app-price,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level1 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level2,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level2 {
    grid-template-columns: 65% 35%;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level2 .article_name,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level2 .article_name {
    font-size: 1.2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level2 .article_price app-price,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level2 .article_price app-price {
    font-size: 1.6rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0_price,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0_price {
    grid-template-columns: 65% 35%;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line ion-row.level0_price .article_price,
.showCartClass .cart-position-right .container_order_lines .order-line ion-row.level0_price .article_price {
    font-size: 2.2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line .divider_compose div,
.showCartClass .cart-position-right .container_order_lines .order-line .divider_compose div {
    width: 25%;
  }
  .showCartClass .cart-position-left .container_order_lines .order-line:last-of-type .divider,
.showCartClass .cart-position-right .container_order_lines .order-line:last-of-type .divider {
    display: none;
  }
  .showCartClass .cart-position-left .container_order_lines .divider,
.showCartClass .cart-position-right .container_order_lines .divider {
    background: #1c8f92;
  }
  .showCartClass .cart-position-left .container_order_lines .total,
.showCartClass .cart-position-right .container_order_lines .total {
    padding-left: 5px;
    padding-right: 5px;
  }
  .showCartClass .cart-position-left .container_order_lines .total > span,
.showCartClass .cart-position-right .container_order_lines .total > span {
    font-size: 2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .total .price app-price,
.showCartClass .cart-position-right .container_order_lines .total .price app-price {
    font-size: 2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .tva_titre, .showCartClass .cart-position-left .container_order_lines .tva_detail,
.showCartClass .cart-position-right .container_order_lines .tva_titre,
.showCartClass .cart-position-right .container_order_lines .tva_detail {
    margin-left: 53%;
    line-height: 2rem;
  }
  .showCartClass .cart-position-left .container_order_lines .tva_titre span,
.showCartClass .cart-position-left .container_order_lines .tva_titre app-price, .showCartClass .cart-position-left .container_order_lines .tva_detail span,
.showCartClass .cart-position-left .container_order_lines .tva_detail app-price,
.showCartClass .cart-position-right .container_order_lines .tva_titre span,
.showCartClass .cart-position-right .container_order_lines .tva_titre app-price,
.showCartClass .cart-position-right .container_order_lines .tva_detail span,
.showCartClass .cart-position-right .container_order_lines .tva_detail app-price {
    text-align: right;
    font-size: 1.2rem !important;
  }
  .showCartClass .cart-position-left .container_order_lines .buttons-bar,
.showCartClass .cart-position-right .container_order_lines .buttons-bar {
    margin-bottom: 0;
  }
  .showCartClass .cart-position-left .container_order_lines .buttons-bar button,
.showCartClass .cart-position-right .container_order_lines .buttons-bar button {
    color: #1c8f92;
    height: 34px !important;
  }
}
:root {
  /** primary **/
  --ion-color-primary: $color_1;
}
:root .iti__country-list {
  position: fixed !important;
  color: grey;
}

ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

/* Remove bottom border on md */
.header-md::after {
  background-image: none;
}

/* Remove bottom border on ios */
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}