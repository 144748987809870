app-bundle {
    background: $color_1;
    ion-header {
        background: $color_1;
    }
    .toolbar-content {
        background: $color_1;
    }
    .toolbar-title {
        margin-left: -20px;
        font-size: 18px !important;
    }
    .scroll-content {
        background: $color_1;
    }
    .step_menu {
        border-bottom: 1px solid lightgray;
        div.img_label {
            font-size: 2rem;
            color: white;
        }
    }

    p {
        color: $color_1;
    }
    .button_color_1 {
        margin-right: auto;
        min-width: 58%;
        margin-left: auto;
    }
    .button_color_2 {
        margin-left: 10px !important;
        min-width: 45%;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
    .img_bar{
        background-color: $color_1 !important;
    }
}
