app-login {
    .form-login {
        width: 80%;
        @include margin-auto;
        font-family: $second-font !important;
        .pass {
            .bt_pass {
                span {
                    color: $color_white !important;
                    ion-icon{
                        color: $color_white !important;
                        font-size: 1.2rem;
                    }
                    /*i {
                        &.eye_on{
                            &:after{
                                color: $color_white !important;
                                font-family:$fontawesome;
                                content:'\f06e';
                                color:grey;
                                font-size: 1.2rem;
                            }
                        }
                        &.eye_off{
                            &:after{
                                color: $color_white !important;
                                font-family:$fontawesome;
                                content:'\f070';
                                color:grey;
                                font-size: 1.2rem;
                            }
                        }
                    }*/
                }
            }
        }

        ion-item{
            ion-label{
                color: $color_white !important;
            }

            ion-input{
                color: $color_white !important;
            }
        }
    }
    ion-header {
        background-color: transparent !important;
        //header title and button
       ion-toolbar {
            background-color: $color_1 !important;
        }
    }
    .toolbar-background-md {
        border-color: transparent !important;
        background-color: transparent !important;
    }
    .logo {
        width: 267px;
        height: 140px;
        content: " ";
        background: transparent url("#{$assetPath}/Accueil/logo_home.png") no-repeat center center !important;
        background-size: contain !important;
        @include margin-auto;
    }
    .forget-pass-text {
        font-family: $second-font !important;
        color: $color_white;
        opacity: 1;
    }
    .button {
        border: 2px solid $color_1 !important;
        height: 42px;
        width: 100%;
        font-family: $main-font;
        .button-inner {
            font-size: 2.4rem !important;
        }
        &.button_color_3 {
            margin-top: 20px;
        }
    }
    .text-mention-legal {
        color: $color_white;
        font-size: 1.1rem;
        font-family: $main-font;
        opacity: 0.8;
    }
    .text-cgv {
        color: $color_white;
        font-size: 1.1rem;
        font-family: $main-font;
        opacity: 0.8;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
        ion-list{
            background-color: $color_1 !important;
            ion-item{
                --background: $color_1 !important;
            }
        }

        ion-icon{
            color: $color_white !important;
            font-size: 1.2rem;
        }
    }
}
