@import "../variables";

app-store-detail {
    ion-content{
        background: $color_1 !important;
        --background:  $color_1 !important;
        ion-list{
            background: $color_1 !important;
            --background:  $color_1 !important;
            ion-item{
                background: $color_1 !important;
                --background:  $color_1 !important;
            }
        }
    }
    ion-list{
        padding: 10px 10px !important;
    }
    .toolbar-title{
        color: $color_2 !important;
        font-size: 2rem;
    }
    h2{
        font-family: $main-font;
        font-weight: 700;
        font-size: 1.8rem;
        white-space: normal !important;
        line-height: 26px;
        text-align: center  !important;
        margin-bottom: 15px;
        color:$color_2;
        text-transform: none !important;
    }
    .store-name{
        color:$color_5;
        font-size: 1.2rem !important;
        font-weight: 600;
        margin-left: auto;
        margin-right: auto;
    }
    ion-label{
        color: $color_2 !important;
    }
    ion-avatar{
        display: none;
    }
    ion-footer{
        background: $color_1;
        button.button_color_1{
            line-height: 4px !important;
        }
    }
    .address,
    .horaires,
    .tel,
    .peculiarity {
        color: $color_2 !important;
    }

    ion-item,
    ion-grid,
    ion-row,
    ion-col{
        padding-left: 0px !important;
        margin-left: 0px !important;
    }
}
