$color_1: #2cb6b9;
$color_2: white;
$color_3: #47b4cf;
$color_4: #1c8f92;
$color_5: #fcea10;
$color_6: white;
$color_7: #92fdff;
$color_8: #fcea10;
$color_9: #2fafc8;
$color_white: white;
$color_black: black;
//buttons
$color_border_button: $color_2;
$color_background_bouton: $color_2;
$color_linear_bouton_1: $color_white;
$color_linear_bouton_2: $color_white;
$color_linear_bouton_3: $color_4;
$color_text_button: $color_2;
//inputs
$color-text-input: $color_white;
$color-background-input: transparent;
$color-border-input: $color_white;
//page background
$color_linear_background_1: $color_white;
$color_linear_background_2: #e6e6e6;
// menu ios
$color_menu_tab: #dadada;
// directory shortcuts
$color_ios_shortcuts: #f9e7ee;
$color_bt_gridcell: #dad9d9;
$color_bt_gridcell_hover: #eceaea;
// formulaires
$color_checkbox: $color_1;
//navbar
$color_border_header: #888;
$color_background_header: $color_2;
$color_button_header: $color_white;
$color_text_header: $color_white;
// prix
$color_price: $color_2;
$colors: (
    primary: #020612,
    secondary: #47b4cf,
    danger: #fda805,
    light: #f4f4f4,
    dark: #222,
);
$border_radius_base: 10px;
