.grid-cell-container {
    margin-top: 10px;
}

grid-article-cell,
app-grid-cell {
    padding: 0 2px;
    &:last-child {
        margin-bottom: 20px !important;
    }
    .disabled {
        pointer-events: none;
        cursor: none;
        button {
            cursor: none;
            opacity: 0.5;
            pointer-events: none;
        }
        &:hover {
            box-shadow: 0 6px 9px -4px rgba(50, 50, 50, 0.3);
            transition: all 0.2s ease-in-out;
            transform: translateY(-3px);
        }
    }
    > div {
        transform: translateY(0px);
        transition: all 0.2s ease-in-out;
        &.disabled {
            opacity: 0.9;
        }
    }
    //design grille -> variable configuration ARTICLES_PAGE_LAYOUT = 'grid'
    .design_grid {
        &.container_image {
            padding-top: 10px;
            height: auto;
            max-height: 156px !important;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 3px 0 3px;
            @media screen and (min-width: 768px) {
                padding: 10px 10px 0 10px;
            }
            img {
                cursor: pointer;
                height: 100%;
                max-height: 137px !important;
            }
            button.button_4.bt_cmd {
                display: none;
                width: 98%;
                height: 23px;
                cursor: default;
                border-radius: 8px;
                position: absolute;
                z-index: 99;
                bottom: 96px;
                left: 1%;
                @include box-shadow(2px, 1px, 3px, 1px, 25, 89, 153, 0.26);
                span {
                    letter-spacing: 0px;
                    div {
                        font-size: 1.15rem;
                    }
                }
                ion-icon {
                    margin-left: 6px;
                    margin-right: 0px;
                }
            }
        }
    }
    //design horizontal -> variable configuration ARTICLES_PAGE_LAYOUT = 'line'
    .design_line {
        &.container_image {
            height: 80px;
            text-align: center;
            width: 140px;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -12px;
            margin-right: -20px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    // options
    .checkbox {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 40px;
        top: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /*&:before {
            margin-left: 6%;
        }*/
        span.checkbox_text {
            display: flex;
            margin-left: -20%;
            line-height: 28px;
        }
        ion-icon{
            color: $color_1 !important;
            font-size: 1.9rem;
            padding-right: 5px;
        }
    }
    .cart {
        pointer-events: initial;
        display: flex;
        align-items: center;
        background: none;
        background-size: cover !important;
        cursor: pointer;
        position: absolute;
        opacity: 1 !important;
        width: 30px;
        height: 30px;
        &:before {
            font-family: Ionicons;
            font-size: 36px;
            position: relative;
            border-radius: 50%;
            line-height: 30px;
            opacity: 1 !important;
        }
    }
    .properties {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        z-index: 999;
        position: absolute;
        width: 100%;
        .info {
            display: flex;
            cursor: pointer;
            width: 67px;
            height: 63px;
            background: transparent url("#{$assetPath}/Common/background_info.png") no-repeat center center !important;
            border-top-right-radius: 9px;
            top: 0px;
            right: -1px;
            position: absolute;
            background-size: contain;
            z-index: 0;
            @media screen and (max-width: 767px) {
                transform: scale(0.7) translate(13px, -14px);
            }
            &:before {
                /*font-family: Ionicons;*/
                /*content: "\f312";*/
                font-size: 30px;
                position: absolute;
                left: 43px;
                font-style: italic;
                text-align: 0;
                top: 3px;
                text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
            }
            ion-icon{
                width: 30px;
                height: 30px;
                margin-left: 30px;
                color: $color_white;
            }
        }
        .breaking_div {
            clip-path: polygon(22% 0, 81% 0, 100% 84%, 100% 96%, 0 96%, 0 83%);
            text-transform: uppercase;
            position: absolute;
            text-align: center;
            font-size: 0.7em;
            letter-spacing: 2px;
            right: 0;
            transform: rotate(43deg) translate(36%, -100%);
            font-family: $main-font;
            font-weight: 700;
            z-index: 1;
            line-height: 1.3em;
            transform-origin: 35% 0;
            text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
            div {
                padding: 3% 26% 2% 18%;
                line-height: 1.3em;
                font-size: 1.1em;
                margin-top: -2px;
            }
            @media screen and (max-width: 320px) {
                transform: scale(0.8) rotate(43deg) translate(45%, -106%) !important;
                transform-origin: 45% 0 !important;
                font-size: 0.5em;
            }
        }
    }
    .cart {
        display: none !important;
        width: 26px;
        height: 26px;
        top: 71px;
        left: 83%;
        opacity: 1 !important;
        justify-content: center;
        border-radius: 20px;
        @media screen and (min-width: 1024px) {
            left: 88% !important;
        }
        &:before {
            display: block;
            content: "\f11f";
            font-size: 21px;
            text-align: center;
        }
    }
    .price_button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 55px;
        .global_price {
            position: relative;
            text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
            // prix
            .unit-price {
                text-align: center;
                box-sizing: border-box;
                align-items: center;
                justify-content: flex-start;
                //  clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
                width: 100%;
                border-radius: 5px;
                display: flex;
                background-color: #fcea10;
                .euro {
                    margin-left: 3px;
                    padding: 3px 20px 0 3px;

                    .withoutDecimalCSSClass {
                        color: transparent;
                        opacity: 0;
                    }
                    .stroke {
                        text-decoration: line-through;
                    }
                    app-price {
                        display: flex;
                        font-family: $price-font;
                        width: auto;
                    }
                }
                &.binome-layer-price {
                    width: 118px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    &.unit {
                        width: 100%;
                    }
                    &.hundred {
                        width: 120px;
                    }
                }
                &.binome-offer-price {
                    width: 118px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    &.unit {
                        width: 105px;
                    }
                    &.hundred {
                        width: 120px;
                    }
                }
                .decimal {
                    font-size: smaller;
                }
            }
            .rate-price {
                font-family: $main-font;
                text-align: right;
                box-sizing: border-box;
                align-items: center;
                justify-content: flex-end;
                @media screen and (min-width: 768px) {
                    padding-top: 0 !important;
                    font-size: 1.7vw !important;
                }
            }
            // prix happy hour
            .layer-price {
                position: absolute;
                top: -20px;
                right: 0;
                letter-spacing: 0.1rem;
                font-family: $price-font;
                display: flex;
                z-index: 1;

                .club_price {
                    min-width: 75px;
                    font-family: $price-font;
                    border-top-left-radius: 5px;
                    text-align: left;
                    padding-left: 5px;
                    margin-top: 2px;
                    .club {
                        text-transform: uppercase;
                        padding-left: 1px;
                    }
                }
                .line_through {
                    top: 12px;
                    left: 6px;
                    width: 40px;
                    height: 2px;
                    z-index: 2;
                    transform: rotate(-23deg);
                    position: absolute;
                }
                .euro {
                    display: inline-flex;
                    //   margin-left: -20px;
                    //      clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
                    margin-top: 2px;
                    border-top-left-radius: 0;
                    text-align: center;
                    min-width: 65px;
                    height: 25px;
                    right: 4px;
                    position: absolute;
                    &.unit {
                        min-width: 50px;
                        //   clip-path: polygon(0 0, 100% 0, 76% 100%, 24% 100%);
                        app-price {
                            min-width: 50px;
                            border-bottom-right-radius: 0;
                            padding-left: 9px;
                            .line_through {
                                left: 13px;
                                width: 31px;
                            }
                            &:after {
                                //  clip-path: polygon(0 0, 100% 0, 76% 100%, 24% 100%);
                            }
                        }
                    }
                    &.hundred {
                        min-width: 68px;
                        //  clip-path: polygon(0 0, 100% 0, 78% 100%, 24% 100%);
                        app-price {
                            .line_through {
                                left: 18px;
                                width: 35px;
                                transform: rotate(-22deg);
                            }
                            &:after {
                                // clip-path: polygon(0 0, 100% 0, 78% 100%, 24% 100%);
                            }
                        }
                    }
                    app-price {
                        font-family: $price-font;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 40px;
                        border-top-right-radius: 0;
                        .integerCSSClass,
                        .decimalCSSClass,
                        .withoutDecimalCSSClass {
                            z-index: 1;
                        }
                        .withoutDecimalCSSClass {
                            opacity: 0;
                            display: none;
                        }
                        /*&:after {
                            content: "";
                            //    clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
                            top: 1px;
                            left: 1px;
                            right: 1px;
                            bottom: 1px;
                            position: absolute;
                        }*/
                    }
                }
            }

            // prix offre commerciale
            .offer-price {
                letter-spacing: 0.1rem;
                font-family: $price-font;
                display: flex;
                z-index: 1;
                margin-top: -10px;
                .club_price {
                    min-width: 75px;
                    font-family: $price-font;
                    border-top-left-radius: 5px;
                    text-align: left;
                    padding-left: 5px;
                    margin-top: 2px;
                    .club {
                        text-transform: uppercase;
                        padding-left: 1px;
                    }
                }
                .line_through {
                    top: 12px;
                    left: 6px;
                    width: 40px;
                    height: 2px;
                    z-index: 2;
                    transform: rotate(-23deg);
                    position: absolute;
                }
                .euro {
                    display: inline-flex;
                    margin-left: -20px;
                    //      clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
                    margin-top: 2px;
                    border-top-left-radius: 0;
                    text-align: center;
                    min-width: 65px;
                    height: 25px;
                    justify-content: center;
                    position: relative;
                    &.unit {
                        min-width: 50px;
                        //   clip-path: polygon(0 0, 100% 0, 76% 100%, 24% 100%);
                        app-price {
                            min-width: 50px;
                            border-bottom-right-radius: 0;
                            padding-left: 9px;
                            .line_through {
                                left: 13px;
                                width: 31px;
                            }
                            &:after {
                                //  clip-path: polygon(0 0, 100% 0, 76% 100%, 24% 100%);
                            }
                        }
                    }
                    &.hundred {
                        min-width: 68px;
                        //  clip-path: polygon(0 0, 100% 0, 78% 100%, 24% 100%);
                        app-price {
                            .line_through {
                                left: 18px;
                                width: 35px;
                                transform: rotate(-22deg);
                            }
                            &:after {
                                // clip-path: polygon(0 0, 100% 0, 78% 100%, 24% 100%);
                            }
                        }
                    }
                    app-price {
                        font-family: $price-font;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 40px;
                        border-top-right-radius: 5px;
                        .integerCSSClass,
                        .decimalCSSClass,
                        .withoutDecimalCSSClass {
                            z-index: 1;
                        }
                        .withoutDecimalCSSClass {
                            opacity: 0;
                            display: none;
                        }
                        /*&:after {
                            content: "";
                            //    clip-path: polygon(0 0, 100% 0, 78% 100%, 22% 100%);
                            top: 1px;
                            left: 1px;
                            right: 1px;
                            bottom: 1px;
                            position: absolute;
                        }*/
                    }
                }
            }
        }
        .addCart {
            .bt_add_cart_icon {
                height: 33px;
                width: 120px;
                border-radius: 5px;
                @media screen and (max-width: $xmedium-screen) {
                    width: 54px;
                }
                &:focus {
                    .icon_cart {
                        animation: rotate 1s ease infinite;
                    }
                }
                .text {
                    margin-left: 32px;
                    opacity: 0.8;
                    text-align: left;
                    @media screen and (max-width: $xmedium-screen) {
                        display: none;
                    }
                }
                .icon_cart {
                    margin-top: -1px;
                    position: absolute;
                    left: 12px;
                    &:before {
                        content: "\f218" !important;
                        font-family: $fontawesome;
                        font-size: 2.7rem;
                        font-weight: 700;
                        opacity: 0.6;
                    }
                }
            }
            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                33% {
                    transform: rotate(-5deg);
                }
                66% {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(5deg);
                }
            }
        }
    }
    //////////// libelle
    .label {
        height: 80px;
        padding-top: 10px;
        display: flex;
        .title {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            text-align: center;
            font-family: $main-font;
            margin-top: 5px;
            margin-bottom: 5px;
            text-transform: uppercase;
            width: 95%;
            @include margin-auto;
            height: 56px;
            text-overflow: ellipsis !important;
            white-space: wrap;
            overflow: hidden;
        }
        // description
        .description {
            //  display: flex;
            justify-content: center;
            display: none;
        }
    }
    //////////// quantite
    .controls_grid_cell {
        min-height: 57px;
        .article_qte {
            display: inline-flex;
            justify-content: space-evenly;
            align-items: center;
            .row {
                width: 100%;
                button {
                    cursor: pointer;
                    &.activated {
                        background: transparent !important;
                    }
                    ion-icon {
                        font-size: 3rem;
                    }
                    &.qte_min,
                    &.qte_max {
                        height: 30px;
                        width: 50px;
                        border: 0 solid transparent;
                        background-color: transparent !important;
                        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                        &.hidden {
                            display: none;
                        }
                    }
                    &.qte_max {
                        @media screen and (max-width: $xmedium-screen) {
                            margin-left: -20px !important;
                        }
                    }
                }
            }
            ion-row{
                width: 100%;
                button {
                    cursor: pointer;
                    &.activated {
                        background: transparent !important;
                    }
                    ion-icon {
                        font-size: 1.6rem;
                    }
                    .qte_min,
                    .qte_max {
                        /*height: 30px;*/
                        /*width: 50px;*/
                        border: 0 solid transparent;
                        background-color: transparent !important;
                        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                        .hidden {
                            display: none;
                        }
                    }
                    .qte_max {
                        @media screen and (max-width: $xmedium-screen) {
                            margin-left: -20px !important;
                        }
                    }
                    
                    .hidden{
                        display: none;
                    }

                }

            }
        }
        .qte {
            margin-top: 2px !important;
        }
    }
}


grid-article-cell,
app-grid-cell  {
    padding: 0;
    > div {
        background: $color_white;
        border-radius: 10px;
        &:hover {
            box-shadow: 0 6px 9px -4px rgba(50, 50, 50, 0.3);
            transition: all 0.2s ease-in-out;
            transform: translateY(-3px);
        }
    }
    //design grille -> variable configuration ARTICLES_PAGE_LAYOUT = 'grid'
    .design_grid {
        &.container_image {
            padding: 15px 3px 0 3px !important;
            img {
                // border-bottom : 3px solid $color_3;
            }
            button.button_4.bt_cmd {
                &:hover:not(.disable-hover) {
                    background-color: #d2d2d2;
                }
                ion-icon {
                    color: $color_2;
                }
            }
        }
    }
    // options
    .div_grid_cell {
        box-shadow: 0 0 0 0 $color_white;
        background: $color_white;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.checked {
            box-shadow: 0 0 0 3px $color_3;
            transition: all 0.2s linear;
        }
    }
    .cart {
        background: color_3;
        &:before {
            color: $color_3;
        }
    }
    .properties {
        .info {
            &:before {
                color: $color_white;
            }
        }
        .breaking_div {
            background: $color_1;
            transform: scale(0.8) rotate(43deg) translate(45%, -106%) !important;
            transform-origin: 49% 0 !important;

            div {
                padding: 3% 22% 2% 17% !important;
                color: $color_white;
                margin-top: -3px;
                font-size: 0.8em;
            }
        }
    }
    .cart {
        background: $color_7;
        &:before {
            color: $color_white;
        }
    }
    //////////// libelle
    .label {
        display: flex;
        height: 55px;
        flex-direction: column;
        .title {
            color: $color_1;
            font-size: 1.1em;
            height: 40px;
            position: absolute;
        }
        // description
        .description {
            opacity: 0.9;
            font-size: 1.3rem;
            line-height: 1.4rem;
            display: none !important;
            flex-direction: column;
            text-align: center;
            margin-top: 50px;
            padding: 0 15px;
            z-index: -1;
        }
    }
    //////// price
    .price_button {
        position: relative;
        width: 100%;
        padding: 5px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: justify;
        justify-content: flex-end;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 20px;

        .global_price {
            // prix
            width: 100%;
            flex-direction: row !important;
            padding: 0 5px;
            .unit-price {
                background: $color_8;
                padding-top: 4px;
                padding-bottom: 4px;

                border-top-left-radius: 0;
                border-top-right-radius: 0;
                -webkit-clip-path: polygon(0 0,calc(100% - 6px) 0,calc(100% - 21px) 100%,0 100%);
                clip-path: polygon(0 0,calc(100% - 6px) 0,calc(100% - 21px) 100%,0 100%);
                border-bottom-left-radius: 5px;

                &.binome-layer-price {
                    border-top-left-radius: 0;
                }
                .euro {
                    app-price {
                        color: $color_9;
                        .integerCSSClass,
                        .decimalCSSClass,
                        .withoutDecimalCSSClass {
                            z-index: 1;
                        }
                        .integerCSSClass {
                            font-size: 2.2rem;
                            line-height: 2.1rem;
                        }
                        .decimalCSSClass {
                            font-size: 1.2rem;
                            margin-top: 0.6rem;
                        }
                        &:after {
                            font-family: arial;
                            font-size: 1rem;
                            font-weight: 700;
                            margin-left: -15px !important;
                        }
                    }
                }
            }
            // prix happy hour
            .layer-price {
                justify-content: flex-end;
                .club_price {
                    display: block;
                    width: 100%;
                    right: 20px;
                    position: absolute;
                    min-width: 140px !important;
                    background-color: $color_9;
                    color: black;
                    height: 18px;
                    line-height: 18px;
                    letter-spacing: -0.01rem;
                    font-size: .8rem;
                    .club {
                        color: $color_black;
                        font-weight: 400;
                        font-size: .8rem;
                    }
                }
                .line_through {
                    background-color: $color_9;
                }
                .euro {
                    background-color: $color_2;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    &.unit {
                        -webkit-clip-path: polygon(0 0,100% 0,calc(100% - 10px) 100%,22% 100%);
                        clip-path: polygon(0 0,100% 0,calc(100% - 10px) 100%,22% 100%);

                        app-price {
                            background: $color_1;

                            &::after {
                                -webkit-clip-path: polygon(4px 0,calc(100% - 1px) 0,calc(100% - 10px) 100%,14px 100%);
                                clip-path: polygon(4px 0,calc(100% - 1px) 0,calc(100% - 10px) 100%,14px 100%);

                                content: "";
                                top: 1px;
                                left: 1px;
                                right: 1px;
                                bottom: 1px;
                                position: absolute;
                                background: #fff;
                            }
                        }
                    }
                    &.hundred {
                        min-width: 68px;
                        app-price {
                            &:after {
                            }
                        }
                    }
                    app-price {
                        color: $color_black;
                        background: $color_2;

                        .integerCSSClass {
                            font-size: 1.6rem;
                            &:after {
                                content: "€";
                                color: #000;
                                font-size: .8rem;
                                bottom: .7rem;
                                position: relative;
                            }
                        }
                        .decimalCSSClass {
                            font-size: 0.8rem;
                            margin-top: 0.75rem;
                            right: 0.6rem;
                            position: relative;
                        }
                        .integerCSSClass {
                            &:after {
                                color: $color_white;
                            }
                        }
                        &:after {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .addCart {
            .bt_add_cart_icon {
                text-transform: none;
                color: $color_black;
                background-color: $color_bt_gridcell;
                &:hover {
                    background-color: $color_bt_gridcell_hover !important;
                    .text {
                        color: #ea6e8f;
                    }
                    .icon_cart {
                        color: #ea6e8f !important;
                    }
                }
                .icon_cart {
                    color: $color_black !important;
                }
            }
            .button-md:hover:not(.disable-hover) {
                background-color: $color_bt_gridcell_hover !important;
            }
        }
    }
    /////// quantités
    .controls_grid_cell {
        .qte {
            font-size: 1.5rem;
            color: $color_1;
        }
        .qte_min,
        .qte_max {
            background: transparent !important;

            .ion-md-remove-circle:before {
                color: $color_1 !important;
                text-shadow: 4px 1px 2px #daddea !important;
                font-size: 2.7rem;
            }
            .ion-md-add-circle:before {
                color: $color_1 !important;
                text-shadow: 4px 1px 2px #daddea !important;
                font-size: 2.7rem;
            }
            ion-icon{
                color: $color_1 !important;
                text-shadow: 4px 1px 2px #daddea !important;
                font-size: 1.6rem;
            }
        }
    }
    .button-md:hover:not(.disable-hover) {
        background-color: $color_1 !important;
    }
}
