////////////////////////// MOBILE /////////////////////////////////////// MOBILE ////////////////////////////////////////// MOBILE //////////////
.device-type-mobile {
    //LANDSCAPE TABLET ///////////////////////////LANDSCAPE TABLET/////////////////////////////////LANDSCAPE TABLET//////////////////////////////LANDSCAPE TABLET//////////////////////////////////////////
    @media screen and (min-width: 1024px) and (orientation: landscape) {
        ion-app {
            background-color: $color_1 !important;
            &.md {
                background-color: $color_1 !important;
            }
        }
        #leftMenu {
            .menu-left-android {
                .scroll-content {
                    overflow-y: hidden !important;
                }
            }
        }
        .ion-page {
            .bar-button-menutoggle {
                padding: 0 2px !important;
            }
            .mainfont-button:not(.qte_min, .qte_max) {
                height: 40px;
                width: 320px;
                .button-inner {
                    font-size: 2.5rem !important;
                }
            }
            ion-header,
            ion-content {
                width: 100% !important;
                margin-left: auto !important;
                margin-right: auto !important;
                position: relative !important;
                .back-button-icon-md {
                    font-size: 4rem;
                    font-weight: normal;
                }
                .container {
                    width: 1024px !important;
                    height: 100vh !important;
                    align-content: center !important;
                    @include margin-auto;
                }
                .scroll-content {
                    width: 1024px !important;
                    max-width: 1024px !important;
                    align-content: center !important;
                    @include margin-auto;
                    margin-top: 0 !important;
                    background: transparent;
                }
               ion-toolbar {
                    ion-title {
                        //  width: calc(100% - 68px) !important;
                        text-align: center !important;
                        .toolbar-content {
                            text-align: center !important;
                            width: 100% !important;
                            .toolbar-title-md,
                            .toolbar-title {
                                font-size: 2.8rem !important;
                                text-align: center !important;
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }
        ion-title {
            .toolbar-title {
                .subtitle {
                    width: 100%;
                }
            }
        }
        .tabbar {
            width: 1024px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            background: transparent !important;
            position: relative;
            margin-top: calc(100vh - 56px) !important;
            a.tab-button {
                height: 50px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                @include box-shadow(2px, 2px, 3px, 1px, 25, 89, 153, 0.5);
                background: $color_1;
                margin-right: 5px;
                &:hover,
                &:focus {
                    opacity: 1 !important;
                }
            }
            .tab-button-icon {
                width: 28px !important;
                height: 30px !important;
                margin-top: 0;
                &:before {
                    font-size: 32px !important;
                }
                &.ion-md-restau:before {
                    font-size: 32px !important;
                }
                &.ion-md-commande:before {
                    font-size: 32px !important;
                }
            }
            .tab-button-text {
                font-size: 1.8rem !important;
                margin-top: -2px;
            }
            ion-badge.tab-badge {
                height: auto !important;
                font-size: 15px !important;
                line-height: 7px;
                width: 38px;
                &:before {
                    top: -2px;
                }
            }
            .tab-button[aria-selected="true"] {
                background: $color_white;
                background-size: cover;
                .tab-button-icon {
                    transform: scale(1.05);
                }
                ion-icon {
                    color: $color_1 !important;
                    &:before {
                        color: $color_1 !important;
                        font-size: 33px !important;
                        margin-top: 1px;
                    }
                }
                .tab-button-text {
                    font-size: 1.6rem !important;
                    color: $color_1 !important;
                }
                ion-badge.tab-badge {
                    height: auto !important;
                    font-size: 15px !important;
                    line-height: 7px;
                    width: 38px;
                    border: 1px solid $color_1 !important;
                    color: $color_1 !important;
                    &:before {
                        top: -2px;
                        color: $color_1 !important;
                    }
                }
            }
        }
        ion-modal {
            width: 100vw !important;
            height: 100vh !important;
            position: absolute !important;
        }
        ion-alert {
            .alert-wrapper {
                margin-top: 40px;
                min-height: 36vh !important;
                width: 40vw !important;
                max-width: 40vw !important;
                transform: scale(1) !important;
                box-shadow: 5px 5px 10px -10px #656565 !important;
                border-radius: 13px !important;
                .alert-button-group {
                    width: 100%;
                    margin-bottom: 90px;
                    .alert-button {
                        padding: 4px;
                        .button-inner {
                            font-size: 2rem !important;
                        }
                    }
                }
                .alert-title {
                    margin-top: 50px;
                    font-size: 2.5rem !important;
                    line-height: 3.5rem;
                }
                .alert-message {
                    font-size: 2.2rem !important;
                    line-height: 2.5rem;
                }
            }
            &.alert_majeur {
                .alert-title {
                    margin-bottom: 30px;
                }
                .alert-message {
                    width: 60% !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                    margin-bottom: 50px;
                    font-family: $second-font;
                    margin-top: -30px;
                }
                .alert-button {
                    width: 30vw !important;
                    margin: 50px 0 0 0 !important;
                }
            }
        }
        app-home {
            ion-header {
                height: 1px;
                min-height: 1px !important;
                .bar_android {
                    margin-top: 4px;
                }
                .flag {
                    width: 40px !important;
                }
            }
            #logo {
                margin-top: 60px;
            }
            .tabbar {
                background: transparent !important;
            }
            &.ion-page {
                ion-content {
                    .scroll-content {
                        width: 100% !important;
                        max-width: 100% !important;
                        margin-bottom: 0 !important;
                        margin-top: 0 !important;
                        background: $color_1 !important;
                        @include margin-auto;
                        .container {
                            margin-top: 0 !important;
                            align-content: center !important;
                            height: 90vh;
                            .container_logo {
                                position: absolute;
                            }
                            div.menu {
                                width: 90% !important;
                                display: flex !important;
                                margin-top: 100px !important;
                                overflow-y: auto;
                                // max-height: 590px;
                                align-items: flex-start;
                                button {
                                    width: 280px !important;
                                    .button-inner {
                                        display: flex;
                                        font-size: 2rem !important;
                                        justify-content: center;
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .tabbar {
                background: transparent !important;
            }
        }
        app-login {
            background-color: $color_1 !important;
            .scroll-content {
                margin-top: 50px !important;
                .form-login {
                    width: 30%;
                    @include margin-auto;
                    .text-input-md {
                        width: 100% !important;
                    }
                }
                .item-md.item-block .item-inner {
                    padding-right: 0;
                }
            }
        }
        app-password-lost,
        app-password-change {
            .form-password {
                width: 50%;
                @include margin-auto;
                .text-input-md {
                    width: 100% !important;
                }
            }
        }
        app-user-form {
            background-color: $color_1 !important;
            .scroll-content {
                margin-bottom: 250px !important;
            }
            .form-register {
                width: 50% !important;
                @include margin-auto;
            }
            .button_color_1 {
                &.modif {
                    .button-inner {
                        font-size: 1.4rem !important;
                    }
                }
            }
        }
        app-stores-list {
            background-color: $color_1 !important;
            .leaflet-container {
                height: 50vh !important;
            }
        }
        app-store-detail {
            background-color: $color_1 !important;
            ion-footer {
                button {
                    @include margin-auto;
                }
            }
        }
        app-orders-list {
            background-color: $color_1 !important;
            .scroll-content {
                margin-bottom: 120px !important;
            }
        }
        app-order-detail {
            background-color: $color_1 !important;
            .scroll-content {
                margin-bottom: 120px !important;
            }
        }
        app-info {
            background-color: $color_1 !important;
            .ion-grid {
                background-color: $color_1 !important;
            }
            .scroll-content {
                background-color: $color_1 !important;
                .container_price {
                    background: $color_1 !important;
                    margin-top: -50px;
                    .price_article {
                        text-align: right !important;
                        background: $color_1 !important;
                        color: $color_white !important;
                        width: 50% !important;
                        .price {
                            color: $color_white;
                        }
                    }
                }
                .container_text {
                    height: calc(100vh - 460px) !important;
                }
                .title {
                    color: $color_white;
                }
                .description {
                    color: $color_white;
                    text-align: center !important;
                }
            }
            ion-footer {
                .controls {
                    width: 300px;
                    button {
                        bottom: 0px !important;
                    }
                }
                button:not(.qte_min, .qte_max) {
                    bottom: 20px;
                }
                button {
                    &.bt_cmd {
                        .button-inner {
                            div {
                                font-size: 1.6rem !important;
                            }
                        }
                    }
                }
            }
        }
        app-options,
        app-cross-sellings,
        app-upsellings {
            ion-header {
               ion-toolbar {
                    .toolbar-content {
                        margin-right: 8%;
                    }
                }
            }
            .scroll-content {
                margin-bottom: 150px !important;
            }
            ion-footer {
                button {
                    width: 250px;
                    max-width: 250px;
                }
            }
        }
        app-recapmenu {
            background: $color_1 !important;
            .scroll-content {
                margin-bottom: 150px !important;
            }
            ion-footer {
                .button_color_2 {
                    margin-left: auto !important;
                    margin-right: auto !important;
                    min-width: 33% !important;
                    width: 33% !important;
                }
            }
        }
        app-validation-order {
            background: $color_1 !important;
            ion-list,
            .cgu-item {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }
            ion-footer {
                button {
                    width: 320px !important;
                    span {
                        font-size: 2rem !important;
                        font-weight: 700 !important;
                        letter-spacing: -0.1px !important;
                    }
                }
            }
        }
        app-payzen {
            background: $color_1 !important;
            .scroll-content {
                margin-bottom: 125px !important;
            }
        }
        app-pick-up {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-pick-up-place {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-pick-up-hour {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-code-type {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-take-away {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-take-back {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-bundle {
            .scroll-content {
                margin-top: 0 !important;
            }
        }
        app-quote,
        app-quote-review {
            background: $color_1 !important;
            .scroll-content {
                margin-top: 0 !important;
                margin-bottom: 222px !important;
                .large.divider {
                    margin-bottom: 100px;
                    opacity: 0.4;
                }
            }
            ion-footer {
                button {
                    width: 320px !important;
                    span {
                        font-size: 2rem !important;
                        font-weight: 700 !important;
                        letter-spacing: -0.1px !important;
                    }
                }
            }
        }
        app-profile {
            background: $color_1 !important;
            ion-list,
            ion-card,
            .card-md {
                width: 50% !important;
                button {
                    width: 320px !important;
                    span {
                        font-size: 2rem !important;
                        font-weight: 700 !important;
                        letter-spacing: -0.1px !important;
                    }
                }
            }
        }
        app-payzen-register {
            background-color: $color_1 !important;
            button {
                width: 15% !important;
            }
        }
        app-reservation {
            background: $color_1 !important;
            ion-content {
                .scroll-content {
                    margin-top: 10px !important;
                    margin-bottom: 200px !important !important;
                }
            }
        }
        app-idle .scroll-content .bt_close {
            width: 50px !important;
        }

        app-directory {
            .toolbar .toolbar-title {
                text-align: center;
            }
            ion-header {
                .ios-shortcuts {
                    div {
                        @include margin-auto;
                    }
                }
               ion-toolbar {
                    border-bottom: 0px solid transparent;
                    background-color: transparent;
                    button {
                        font-size: 2rem !important;
                        padding: 20px 10px 16px 10px !important;
                    }
                    ion-badge {
                        font-size: 1.7rem;
                        &:before {
                            height: 24px;
                        }
                    }
                }
            }
            .scroll-content {
                background: transparent !important;
               ion-toolbar {
                    border-bottom: 0px solid transparent;
                    background-color: transparent;
                    ion-title {
                        width: 100% !important !important;
                        .toolbar-title {
                            margin-left: 8% !important;
                        }
                    }
                    button {
                        font-size: 2rem !important;
                        padding: 20px 10px 16px 10px !important;
                    }
                    ion-badge {
                        font-size: 1.7rem;
                        &:before {
                            height: 24px;
                        }
                    }
                }
            }
        }
        app-directory,
        app-cross-sellings,
        app-upsellings {
            ion-header {
               ion-toolbar {
                    .toolbar-content {
                        margin-left: 8%;
                    }
                }
            }
        }
        app-directory,
        app-options,
        app-bundle,
        app-cross-sellings,
        app-upsellings {
            ion-header {
               ion-toolbar {
                    border-bottom: 0px solid transparent;
                    button {
                        font-size: 2rem !important;
                        padding: 20px 10px 16px 10px !important;
                    }
                    ion-title {
                        width: 90% !important !important;
                        .toolbar-title {
                            margin-top: 0px !important;
                        }
                    }
                    .toolbar-content {
                        margin-left: 8%;
                    }
                    .toolbar-md {
                        .toolbar-title {
                            margin-left: 0;
                            .subtitle {
                                font-size: 2rem;
                                margin-top: -10px !important;
                                font-family: $second-font;
                                opacity: 0.5;
                            }
                        }
                    }
                    ion-badge {
                        font-size: 1.7rem;
                        &:before {
                            height: 24px;
                        }
                    }
                }
            }
            .bar-button-md:first-child {
                margin-left: 22px;
                font-size: 4rem;
            }
            .scroll-content {
                background: transparent !important;
                padding-top: 0 !important;
                margin-bottom: 125px;
                .img_bar {
                    background: $color_1 !important;
                }
                .grid-cell-container {
                    margin-top: 15px !important;
                    margin-bottom: 100px;
                }
                grid-article-cell,
                app-grid-cell {
                    .properties {
                        .breaking_div {
                            transform: rotate(44deg) translate(29%, -119%) !important;
                            transform-origin: 34% 0 !important;
                            div {
                                padding: 3% 15% 2% 20% !important;
                                line-height: 1.3em;
                                font-size: 1.2em;
                            }
                        }
                    }
                    .label {
                        .description {
                            top: 215px;
                        }
                    }
                    .checkbox {
                        top: 70%;
                        &:before {
                            margin-left: 4%;
                        }
                        span.checkbox_text {
                            margin-left: -30%;
                        }
                    }
                }
            }
            ion-footer {
                background: transparent !important;
                margin-left: auto;
                margin-right: auto;
                height: 75px;
                .button_color_1 {
                    margin-right: 10px !important;
                    min-width: 30% !important;
                    width: 30% !important;
                    opacity: 0.8;
                    margin-left: 0 !important;
                }
                .button_color_2 {
                    min-width: 30% !important;
                    width: 30% !important;
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
            }
        }
        app-order-lines {
            .order-line {
                ion-row.level0 {
                    grid-template-columns: 85% 15%;
                    .article_qte {
                        font-size: 2rem;
                    }
                    .article_price {
                        font-size: 2.2rem;
                    }
                }
                ion-row.level1 {
                    grid-template-columns: 85% 15%;
                    .article_price {
                        app-price {
                            font-size: 1.6rem;
                        }
                    }
                }
                ion-row.level2 {
                    grid-template-columns: 85% 15%;
                    .article_price {
                        app-price {
                            font-size: 1.6rem;
                        }
                    }
                }
                ion-row.level0_price {
                    grid-template-columns: 85% 15%;
                }
                .divider_compose {
                    div {
                        width: 15%;
                    }
                }
            }
            .tva_titre,
            .tva_detail {
                width: 42% !important;
                margin-left: 58%;
            }
        }
        app-language-list {
            ion-footer {
                background: transparent;
            }
        }
    }
}
