app-payzen {
    background: $color_white !important;
    iframe {
        border: 0;
        background: $color_white !important;
    }
    body {
        &#lyra{
            background: $color_white !important;
            #blocksSection{
                background: $color_white !important;
            }
        }
    }
    #blocksSection{
        background: $color_white !important;
    }
    #lyra{
        background: $color_white !important;
    }
    #rightBlock, .color2, #paymentResult .message, #forwardMsg .message {
        background-color: $color_white  !important;
    }
    #blockScreenBackground {
        background-color: $color_white !important;
    }
    h2.titleContainer {
        font-size: 17px  !important;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}
