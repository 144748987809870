app-take-back {
    .title{
        margin-bottom: 0px !important;
        text-align: left !important;
    }
    .scroll-content{
        background: $color_5 !important !important;
        overflow-y: hidden !important;
        margin-top: 0 !important;

        button.button_color_1 {
            @extend .mainfont-button;
            @extend .button_1;
            width: 80%;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-top: -200px !important !important;
            height: auto;
        }
        button.button_color_2 {
            @extend .mainfont-button;
            @extend .button_1;
            width: 80%;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-bottom: 15px;
            margin-top: 15px;
            height: auto;
        }
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }

}
