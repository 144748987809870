app-directory {
    ion-header {
        background: $color_1;
    }
    ion-toolbar {
        background: $color_1;
        ion-title {
            background: $color_1;
        }
        .toolbar-background-md {
            border-bottom: 1px solid $color_white !important;
        }
        .toolbar-title {
            text-align: left;
            padding: 9px 16px;
            text-overflow: ellipsis;
        }
        .rewind-navigation {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
        }
    }
    .ios-shortcuts {
        line-height: 26px;
        font-family: $third-font;
        border-bottom: 1px solid $color_white !important;
        border-top: 0 solid transparent;
        width: 100%;
        justify-content: space-evenly;
        &.formenupos {
            display: none !important;
        }
        div {
            overflow-x: auto;
            display: flex;
            button {
                white-space: nowrap;
                font-size: 1.5rem;
                color: white;
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                background: transparent;
                &.selected {
                    background: white;
                    color: $color_1;
                    font-size: 1.5rem;
                }
            }
        }
    }
    .menu {
        background: $color_1;
        .menu-scrollable {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            .menu-button {
                color: white;
                text-align: center;
                font-size: 1.2rem;
                flex-direction: column;
                min-width: auto !important;
                &.depth1 {
                    min-height: 105px;
                    height: 105px;
                    margin-top: 5px;
                    font-family: $third-font;
                    text-shadow: 1px 2px 3px #085051;
                }
                &.selected-mainCategory {
                    border-bottom: 2px solid $color_white;
                    font-family: $third-font;
                }
                .image-menu {
                    min-width: 139px;
                    max-height: 79px;
                    max-width: 100px;
                }
                .text-center {
                    max-width: 100px;
                }
            }
        }
    }
    .bar-buttons-ios {
        .pin_icon {
            margin-top: -20px;
        }
        .content-md {
            background: $color_1;
        }
        .directory_cart {
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
    }

    ion-content{
        background: $color_1;
        --background: $color_1;
    }

    ion-content.ios{
        .product_list{
            margin-top: 170px;
        }
    }
}