@import "../variables";

app-stores-list {
    background-color: $color_1 !important;
    .scroll-content {
        background-color: $color_1 !important;
        margin-bottom: 150px;
    }
    .toolbar-title {
        color: $color_white !important;
        ion-title {
            text-transform: uppercase;
        }
    }
    .radio-md .radio-icon {
        border-color: transparent !important;
        margin-right: 15px;
    }
    .store-list {
        .item-input ion-input,
        .item-input ion-textarea {
            font-family: $second-font !important;
            color: $color_white !important;
            font-size: 1.8rem;
            border-bottom: 1px solid $color_white;
            width: 300px;
            margin-top: 15px;
            input {
                color: $color_white !important;
                &::placeholder {
                    color: $color_white !important;
                }
            }
        }
        .container-store {
            background-color: $color_white;
            &.selected{
                border: 2px solid $color_5 !important;
            }
            .store-label {
                div {
                    &.store-info {
                        .store-name {
                            font-weight: 700;
                            font-size: 1.3rem;
                            line-height: 1.9rem;
                            font-family: $main-font;
                        }
                        .store-address {
                            font-family: $main-font;
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                        }
                    }
                    &.store-link {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        min-height: 80px;
                        .store-radio {
                            ion-icon {
                                font-size: 2.8rem;
                                color: $color_1;
                            }
                            ion-radio .radio-icon.checkbox-checked:before,
                            ion-radio .radio-icon.radio-checked:before {
                                color: $color_1;
                                display: block;
                                --color-checked: $color_1 !important;
                            }
                            ion-radio{
                                color: transparent !important;
                            }
                            ion-radio .check-radio{
                                color: $color_1 !important;
                                --color-checked: $color_1 !important;
                            }
                        }
                        .store-info {
                            margin-top: 15px;
                            ion-icon {
                                font-size: 2.6rem;
                                color: $color_1;
                            }
                        }
                        .store-link {
                            a {
                                font-size: 2.3rem;
                                color: $color_1;
                                ion-icon {
                                    font-size: 2.5rem !important;
                                    color: $color_1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .stores {
        &:last-child {
            margin-bottom: 100px !important;
        }
    }
    .ios-shortcuts {
        font-family: $second-font;
        border: 0px solid transparent !important;
        background-color: $color_1;
        letter-spacing: 1px;
        justify-content: space-between !important;
        display: flex !important;
        line-height: 26px;
        border-bottom: 1px solid white !important;
        border-top: 1px solid white !important;
        div {
            overflow-x: auto;
            width: 100%;
            display: flex;
            justify-content: space-between !important;
            button {
                width: 50%;
                white-space: nowrap;
                font-size: 2rem;
                &.selected {
                    color: $color_1;
                    font-size: 2rem;
                }
            }
        }
    }
    .map {
        .item-input ion-input,
        .item-input ion-textarea {
            font-family: $second-font !important;
            color: $color_white !important;
            font-size: 1.8rem;
            border-bottom: 1px solid $color_white;
            width: 300px;
            margin-top: 15px;
            input {
                color: $color_white !important;
                &::placeholder {
                    color: $color_white !important;
                }
            }
        }
        .list-md .item-input:last-child {
            border-bottom: 0px solid transparent !important;
        }
        .label-md {
            margin: 0px 8px 0px 0;
        }
        ion-label {
            justify-content: flex-end;
            .button_geoloc {
                display: flex;
            }
        }
        .button_valid {
            box-shadow: 3px 4px 10px 2px rgba(204, 239, 240, 0.3) !important;
            float: right;
            background: $color_4;
            color: $color_white;
            border-radius: 10px;
            height: 32px;
            font-size: 1rem;
            line-height: 1.2rem;
            ion-icon {
                margin-top: -2px;
                margin-left: 10px;
            }
            &:hover {
                background: $color_4;
                opacity: 0.8;
            }
        }
        .button_geoloc {
            float: left;
            border-radius: 10px;
            height: 32px;
            font-size: 1rem;
            line-height: 1.2rem;
            background: $color_5;
            color: $color_black;
            ion-icon {
                margin-top: -2px;
            }
            &:hover {
                background: $color_5;
                opacity: 0.8;
            }
        }
        .leaflet-container {
            width: auto !important;
            .leaflet-popup {
                min-width: 260px;
                bottom: -60px !important;
                left: -130px !important;
            }
            .leaflet-popup-content {
                min-width: 240px;
                a {
                    font-size: 1.5rem;
                    line-height: 3rem;
                }
                h2 {
                    white-space: normal;
                }
                ion-button {
                    background: $color_5;
                    border-radius: 10px;
                }
            }
        }
    }
    ion-footer {
        background: transparent;
        button.button_color_1 {
            line-height: 4px !important;
        }
    }

    ion-content.ios{
        .store-list{
        }

        ion-input{
            padding-top: 30px;
            font-size: 30px !important;
            z-index: 999;
            padding-bottom: 30px;
        }
        .ionlist_button_geoloc{
            ion-item{
                ion-input{
                    color: $color_white !important;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 30px;

                    input{
                        font-family: "Lato" !important;
                        font-size: 1.6rem !important;
                        color: white !important;
                        background-color: transparent !important;
                        border: 0 !important;
                        border-bottom: 1px solid white !important;
                        border-radius: 0 !important;
                        padding-left: 3px;
                        padding-right: 3px;
                        height: 40px !important;
                    }
                }
            }
        }

    }
}
