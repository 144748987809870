app-info {
    ion-content{
        background: $color_1;
        --background: $color_1;
    }
    .scroll-content{
        overflow-y: hidden !important;
        margin-top: 10px !important;
    }
    .toolbar-title {
        margin-left: -20px;
    }
    .title{
        text-align: center;
        font-family: $main-font;
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-weight: 700;
        letter-spacing: 1px;
        color:$color_5;
        width: 95%;
        margin-left:auto;
        margin-right: auto;
    }
    .price_article{
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: right;
        margin-top: 10px;
        display: inline-flex;
        align-self: center;
        align-content: right;
        height: 50px;
        flex-direction: row;
        .layer-price{
            bottom: -20px;
            letter-spacing: 0.1rem;
            font-family: $price-font;
            display: inline-flex;
            align-self: center;
            align-content: center;
            position: absolute;
            right: 27px;
            z-index: 2;
            opacity: 0.8;
            .club_price{
                display:none;
            }
            .line_through{
                background-color: $color_white;
                top: 15px;
                left: 18px;
                width: 65%;
                height: 2px;
                position: absolute;
                z-index: 3;
                display: flex !important;
            }
            .euro{
                display: inline-flex;   
                margin-left: -22px;
                background: transparent;
                color: #38423f !important;
                text-align: center;
                min-width: 76px;
                height:25px;
                justify-content: center;
                position: relative;
                &.unit{
                    min-width: 55px;
                    app-price{
                        padding-left: 10px;
                        .line_through{
                            left: 15px;
                            width: 31px;
                        }
                    }
                }
                &.hundred{
                    min-width: 68px;
                    app-price{    
                        padding-left: 8px;
                        .line_through{
                            left: 18px;
                            width: 39px;
                            top: 11px;
                            transform: rotate(-22deg);
                        }
                    }
                }
                app-price{
                    font-family: $price-font;
                    color:$color_white;
                    font-weight: 700;
                    padding-left: 8px;
                    padding-top: 2px;
                    opacity: 0.7;
                    .integerCSSClass,.decimalCSSClass,.withoutDecimalCSSClass{
                        z-index: 1;
                    }
                    .withoutDecimalCSSClass{
                        opacity: 0;
                        display: none;
                        font-size: 0;
                    }
                    .integerCSSClass{
                        font-size: 1.8rem;
                    }
                    .decimalCSSClass{
                        font-size: 1.8rem;
                        margin-top: 0.8rem;
                        right: 0.6rem;
                        position: relative;
                    }
                    &:after{
                        content: "";
                        position: relative;
                        font-weight: 700;
                        margin-left: -5px;
                        font-size: 1.5rem;
                    }
                    :after {
                        content: "";
                        position: relative;
                        font-weight: 700;
                        margin-left: -5px;
                        font-size: 1.5rem;
                    }
                    ion-icon{
                        margin-left: 0px !important;
                        font-size: 1rem;
                    }
                }
            }
        }
       
        .unit-price{
            background: transparent;
            justify-content: left !important;
            /*bottom: -70px;*/
            align-self: right;
            align-content: right;
            display: inline-flex;
            position: absolute;
            right: -6px;
            z-index: 1;
            .euro{
                margin-left: 3px;
                line-height: 83px;
                color: $color_white !important;
                width: 140px;
                height: 85px;
                background-size: contain;
                .withoutDecimalCSSClass{
                    &:after{
                        left: 3px;
                    }
                }
                app-price{
                    font-family: $price-font;
                    color: $color_white;
                    width: auto;
                    padding-right: 6px;
                    font-size: 1.9rem;
                    font-weight: 700;
                    .integerCSSClass,.decimalCSSClass,.withoutDecimalCSSClass{
                        z-index: 1;
                    }
                    .integerCSSClass{
                        font-size: 1.9rem;
                        line-height: 2.5rem;
                    }
                    .withoutDecimalCSSClass{
                        opacity: 0;
                        display: none;
                        font-size: 0;
                    }
                    .decimalCSSClass{
                        font-size: 1.9rem;
                        margin-left: -5px;
                    }
                    &:after {
                        font-size: smaller;
                        font-weight: 700; 
                        bottom: 1px;
                        position: relative;
                    }
                    ion-icon{
                        margin-left: 0px !important;
                        font-size: 1rem;
                    }
                }
            }
            &.binome-layer-price{
                width: 114px;
                .euro{
                    padding-top: 7px;
                }
                &.unit{
                    width: 108px;
                }
                &.hundred{
                    width: 118px;
                }
            }
        }
    }
    .description{
        text-align: center;
        font-family: $main-font;
        font-size: 1.4rem;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 2rem;
        font-weight: 100;
        width:90%;
        margin-left:auto;
        margin-right: auto;
        color:$color_white;
    }
    .attributes{
        .item-md.item-block .item-inner {
            border-bottom: 0 solid transparent !important;
        }
        .label-md {
            margin: 0 !important;
            font-size: 2rem;
            color: $color_white !important;
            font-family: $main-font;
            .item {
                .label-md {
                    font-size: 1.8rem;
                    font-family : $main-font !important;
                }
            }
        }
        .item-block{
            min-height: 20px;
        }
    }
    .container_image{
        background: $color_1;
        padding-bottom: 10px;
        padding-top:15px;
    }
    img {
        margin: auto;
        display: inherit;
        height:90%;
        margin-top:20px;
    }
    
    .text-input{
        color: $color_white;
    }
    &.qte_min, &.qte_max{
        .ion-md-remove-circle:before {
            color: $color_white !important;
            text-shadow: 4px 1px 2px #DADDEA !important;
            font-size: 1.2em;
        }
        .ion-md-add-circle:before {
            color: $color_white !important;
            text-shadow: 4px 1px 2px #DADDEA !important;
            font-size: 1.2em;
        }
    }
    ion-footer{
        .heavy{
           color: $color_white !important;
           font-size: 1.5rem;
        }
        background: $color_1;
        ion-icon{
           color: $color_white !important;
           width: 30px;
           height: 30px;
        }
        button{
            &.bt_cmd{
                width: 320px !important;
                margin-bottom: 15px !important;
                margin-left: auto;
                margin-right: auto;
                cursor: default;
                span{
                    font-size: 1.8rem;
                }
                &:hover:not(.disable-hover){
                   // background-color: #d2d2d2 !important;
                }
                .check_dispo{
                    display: none;
                }
            }
        }
        button{
            &.bt_add{
                padding-left:35px;
                margin-bottom: 10px !important;
                font-size: 2.2rem;
                span{
                    font-size: 2rem;
                }
                ion-icon{
                    display: none;
                }
            }
            &.qte_min, &.qte_max{
                @media screen and (min-width: 768px) {
                    width: 50px !important;
                }
            }
        }
    }

    ion-item{
        --background: $color_1 !important;
        color: $color_white !important;
        font-size: 1.4rem;
    }
}