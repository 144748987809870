app-debug-settings{
    ion-header{
       ion-toolbar{
            height: 45px !important;
            min-height: 45px !important;
        }
        ion-title .toolbar-title {
            color: #fcea10 !important;
            font-family: "Montserrat";
            font-weight: 700;
            width: 100%;
        }
    }
    .scroll-content{
        margin-bottom: 0;
        ion-label{
            white-space: pre-wrap;
            font-size: 1.5rem;
            padding-left: 15px;
        }
        ion-select{
            .select-text{
                font-size: 1.5rem;
                color: #fff475 !important;
            }
        }
    }
    .checkbox-md .checkbox-icon {
        background-color:transparent !important;
    }
   .item.item-md .checkbox-md {
        margin: 9px 10px 9px 4px;
        padding-left: 15px;
    }

    ion-content{
        background-color: $color_1 !important;
        --background: $color_1 !important;
    }

    ion-header ion-toolbar {
      border-bottom: 1px solid $color_white !important;
    }
}