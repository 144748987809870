
@import "fonts";

//MENU IOS + WP
.menu-content {
    .seperator {
        background-color: $color_1 !important;
    }
    ion-list.menu-list {
        padding-left: 20px;
        overflow: auto;
        border: none !important;
    }
    .fixed-content {
        background-color: transparent;
    }
    .scroll-content{
        overflow-y: auto;
    }
    .tabbar{
        background-color: $color_1;
        bottom:0 !important;
        .tab-button-icon{
            width:40px !important;
            color: #5c5c5c;
            height:32px !important;

            &.ion-md-accueil{
                &:before{
                    content: '\f448';
                    color:$color_white;
                    font-size: 30px;
                }
            }
            &.ion-md-restau{
                &:before{
                    content: '\f31c';
                    color:$color_white;
                    font-size: 30px;
                }
            }
            &.ion-md-commande{
                &:before{
                    content: '\f36c';
                    color:$color_white;
                    font-size: 30px;
                }
            }
            &.ion-md-panier{
                &:before{
                    content: '\f292';
                    color:$color_white;
                    font-size: 30px;
                }
            }
            &.ion-md-profil{
                &:before{
                    content: '\f41a';
                    color:$color_white;
                    font-size: 30px;
                }
            }
        }

        .tab-button-text {
            font-size: 1.2rem !important;
            letter-spacing: -0.1px;
            color:$color_white;
            transform: scaleY(1.05);
        }
        .tab-button {
            overflow: visible;
        }
        .tab-button[aria-selected=true] {
            ion-icon{
                &:before{
                    color:$color_5 !important;
                    font-size: 30px;
                }
            }
        }
        .tab-button[aria-selected=true] .tab-button-text{
            color:$color_5;
            font-family: $main-font;
            transform: scaleY(1.05);
        }
    }
    .list-md{
        cursor:pointer;
        .icon-md{
             &.accueil{
                &:before{
                    content: '\f448';
                    color:$color_1;
                    font-size: 30px;
                }
            }
            &.restau{
                &:before{
                    content: '\f31c';
                    color:$color_1;
                    font-size: 30px;
                }
            }
            &.commande{
                &:before{
                    content: '\f36c';
                    color:$color_1;
                    font-size: 30px;
                }
            }
            &.panier {
                &:before{
                    content: '\f292';
                    color:$color_1;
                    font-size: 30px;
                }
            }
            &.profil {
                &:before{
                    content: '\f41a';
                    color:$color_1;
                    font-size: 30px;
                }
            }
        }
        .badge-md {
            color: #47b4cf !important;
            top: 4px !important;
            width: 34px !important;
            height: 30px !important;
            border: 2px solid #47b4cf;
            border-radius: 20px;
            text-align: center;
            background: white;
            font-family: $second-font;
        }
        .label-md{
            font-family: $main-font;
        }
    }
}
//menu selectionné iphone et windows phone
.menu-content .tabbar .tab-button[aria-selected=true] {
    .badge-ios-danger,.badge-wp-danger {
        color:$color_3 !important;
    }
}