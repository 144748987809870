@media screen and (min-width: 1024px) and (orientation: landscape) {
    .showCartClass{
        .directory_cart {
            .container_order_lines{
                padding: 10px 1px 10px 0px;
                app-order-lines {
                    .store-name {
                        margin-top: 0px;
                        margin-left: auto !important;
                        font-size: 2rem !important;
                    }
                    .container_list {
                        overflow-y: auto;   
                        height: calc(100vh - 362px);
                        padding-left: 5px;
                    }
                    .container_total {
                        overflow-y: auto;   
                        padding-right: 10px;
                        padding-left: 5px;
                        .total{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .buttons-bar{
                //  margin-bottom: 100px;
                display: flex;
                justify-content: flex-end;
                padding-right: 10px;
                button{
                    height: 40px;
                    background-color: $color_5 !important !important;
                }
            }
        }

//////////////////////////////////////cart-orientation-landscape////////////////////////////

        /// css à ajouter si on veut voir le panier dans la page du catalogue
        


        .cart-position-top {
            display: flex;
            flex-direction: column-reverse;
            .contain{                      
                display:flex;
                flex-direction: column-reverse;
            }
        }
        .cart-position-bottom {
            display: flex;
            flex-direction: column;
            .contain{                      
                display:flex;
                flex-direction: column;
            }
        }
        .cart-position-left {
            display: flex;
            flex-direction: row;
            .contain{                      
                display:flex;
                flex-direction : row-reverse;
            }
        }
        .cart-position-right {
            display: flex;
            flex-direction: row;
            .contain{                      
                display:flex;
                flex-direction : row;
            }
        }

        // TOP css positionné dans cart-orientation-landscape car le calcul de la hauteur n'est pas le meme que pour cart-orientation-portrait
        .cart-position-top {
            .contain{
                width:100% !important;
                justify-content: space-between;
                display: flex;
                
                .grid-cell-container {
                    width: 100% !important;
                    overflow-y:scroll !important;
                    overflow-x:hidden !important;
                    height: calc(100vh - 513px);
                }
                &.carousel_show{
                    width:100% !important;
                    .grid-cell-container{
                        height: calc(100vh - 275px);
                    }
                    .container_list {
                        height: calc(100vh - 470px);
                    }
                }
            }
            .directory_cart{
                width: 100% !important;
            }
            .container_list {
                width:100%;
                height: 150px !important;
            }
            .container_order_lines{
                app-price{
                    font-size: 1.5rem;
                }

                .order-line{
                    width:100%;
                    padding-left: 0px;
                    padding-right: 0px;
                    ion-row.level0{
                        .article_name{
                            font-size: 1.4rem;
                        }
                       .article_qte{
                            font-size: 2rem;
                        }
                       .article_price{
                            font-size: 2rem;
                       }
                    }
            
                    ion-row.level1{
                        .article_name{
                            font-size: 1.3rem;
                        }
                        .article_price{
                            app-price {
                               font-size: 1.6rem;
                            }
                        }
                    }
                    ion-row.level2{
                        .article_name{
                            font-size: 1.2rem;
                        }
                        .article_price{
                            app-price {
                                font-size: 1.6rem;
                            }
                        }
                    }
                  
                    ion-row.level0_price{
                       .article_price{
                            font-size: 2.2rem;
                       }
                    }
                    .divider_compose div {
                        width: 25%;
                    }
                    &:last-of-type{
                        .divider {
                            display: none;
                        }
                    }
                }
                .divider{
                    background:$color_4;
                }
               
                .total{
                    padding-left: 5px;
                    padding-right: 5px;
                    > span{
                        font-size: 1.8rem;
                    }
                    .price{
                        price{
                            font-size: 1.8rem;
                        }
                    }
                }
                .tva_titre, .tva_detail{
                    margin-left: 57%;
                    line-height: 1.5rem;
                    span,
                    app-price {
                        text-align: right;
                        font-size: 1rem !important;
                    } 
                }
                .buttons-bar{
                    margin-bottom: 0;
                    button{
                        color: $color_4;
                        height: 34px !important;
                    
                    }
                }
            }
            .container_total {
                width:100%;
            }
        }
        // BOTTOM  css positionné dans cart-orientation-landscape car le calcul de la hauteur n'est pas le meme que pour cart-orientation-portrait
        .cart-position-bottom {
            .contain{
                width:100% !important;
                justify-content: space-between;
                display: flex;
                height: calc(100vh - 156px);
                .grid-cell-container {
                    width: 100% !important;
                    overflow-y:scroll !important;
                    overflow-x:hidden !important;
                    
                }
                &.carousel_show{
                    width:100% !important;
                    height: calc(100vh - 275px) !important;
                    .grid-cell-container{
                        height: 360px;
                    }
                    .container_list {
                        height: calc(100vh - 470px);
                    }
                }
            }
            .directory_cart{
                width: 100% !important;
            }
            .container_list {
                width:100%;
                height: 100px !important;
            }
            .container_order_lines{
                app-price{
                    font-size: 1.5rem;
                }

                .order-line{
                    width:100%;
                    padding-left: 0px;
                    padding-right: 0px;
                    ion-row.level0{
                        .article_name{
                            font-size: 1.4rem;
                        }
                       .article_qte{
                            font-size: 2rem;
                        }
                       .article_price{
                            font-size: 2rem;
                       }
                    }
            
                    ion-row.level1{
                        .article_name{
                            font-size: 1.3rem;
                        }
                        .article_price{
                            app-price {
                               font-size: 1.6rem;
                            }
                        }
                    }
                    ion-row.level2{
                        .article_name{
                            font-size: 1.2rem;
                        }
                        .article_price{
                            app-price {
                                font-size: 1.6rem;
                            }
                        }
                    }
                  
                    ion-row.level0_price{
                       .article_price{
                            font-size: 2.2rem;
                       }
                    }
                    .divider_compose div {
                        width: 25%;
                    }
                    &:last-of-type{
                        .divider {
                            display: none;
                        }
                    }
                }
                .divider{
                    background:$color_4;
                }
               
                .total{
                    padding-left: 5px;
                    padding-right: 5px;
                    > span{
                        font-size: 1.8rem;
                    }
                    .price{
                        app-price{
                            font-size: 1.8rem;
                        }
                    }
                }
                .tva_titre, .tva_detail{
                    margin-left: 57%;
                    line-height: 1.5rem;
                    span,
                    app-price {
                        text-align: right;
                        font-size: 1rem !important;
                    } 
                }
                .buttons-bar{
                    margin-bottom: 0;
                    button{
                        color: $color_4;
                        height: 34px !important;
                    
                    }
                }
            }
            .container_total {
                width:100%;
            }
        }
        // RIGHT & LEFT css positionné hors cart-orientation-landscape et cart-orientation-portrait car pas utile l'encapsuler dans ces classes
        .cart-position-left,
        .cart-position-right {
            .contain{
                width:100% !important;
                justify-content: space-between;
                display: flex;
                .grid-cell-container {
                    width: 70% !important;
                    overflow-y:scroll !important;
                    overflow-x:hidden !important;
                    height: calc(100vh - 158px);
                } 
                &.carousel_show{
                    width:100% !important;
                    height: calc(100vh - 265px) !important !important;

                    .grid-cell-container{
                        height: calc(100vh - 290px) !important;
                    }
                    .container_list {
                        border-bottom: 1px solid #1c8f92;
                        height: calc(100vh - 472px) !important;
                    }
                }
            }
            .container_total {
                width:307px;
                button{
                    height: 35px !important;
                }
            }
            .container_order_lines{
                app-price{
                    font-size: 1.5rem;
                }

                .order-line{
                    width:100%;
                    padding-right: 0;
                    padding-top: 10px 0;
                    ion-row.level0{
                        grid-template-columns: 65% 35%;
                        .article_name{
                            font-size: 1.4rem;
                        }
                       .article_qte{
                            font-size: 2rem;
                        }
                       .article_price{
                            font-size: 2rem;
                       }
                    }
            
                    ion-row.level1{
                        grid-template-columns: 65% 35%;
                        .article_name{
                            font-size: 1.3rem;
                        }
                        .article_price{
                            app-price {
                               font-size: 1.6rem;
                            }
                        }
                    }
                    ion-row.level2{
                        grid-template-columns: 65% 35%;
                        .article_name{
                            font-size: 1.2rem;
                        }
                        .article_price{
                            app-price {
                                font-size: 1.6rem;
                            }
                        }
                    }
                  
                    ion-row.level0_price{
                        grid-template-columns: 65% 35%;
                        .article_price{
                            font-size: 2.2rem;
                       }
                    }
                    .divider_compose div {
                        width: 25%;
                    }
                    &:last-of-type{
                        .divider {
                            display: none;
                        }
                    }
                }

                .divider{
                    background:$color_4;
                }
             
                .total{
                    padding-left: 5px;
                    padding-right: 5px;
                    > span{
                        font-size: 2rem;
                    }
                    .price{
                        app-price{
                            font-size: 2rem;
                        }
                    }
                }
                .tva_titre, .tva_detail{
                    margin-left:53%;
                    line-height: 2rem;
                    span,
                    app-price {
                        text-align: right;
                        font-size: 1.2rem !important;
                    } 
                }
                .buttons-bar{
                    margin-bottom: 0;
                    button{
                        color: $color_4;
                        height: 34px !important;
                        
                    }
                }
            }
        }

    }      
            
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}